import { Button, Flex, Space, Typography } from 'antd';
import {
  CustomLeafletMarkerIcon,
  LeafletMarkerIcon,
} from 'components/shared/Map';
import { mapHelpers } from 'helpers';
import { IPlot } from 'interfaces';
import React, { useMemo } from 'react';
import { Circle, Marker, Popup, useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { selectAvailableSamplingModules } from 'redux/features/samplingPlot';
import './SamplingPlotPoint.scss';
import { t } from 'helpers/i18n';
import { convertNumberFormat } from 'helpers/common';
import { selectProject } from 'redux/features/project';

interface ISamplingPlotPointProps {
  plot: IPlot;
  mapRef: React.RefObject<HTMLDivElement>;
}

const { convertRadiusToPixel } = mapHelpers;

const SamplingPlotPoint: React.FC<ISamplingPlotPointProps> = ({
  plot,
  mapRef,
}) => {
  const availableSamplingModules = useSelector(selectAvailableSamplingModules);
  const projectDetails = useSelector(selectProject);
  const map = useMap();

  const markerData = useMemo(() => {
    return projectDetails?.samplingModules?.find(samplingModule => {
      return samplingModule.id === plot?.samplingModuleId;
    });
  }, [plot?.samplingModuleId]);

  const markerIcon = useMemo(() => {
    if (markerData) {
      return CustomLeafletMarkerIcon(
        markerData.backgroundColorCode,
        plot.hasData
          ? 'yellow'
          : availableSamplingModules.find(
              samplingModule => samplingModule.id === plot.samplingModuleId
            )?.colorCode || 'grey'
      );
    } else {
      return LeafletMarkerIcon.DEFAULT.NO_DATA;
    }
  }, [plot?.samplingModuleId]);

  return (
    <>
      <Circle
        center={[plot.latitude, plot.longitude]}
        radius={convertRadiusToPixel(plot.plotRadius || 1, map, mapRef)}
        pathOptions={{
          color: plot.hasData
            ? 'yellow'
            : availableSamplingModules.find(
                samplingModule => samplingModule.id === plot.samplingModuleId
              )?.colorCode || 'grey',
        }}
      />
      <Marker
        position={[plot.latitude, plot.longitude]}
        icon={markerIcon}
        eventHandlers={{
          click: () => {},
        }}
      >
        <Popup minWidth={300} className="sampling-plot-point-popup">
          <div className="d-flex justify-content-between">
            <Typography.Text className="d-block" strong>
              {plot.plotName}
            </Typography.Text>
          </div>
          <Space direction="horizontal" className="w-100">
            <div>
              <Typography.Text strong>{t('PlotShape')}: </Typography.Text>
              <Typography.Text>{t('Circle')}</Typography.Text>
            </div>
            <div>
              <Typography.Text strong>{t('PlotRadius')}: </Typography.Text>
              <Typography.Text>
                {convertNumberFormat(plot.plotRadius || 0)} m
              </Typography.Text>
            </div>
          </Space>
          {plot.hasData && (
            <Flex justify="flex-end" className="mt-half">
              <Button>{t('ViewSubmission')}</Button>
            </Flex>
          )}
        </Popup>
      </Marker>
    </>
  );
};

export { SamplingPlotPoint };
export default SamplingPlotPoint;
