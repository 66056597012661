import { FLAGS } from 'interfaces';

const FLAG_KEYS: { [key in FLAGS]: string } = {
  CAR_418: 'epic_car_418',
  CAR_2311: 'epic_car_2311',
  CAR_2398: 'epic_car_2398',
  CAR_2458: 'epic_car_2458',
  CAR_2355: 'epic_car_2355',
  CAR_2356: 'epic_car_2356',
  CARSA_243_VIEWPORT: 'task_carsa_243_viewport',
  CARHCM_507: 'task_carhcm_507',
  CAR_1598: 'epic_car_1598',
  CAR_377: 'epic_car_377',
  CAR_2399: 'epic_car_2399',
  CAR_1514: 'epic_car_1514',
  CARSA_658: 'flagsup_carsa_658',
  CARHCM_786: 'task_carhcm_786',
  CARSA_919: 'feature_carsa_919',
  CAR_613: 'epic_car_613',
  CAR_2619: 'epic_car_2619',
  CARHCM_856: 'task_carhcm_856',
  CARSA_1046: 'feature_carsa_1046',
  CARSA_853: 'feature_carsa_853',
  CAR_2787: 'epic_car_2787',
  CAR_2787_phase_2: 'epic_car_2787_phase_2',
  CAR_2887: 'epic_car_2887',
};

export default {
  FLAG_KEYS,
};
