import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography, Divider } from 'antd';
import { CurrentSelectedPolygon } from 'components/ProjectList/ProjectModal/context';
import { DrawState } from 'constants/map';
import { useMapContext } from 'contexts/map';
import { t } from 'i18next';
import { ILatLng } from 'interfaces';
import { useContext } from 'react';

export type CremaPolygonDrawerProps = {
  id?: string;
  value?: ILatLng[];
  onChange?: (polygon?: ILatLng[]) => void;
  onDrawStateChange?: (isDrawing: boolean) => void;
};

const CremaPolygonDrawer: React.FC<CremaPolygonDrawerProps> = ({
  onChange,
  value,
  onDrawStateChange,
}) => {
  const { drawRef } = useMapContext();
  const { drawState } = useContext(CurrentSelectedPolygon);

  const onFinishDrawing = () => {
    // get single polygon without inner hole
    const polylinePoints = drawRef?.current?.getDrawPolygon()?.[0]?.[0] || [];
    if (polylinePoints.length > 2) {
      drawRef?.current.setPolygonViewOnly();
      onChange?.(polylinePoints);
    } else {
      drawRef?.current.clear();
    }
    onDrawStateChange?.(false);
  };

  const onClear = () => {
    drawRef?.current?.clear();
    onChange?.(undefined);
  };

  const startDrawing = () => {
    drawRef?.current?.setInitialPolygon([]);
    onDrawStateChange?.(true);
  };

  const onEdit = () => {
    drawRef?.current?.edit();
    onDrawStateChange?.(true);
  };

  return (
    <>
      {!value &&
        ![DrawState.DRAW, DrawState.EDIT].includes(drawState as DrawState) && (
          <Button type="text" onClick={startDrawing}>
            {t('DrawPolygon')}
          </Button>
        )}
      {!!value?.length && drawState === DrawState.VIEW_ONLY && (
        <Space>
          <Typography.Text strong>1 polygon drawn</Typography.Text>
          <Button
            htmlType="button"
            type="text"
            size="small"
            icon={<EditOutlined />}
            onClick={onEdit}
          />
          <Divider type="vertical" />
          <Button
            htmlType="button"
            type="text"
            size="small"
            icon={<DeleteOutlined />}
            danger
            onClick={onClear}
          />
        </Space>
      )}
      {[DrawState.DRAW, DrawState.EDIT].includes(drawState as DrawState) && (
        <>
          <Typography.Text>Drawing....</Typography.Text>
          <Button type="text" onClick={onFinishDrawing}>
            {t('Finish')}
          </Button>
          <Button type="text" onClick={onClear}>
            {t('Clear')}
          </Button>
        </>
      )}
    </>
  );
};

export default CremaPolygonDrawer;
