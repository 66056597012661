import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ILatLng,
  IManualSamplingPlot,
  ISamplingModuleDetails,
  SamplingPlotsType,
} from 'interfaces';
import { LatLng } from 'leaflet';
import { RootState } from 'redux/store';

export interface ISamplingPlotState {
  showingSamplingPlotTypes: number[];
  availableSamplingModules: ISamplingModuleDetails[];
  addSamplingPlotType: SamplingPlotsType;
  manualSamplingPlots: IManualSamplingPlot[];
  loadingSamplingPlotSet: boolean;
}

export interface IUpdateManualSamplingPlots {
  lat: number;
  lng: number;
  radius: number;
  index: number;
  isActive?: boolean;
}

export interface IUpdateManualSamplingPlotLocation {
  lat: number;
  lng: number;
  index: number;
}

const initialState: ISamplingPlotState = {
  showingSamplingPlotTypes: [],
  availableSamplingModules: [],
  addSamplingPlotType: 'NONE',
  manualSamplingPlots: [],
  loadingSamplingPlotSet: false,
};

export const selectShowingSamplingPlotTypes = (state: RootState) =>
  state.showingSamplingPlot.showingSamplingPlotTypes;

export const selectAvailableSamplingModules = (state: RootState) =>
  state.showingSamplingPlot.availableSamplingModules;

export const selectAddSamplingPlotType = (state: RootState) =>
  state.showingSamplingPlot.addSamplingPlotType;

export const selectManualSamplingPlots = (state: RootState) =>
  state.showingSamplingPlot.manualSamplingPlots;

export const selectLoadingSamplingPlotSet = (state: RootState) =>
  state.showingSamplingPlot.loadingSamplingPlotSet;

export const showingSamplingPlotSlice = createSlice({
  name: 'showingSamplingPlot',
  initialState,
  reducers: {
    setShowingSamplingPlotTypes: (state, action: PayloadAction<number[]>) => {
      state.showingSamplingPlotTypes = action.payload;
    },
    setAvailableSamplingModules: (
      state,
      action: PayloadAction<ISamplingModuleDetails[]>
    ) => {
      state.availableSamplingModules = action.payload;
    },
    clearShowingSamplingPlotTypes: state => {
      state.showingSamplingPlotTypes = [];
    },
    addShowingSamplingPlotType: (state, action: PayloadAction<number>) => {
      if (!state.showingSamplingPlotTypes.includes(action.payload)) {
        state.showingSamplingPlotTypes.push(action.payload);
      }
    },
    removeShowingSamplingPlotType: (state, action: PayloadAction<number>) => {
      state.showingSamplingPlotTypes = state.showingSamplingPlotTypes.filter(
        id => id !== action.payload
      );
    },
    addSamplingPlot: (state, action: PayloadAction<ILatLng>) => {
      const { lat, lng } = action.payload;

      state.manualSamplingPlots = state.manualSamplingPlots.map(
        manualSamplingPlot => ({
          ...manualSamplingPlot,
          isActive: manualSamplingPlot.submitted,
        })
      );

      state.manualSamplingPlots.push({
        center: { lat: lat, lng: lng } as LatLng,
        radius: 3,
        isActive: true,
      });
    },
    setAddSamplingPlotType: (
      state,
      action: PayloadAction<SamplingPlotsType>
    ) => {
      state.addSamplingPlotType = action.payload;
    },
    setManualSamplingPlots: (
      state,
      action: PayloadAction<IManualSamplingPlot[]>
    ) => {
      state.manualSamplingPlots = action.payload;
    },
    updateManualSamplingPlots: (
      state,
      action: PayloadAction<IUpdateManualSamplingPlots>
    ) => {
      const { lat, lng, radius, index, isActive } = action.payload;
      state.manualSamplingPlots[index] = {
        ...state.manualSamplingPlots[index],
        center: { lat: lat, lng: lng } as LatLng,
        radius: radius,
        isActive: !!isActive,
      };
    },
    updateManualSamplingPlotLocation: (
      state,
      action: PayloadAction<IUpdateManualSamplingPlotLocation>
    ) => {
      const { lat, lng, index } = action.payload;
      state.manualSamplingPlots[index] = {
        ...state.manualSamplingPlots[index],
        center: { lat: lat, lng: lng } as LatLng,
      };
    },
    updateRadiusSamplingPlot: (
      state,
      action: PayloadAction<{ index: number; radius: number }>
    ) => {
      const { index, radius } = action.payload;
      state.manualSamplingPlots[index] = {
        ...state.manualSamplingPlots[index],
        radius: radius,
      };
    },
    updateSubmittedSamplingPlot: (
      state,
      action: PayloadAction<{ index: number; submitted: boolean }>
    ) => {
      const { index, submitted } = action.payload;
      state.manualSamplingPlots[index] = {
        ...state.manualSamplingPlots[index],
        submitted: submitted,
      };
    },
    updateModuleIdSamplingPlot: (
      state,
      action: PayloadAction<{ index: number; moduleId: number }>
    ) => {
      const { index, moduleId } = action.payload;
      state.manualSamplingPlots[index] = {
        ...state.manualSamplingPlots[index],
        moduleId: moduleId,
      };
    },
    updateLoadingSamplingPlotSet: (state, action: PayloadAction<boolean>) => {
      state.loadingSamplingPlotSet = action.payload;
    },
  },
});

export const {
  setShowingSamplingPlotTypes,
  setAvailableSamplingModules,
  clearShowingSamplingPlotTypes,
  addShowingSamplingPlotType,
  removeShowingSamplingPlotType,
  addSamplingPlot,
  setAddSamplingPlotType,
  setManualSamplingPlots,
  updateManualSamplingPlots,
  updateManualSamplingPlotLocation,
  updateRadiusSamplingPlot,
  updateSubmittedSamplingPlot,
  updateModuleIdSamplingPlot,
  updateLoadingSamplingPlotSet,
} = showingSamplingPlotSlice.actions;

export default showingSamplingPlotSlice.reducer;
