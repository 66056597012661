export enum RiskLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CUSTOM = 'CUSTOM',
}

export enum RiskMetricType {
  uncertainty = 'uncertainty',
  leakage = 'leakage',
  performanceBenchmark = 'performanceBenchmark',
  nonPermanenceRisk = 'nonPermanenceRisk',
}

export interface RiskMetrics {
  [RiskMetricType.leakage]: number;
  [RiskMetricType.uncertainty]: number;
  [RiskMetricType.performanceBenchmark]: number;
  [RiskMetricType.nonPermanenceRisk]: number;
}

export interface RiskAssessmentMetrics extends RiskMetrics {
  riskLevel: RiskLevel;
}

export interface IncPlantingAreaByYear {
  year: number;
  incPlantingAreaPercentage: number;
  incPlantingAreaHa: number;
}

export interface IARRCarbonPotentialReportRowData {
  year: number;
  plantedArea: number;
  incrementalPlantingArea: number;
  linearModelEstimatedRemoval: number;
  emission: number;
  risk: number;
}

export interface IARRCarbonPotentialReport {
  startDate: number;
  endDate: number;
  reportAttributes: {
    riskLevel: RiskAssessmentMetrics;
    incPlantingAreaByYear: IncPlantingAreaByYear[];
    polygonIds: number[];
  };
  reportData: IARRCarbonPotentialReportRowData[];
}

export interface GenerateARRCarbonPotentialReportPayload {
  projectId: number;
  startDate: number;
  endDate: number;
  reportAttributes: {
    riskLevel: RiskAssessmentMetrics;
    incPlantingAreaByYear: IncPlantingAreaByYear[];
    polygonIds: number[];
  };
}

export interface ICarbonPotentialReport {
  startDate: number;
  endDate: number;
  reportAttributes: {
    riskLevel: RiskAssessmentMetrics;
    incPlantingAreaByYear: IncPlantingAreaByYear[];
    polygonIds: number[];
  };
  reportData: CarbonPotentialReportRowData[];
}

export interface CarbonPotentialReportRowData {
  year: number;
  arr: {
    plantedArea: number;
    totalCarbonRemoval: number;
    emission: number;
    risk: number;
  };
  wrc: {
    plantedArea: number;
    totalCarbonRemoval: number;
    emission: number;
    risk: number;
  };
}
