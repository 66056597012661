import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Divider,
  Drawer,
  DrawerProps,
  Flex,
  Form,
  Typography,
  notification,
} from 'antd';
import { Store } from 'antd/es/form/interface';
import { ImageUpload } from 'components/FormTemplateControl/ImageUpload';
import { CurrentSelectedPolygon } from 'components/ProjectList/ProjectModal/context';
import KmlFileUpload from 'components/shared/Upload/KmlFileUpload';
import { DrawState } from 'constants/map';
import { useProjectContext } from 'contexts';
import { useCremaFormationContext } from 'contexts/cremaFormation';
import { useMapContext } from 'contexts/map';
import {
  CremaDrawer,
  CremaInvalidCase,
  ILatLng,
  IUpdateCremaPayload,
} from 'interfaces';
import { useContext, useEffect, useState } from 'react';
import { cremaServices } from 'services';
import { cremaConstants } from 'constants/index';
import { fakeRule } from 'utils/formValidator';
import { MAX_FILE_UPLOAD } from 'constants/file';
import CremaPolygonDrawer from './CremaPolygonDrawer';
import mapHooks from 'hooks/map';
import { useTranslation } from 'react-i18next';
import { tInputPlaceholder } from 'helpers/i18n';

const {
  REGEX_PATTERN_CREMA_COMMUNITY_NAME,
  MAX_LENGTH_CREMA_COMMUNITY_NAME,
} = cremaConstants;

interface UpdateCremaForm {
  cremaId: number;
  cremaName: string;
  cremaImageFileUrl?: string[];
  cremaPolygonFileUrl?: string;
}

interface UpdateCremaProps extends Pick<DrawerProps, 'open'> {
  onClose?: () => void;
  initialValues: UpdateCremaForm;
  onSuccess?: (changedValues: IUpdateCremaPayload) => void;
}

const UpdateCrema: React.FC<UpdateCremaProps> = ({
  open,
  onClose,
  initialValues,
  onSuccess,
}) => {
  const { t } = useTranslation(window.appConfig?.appName);
  const [loading, setLoading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [form] = Form.useForm();
  const { projectId } = useProjectContext();
  const { map, drawRef } = useMapContext();
  const [errors, setErrors] = useState<{ name: string; entity: string[] }[]>(
    []
  );
  const [warnings, setWarnings] = useState<
    { name: string; entity: string[] }[]
  >([]);
  const { cremas, communities, toggleDrawer } = useCremaFormationContext();
  const { setErrorPolygonIds } = useContext(CurrentSelectedPolygon);
  const [errorCommunityIds, setErrorCommunityIds] = useState<number[]>([]);

  // Highlight & focus selected communities
  mapHooks.useMarkerHighlight(errorCommunityIds);

  useEffect(() => {
    if (!open) {
      drawRef?.current?.clear();
      form.resetFields();
    }
    return () => {
      drawRef?.current?.clear();
      setErrorPolygonIds?.([]);
    };
  }, [open]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [JSON.stringify(initialValues)]);

  const validateCrema = async (formValues: Store) => {
    const drawnPolygon: ILatLng[] = formValues.cremaPolygonMap;

    let payload = {
      ...formValues,
      cremaId: initialValues.cremaId,
      cremaImageFileUrl: formValues.cremaImageFileUrl?.[0],
      cremaPolygonFileUrl: formValues.cremaPolygonFileUrl,
      cremaPolygonMap: !!drawnPolygon?.length
        ? drawnPolygon?.map(latlng => ({
            latitude: latlng.lat,
            longitude: latlng.lng,
          }))
        : undefined,
      projectId,
    };

    try {
      setLoading(true);
      resetErrors();
      // call api to validate crema
      const { invalidCases } = await cremaServices.validateCrema(payload);
      if (invalidCases.length) {
        // map errors with the same field name
        const errorsByField = invalidCases.reduce(
          (acc: { [key: string]: string[] }, invalidCase) => {
            if (!acc[invalidCase.fieldName]) {
              acc[invalidCase.fieldName] = [];
            }
            if (
              invalidCase.fieldName === 'cremaPolygonMap' ||
              invalidCase.fieldName === 'cremaPolygonFileUrl'
            ) {
              const entity: string[] = [];
              if (
                invalidCase.error === CremaInvalidCase.CremaPolygonOverlapped
              ) {
                if (invalidCase.relatedEntityIds) {
                  cremas.forEach(
                    crema =>
                      invalidCase.relatedEntityIds.includes(crema.cremaId) &&
                      entity.push(crema.cremaName)
                  );
                  setErrorPolygonIds?.(invalidCase.relatedEntityIds);
                }
                setErrors(prev => [
                  ...prev,
                  { name: t(invalidCase.error), entity },
                ]);
              } else if (
                invalidCase.error ===
                CremaInvalidCase.CommunityOutsideCremaPolygon
              ) {
                if (invalidCase.relatedEntityIds) {
                  communities.forEach(
                    community =>
                      invalidCase.relatedEntityIds.includes(
                        community.communityId
                      ) && entity.push(community.communityName)
                  );
                }
                setErrorCommunityIds(invalidCase.relatedEntityIds);
                setWarnings(prev => [
                  ...prev,
                  { name: t(invalidCase.error), entity },
                ]);
              } else {
                setErrors(prev => [
                  ...prev,
                  { name: t(invalidCase.error), entity },
                ]);
              }
            } else {
              acc[invalidCase.fieldName].push(t(invalidCase.error));
            }
            return acc;
          },
          {}
        );

        // set errors to form
        Object.entries(errorsByField).forEach(([name, errors]) => {
          form.setFields([{ name, errors }]);
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const showPolygonOnMap = (polygon: ILatLng[]) => {
    drawRef?.current?.setInitialPolygon([[polygon]], DrawState.VIEW_ONLY);
    map?.flyTo({
      lat: polygon[0].lat,
      lng: polygon[0].lng,
    });
  };

  const onValuesChange = (
    changedValues: Partial<IUpdateCremaPayload>,
    values: Store
  ) => {
    if (
      changedValues.hasOwnProperty('cremaPolygonFileUrl') &&
      values.cremaPolygonMap
    ) {
      if (changedValues.cremaPolygonFileUrl) {
        form.setFieldsValue({ cremaPolygonMap: undefined });
        values.cremaPolygonMap = undefined;
      } else {
        drawRef?.current.clear();
      }
    }
    if (
      changedValues.hasOwnProperty('cremaPolygonMap') &&
      values.cremaPolygonFileUrl
    ) {
      form.setFieldsValue({ cremaPolygonFileUrl: undefined });
      values.cremaPolygonFileUrl = undefined;
    }

    if (
      ['cremaPolygonMap', 'cremaPolygonFileUrl', 'cremaName'].some(key =>
        changedValues.hasOwnProperty(key)
      )
    ) {
      validateCrema(values);
    }
  };

  const resetErrors = () => {
    setErrors([]);
    setWarnings([]);
    setErrorPolygonIds?.([]);
    setErrorCommunityIds([]);
  };

  const handleClose = () => {
    resetErrors();
    onClose?.();
  };

  const handleSubmit = async (formValues: Store) => {
    const drawnPolygon: ILatLng[] = formValues.cremaPolygonMap;

    let payload = {
      ...formValues,
      cremaId: initialValues.cremaId,
      cremaImageFileUrl: formValues.cremaImageFileUrl?.[0] || '',
      cremaPolygonFileUrl: formValues.cremaPolygonFileUrl,
      cremaPolygonMap: !!drawnPolygon?.length
        ? drawnPolygon?.map(latlng => ({
            latitude: latlng.lat,
            longitude: latlng.lng,
          }))
        : undefined,
      projectId,
    };

    try {
      setLoading(true);
      await cremaServices.updateCrema(payload);
      notification.success({ message: t('UpdateCremaSuccessfully') });
      handleClose();
      onSuccess?.(payload);
    } finally {
      setLoading(false);
    }
  };

  const handleDrawStateChange = (isDrawing: boolean) => {
    setIsDrawingPolygon(isDrawing);
    if (isDrawing) {
      toggleDrawer(CremaDrawer.UpdateCrema);
    } else {
      toggleDrawer(CremaDrawer.CremaDetail);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmit}
      disabled={loading}
      onValuesChange={onValuesChange}
    >
      <Drawer
        destroyOnClose
        open={open}
        onClose={handleClose}
        mask={false}
        classNames={{ header: 'd-flex', body: 'update-crema-drawer' }}
        getContainer={false}
        zIndex={1001}
        title={
          <Form.Item
            name="cremaName"
            className="mb-0"
            rules={[
              {
                required: true,
                whitespace: true,
                message: tInputPlaceholder('CremaName', {
                  ns: window.appConfig?.appName,
                  lowerCase: false,
                }),
              },
              {
                max: MAX_LENGTH_CREMA_COMMUNITY_NAME,
                message: t('CremaNameTooLong'),
              },
              {
                pattern: REGEX_PATTERN_CREMA_COMMUNITY_NAME,
                message: t('CremaNameIsInvalid'),
              },
            ]}
          >
            <EditableCremaTitle />
          </Form.Item>
        }
      >
        <Typography.Title level={5}>{t('UploadCremaImage')}</Typography.Title>
        <Form.Item name="cremaImageFileUrl">
          <ImageUpload
            isPrivate
            maxCount={1}
            onUploadStateChange={setIsImageUploading}
            maxSize={MAX_FILE_UPLOAD}
          />
        </Form.Item>
        <Typography.Title level={5}>{t('ConfigBoundary')}</Typography.Title>
        <Form.Item name="cremaPolygonFileUrl" rules={[fakeRule]}>
          <KmlFileUpload
            onFileLoaded={showPolygonOnMap}
            onUploadStateChange={setIsFileUploading}
            showUploadList={{ showRemoveIcon: false }}
          />
        </Form.Item>
        <Divider orientation="left" plain>
          Or
        </Divider>
        <Form.Item name="cremaPolygonMap" rules={[fakeRule]}>
          <CremaPolygonDrawer onDrawStateChange={handleDrawStateChange} />
        </Form.Item>
        {errors.map(error => (
          <Alert
            showIcon
            icon={<CloseCircleOutlined />}
            key={error.name}
            type="error"
            className="mt-base"
            message={
              <>
                <Typography.Text>{error.name}</Typography.Text>
                <ul>
                  {error.entity.map(entity => (
                    <li key={entity} style={{ listStylePosition: 'inside' }}>
                      <Typography.Text>{entity}</Typography.Text>
                    </li>
                  ))}
                </ul>
              </>
            }
          />
        ))}
        {warnings.map(warning => (
          <Alert
            showIcon
            icon={<InfoCircleOutlined />}
            key={warning.name}
            type="warning"
            className="mt-base"
            message={
              <>
                <Typography.Text>{warning.name}</Typography.Text>
                <ul>
                  {warning.entity.map(entity => (
                    <li key={entity} style={{ listStylePosition: 'inside' }}>
                      <Typography.Text>{entity}</Typography.Text>
                    </li>
                  ))}
                </ul>
              </>
            }
          />
        ))}
        <Divider />
        <Form.Item noStyle shouldUpdate>
          {({ isFieldsTouched }) => (
            <Flex gap={8}>
              <Button className="flex-1" onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button
                className="flex-1"
                type="primary"
                onClick={form.submit}
                disabled={
                  !isFieldsTouched() ||
                  isImageUploading ||
                  isDrawingPolygon ||
                  isFileUploading ||
                  errors.length > 0 ||
                  !!form.getFieldsError().find(item => item.errors.length)
                }
                loading={loading}
              >
                {t('Save')}
              </Button>
            </Flex>
          )}
        </Form.Item>
      </Drawer>
    </Form>
  );
};

type EditableCremaTitleProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const EditableCremaTitle: React.FC<EditableCremaTitleProps> = ({
  value,
  onChange,
}) => (
  <Typography.Title
    level={4}
    editable={{
      triggerType: ['text'],
      enterIcon: null,
      icon: null,
      onChange: value => value && onChange?.(value),
    }}
    className="mb-0"
  >
    {value}
  </Typography.Title>
);

export { UpdateCrema };
