import {
  GetOrganizationRolesParams,
  OrganizationRole,
} from 'interfaces/organization';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;

const getOrganizationRoles = async (
  params: GetOrganizationRolesParams
): Promise<OrganizationRole[]> => {
  const response = await projectManagerClient.get(
    '/tekbone/api/v1/organization-roles',
    {
      params,
    }
  );
  return response.data.data.organizationRoles;
};

export default { getOrganizationRoles };
