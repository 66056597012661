import {
  Button,
  Drawer,
  DrawerProps,
  Form,
  Input,
  Select,
  notification,
} from 'antd';
import { tInputPlaceholder, tSelectPlaceholder } from 'helpers/i18n';
import { useEffect, useState } from 'react';
import { communityHooks, mapHooks } from 'hooks';
import { filterSelect } from 'helpers/common';
import { cremaServices } from 'services';
import { useProjectContext } from 'contexts';
import { DEBOUNCE_TIME, DEFAULT_MAX_TAG_COUNT } from 'constants/common';
import { commonConstants, cremaConstants } from 'constants/index';
import { useTranslation } from 'react-i18next';

const {
  MAX_LENGTH_CREMA_COMMUNITY_NAME,
  REGEX_PATTERN_CREMA_COMMUNITY_NAME,
} = cremaConstants;
const { IS_WA } = commonConstants;

const { useCremaCommunities } = communityHooks;

interface CreateCREMAProps extends DrawerProps {
  onCreateSuccess: (cremaId: number) => void;
  onClose?: () => void;
}

interface CreateCremaForm {
  cremaName: string;
  communityIds: number[];
}

const CreateCREMA: React.FC<CreateCREMAProps> = ({
  onCreateSuccess,
  ...drawerProps
}) => {
  const { projectId } = useProjectContext();
  const { t } = useTranslation(window.appConfig?.appName);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<CreateCremaForm>();

  const {
    communities,
    loading: communityLoading,
    getCommunities: reloadCommunities,
  } = useCremaCommunities({
    isCremaLinked: false,
  });

  const communityIds = Form.useWatch('communityIds', form);

  // Highlight & focus selected communities
  mapHooks.useMarkerHighlight(communityIds, {
    focus: true,
    highlightDependencies: [drawerProps.open],
  });

  const createCrema = async (formValues: CreateCremaForm) => {
    try {
      setLoading(true);
      const { cremaId } = await cremaServices.createCrema({
        projectId,
        ...formValues,
      });
      drawerProps.onClose?.();
      notification.success({ message: t('CreateCremaSuccessfully') });
      form.resetFields();
      reloadCommunities();
      onCreateSuccess?.(cremaId);
    } finally {
      setLoading(false);
    }
  };

  const validateCremaName = async (cremaName: string) => {
    try {
      setLoading(true);
      // call api to validate crema
      const { invalidCases } = await cremaServices.validateCrema({
        projectId,
        cremaName,
      });
      if (!!invalidCases.length) {
        form.setFields(
          invalidCases.map(invalidCase => ({
            name: invalidCase.fieldName,
            errors: [t(invalidCase.error)],
          }))
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Reset fields when drawer is closed
    if (!drawerProps.open) {
      form.resetFields();
      return;
    }
    reloadCommunities();
  }, [drawerProps.open]);

  return (
    <Drawer
      destroyOnClose={true}
      maskClosable={false}
      title={t('CreateCREMA')}
      zIndex={1005}
      getContainer={false}
      styles={{ footer: { textAlign: 'center' } }}
      mask={false}
      {...drawerProps}
    >
      <Form form={form} layout="vertical" onFinish={createCrema}>
        <Form.Item
          name="cremaName"
          label={t('CremaName')}
          rules={[
            {
              required: true,
              whitespace: true,
              message: t('PleaseInputCremaName'),
            },
            {
              max: MAX_LENGTH_CREMA_COMMUNITY_NAME,
              message: t('CremaNameTooLong'),
            },
            {
              pattern: REGEX_PATTERN_CREMA_COMMUNITY_NAME,
              message: t('CremaNameIsInvalid'),
            },
            {
              validator: (_, value) => {
                if (value.trim()) {
                  validateCremaName(value);
                }
                return Promise.resolve();
              },
            },
          ]}
          validateDebounce={DEBOUNCE_TIME}
        >
          <Input
            placeholder={tInputPlaceholder('CremaName', {
              ns: window.appConfig?.appName,
              lowerCase: false,
            })}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={t('Community')}
          required
          name="communityIds"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            allowClear
            mode="multiple"
            loading={communityLoading}
            placeholder={tSelectPlaceholder('Community', {
              ns: window.appConfig?.appName,
              lowerCase: !IS_WA,
            })}
            options={communities.map(community => ({
              label: community.communityName,
              value: community.communityId,
            }))}
            maxTagCount={DEFAULT_MAX_TAG_COUNT}
            filterOption={filterSelect}
          />
        </Form.Item>
        <Form.Item className="text-center" shouldUpdate>
          {({ isFieldsTouched, getFieldsError, isFieldsValidating }) => {
            const hasErrors = Object.values(getFieldsError()).some(
              ({ errors }) => errors.length
            );
            return (
              <Button
                type="primary"
                onClick={form.submit}
                loading={loading}
                disabled={
                  hasErrors || !isFieldsTouched() || isFieldsValidating()
                }
              >
                {t('Save')}
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export { CreateCREMA };
