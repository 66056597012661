import {
  Button,
  Drawer,
  DrawerProps,
  List,
  Typography,
  notification,
} from 'antd';
import { t } from 'helpers/i18n';
import React, { useEffect, useState } from 'react';
import { staffHooks, userHooks } from 'hooks';
import { CheckOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import './Assignment.scss';
import { ICommunityStaff } from 'interfaces';
import { useProjectContext } from 'contexts';
import { COLOR } from 'constants/styles/color';

interface AssignmentProps extends DrawerProps {
  communityId: number;
  getCommunityDetail: () => void;
}

interface IStaff {
  selected: ICommunityStaff[];
  unselected: ICommunityStaff[];
}

const zIndex = 1002;
const { useUserPermissions } = userHooks;

const Assignment: React.FC<AssignmentProps> = ({
  open,
  communityId,
  onClose,
  getCommunityDetail,
}) => {
  const [staffs, setStaffs] = useState<IStaff>({
    selected: [],
    unselected: [],
  });
  const { projectId } = useProjectContext();
  const {
    staffs: defaultStaffs,
    gettingStaffs,
    getStaffs,
    assignStaffs,
    assigningStaffs,
  } = staffHooks.useCommunityStaffs();
  const { canUpdateCrema } = useUserPermissions();

  const selectStaff = (selectedStaffIdx: number) => {
    const unselectedStaffs = [...staffs.unselected];
    const selectedStaff = unselectedStaffs.splice(selectedStaffIdx, 1)[0];
    const selectedStaffs = [
      ...staffs.selected,
      { ...selectedStaff, isAssignedToCommunity: true },
    ];
    return { selected: selectedStaffs, unselected: unselectedStaffs };
  };

  const unselectStaff = (unselectedStaffIdx: number) => {
    const selectedStaffs = [...staffs.selected];
    const unselectedStaff = selectedStaffs.splice(unselectedStaffIdx, 1)[0];
    const unselectedStaffs = [
      { ...unselectedStaff, isAssignedToCommunity: false },
      ...staffs.unselected,
    ];
    return { selected: selectedStaffs, unselected: unselectedStaffs };
  };

  const onClick = (staffId: number) => {
    const selectedStaffIdx = staffs.unselected.findIndex(
      staff => staff.staffId === staffId
    );
    if (selectedStaffIdx !== -1) {
      // select staff
      setStaffs(selectStaff(selectedStaffIdx));
    } else {
      // unselect staff
      const unselectedStaffIdx = staffs.selected.findIndex(
        staff => staff.staffId === staffId
      );
      setStaffs(unselectStaff(unselectedStaffIdx));
    }
  };

  const onSave = async () => {
    if (!staffs.selected.length) {
      notification.error({
        message: t('MustSelectAtLeast1Staff'),
        duration: 2,
      });
      return;
    }
    await assignStaffs({
      projectId,
      communityId,
      staffIds: staffs.selected.map(staff => staff.staffId),
    });
    notification.success({
      message: t('AssignStaffsSuccessfully'),
      duration: 2,
    });
    getStaffs(communityId);
    getCommunityDetail();
  };

  useEffect(() => {
    if (open) getStaffs(communityId);
  }, [communityId, open]);

  useEffect(() => {
    const reducedStaffs = defaultStaffs.reduce(
      (staffs, curStaff) => {
        return curStaff.isAssignedToCommunity
          ? { ...staffs, selected: [...staffs.selected, curStaff] }
          : { ...staffs, unselected: [...staffs.unselected, curStaff] };
      },
      { selected: [], unselected: [] } as IStaff
    );
    setStaffs(reducedStaffs);
  }, [defaultStaffs]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t('Assignment')}
      styles={{ header: { textAlign: 'center' } }}
      zIndex={zIndex}
      getContainer={false}
      maskClosable={false}
      className="assignment"
      footer={
        canUpdateCrema ? (
          <div className="text-center p-half">
            <Button
              type="primary"
              className="w-120px"
              onClick={onSave}
              loading={assigningStaffs}
            >
              {t('Save')}
            </Button>
          </div>
        ) : (
          undefined
        )
      }
    >
      <List
        header={
          <>
            <span className="text-error mr-quarter">*</span>
            <Typography.Text>{t('Assignees')}</Typography.Text>
          </>
        }
        loading={gettingStaffs}
        dataSource={[...staffs.selected, ...staffs.unselected]}
        bordered
        renderItem={item => {
          return (
            <List.Item
              className={classNames(
                {
                  active: item.isAssignedToCommunity,
                  clickable: canUpdateCrema,
                },
                'assignment__staff'
              )}
              onClick={() => canUpdateCrema && onClick(item.staffId)}
            >
              <Typography.Text>{item.staffName}</Typography.Text>
              {item.isAssignedToCommunity && (
                <CheckOutlined style={{ color: COLOR.ACTIVE }} />
              )}
            </List.Item>
          );
        }}
      />
    </Drawer>
  );
};

export { Assignment };
