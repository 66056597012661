import { Alert, Badge, Button, Space } from 'antd';
import { Dispatch, DispatchWithoutAction, useState } from 'react';
import { CommunityTab } from './CommunityTab';
import {
  EditOutlined,
  FileTextOutlined,
  ProfileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { DocumentationsTab } from './DocumentationsTab';
import AddDocumentation from './AddDocumentation';
import { cremaHooks } from 'hooks';
import { useHistory } from 'react-router-dom';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';
import { CremaDrawer, ICommunity } from 'interfaces';
import OrganizationDetail from '../OrganizationDetail';
import CECMember from './CECMember';
import { UpdateCrema } from './UpdateCrema';
import { userHooks } from 'hooks';
import './CremaDetail.scss';
import withDrawerManager from 'hocs/withDrawerManager';
import { useCremaFormationContext } from 'contexts/cremaFormation';
import { useTranslation } from 'react-i18next';
import { commonConstants } from 'constants/index';
import AddOrgStaffDrawer from 'components/CREMA/OrganizationStaffDrawer';
import { cremaServices } from 'services';
import { useFlagsupContext } from 'contexts';

const { useUserPermissions } = userHooks;
const { IS_AJA } = commonConstants;

interface CremaDetailProps {
  open?: boolean;
  cremaInfo: { id: number };
  onClose?: DispatchWithoutAction;
  onSelectCommunity?: Dispatch<ICommunity['communityId']>;
  onRefreshCremas: DispatchWithoutAction;
  hideMeetingRecords?: boolean;
  hideDocumentations?: boolean;
}

const UpdateCremaDrawer = withDrawerManager(
  UpdateCrema,
  CremaDrawer.UpdateCrema
);
const CECMemberDrawer = withDrawerManager(CECMember, CremaDrawer.CECMember);
const AddStaffDrawer = withDrawerManager(
  AddOrgStaffDrawer,
  CremaDrawer.CECMember
);

const AddDocumentationDrawer = withDrawerManager(
  AddDocumentation,
  CremaDrawer.AddDocumentation
);

const CremaDetail = ({
  open,
  cremaInfo,
  onClose,
  onSelectCommunity,
  onRefreshCremas,
  hideMeetingRecords,
  hideDocumentations,
}: CremaDetailProps) => {
  const { t } = useTranslation(window.appConfig?.appName);
  const history = useHistory();
  const { toggleDrawer } = useCremaFormationContext();
  const { featureFlagsData } = useFlagsupContext();
  const [triggerDocumentationTimes, setTriggerDocumentationTimes] = useState<
    number
  >(1);
  const {
    crema,
    getCremaDetail,
    loading,
    warnings,
    validateCrema,
    updateStaffNumbers,
  } = cremaHooks.useCremaDetail(cremaInfo);
  const { canUpdateCrema } = useUserPermissions();

  // CAR-2787 Phase 2: New organization staff management UI (~ CEC Members of AJA), expected to be enabled for all except AJA
  const isCAR2787Phase2Enabled = featureFlagsData.CAR_2787_phase_2?.enabled;
  const newStaffUIEnabled = isCAR2787Phase2Enabled && !IS_AJA;
  const isCAR1514Enabled = featureFlagsData.CAR_1514?.enabled;

  const items = [
    {
      key: 'Community',
      label: (
        <Space className="text-medium">
          <TeamOutlined />
          {t('Community')}
          <Badge count={crema?.totalCommunities} showZero />
        </Space>
      ),
      children: (
        <CommunityTab
          cremaId={cremaInfo.id}
          setShowDetailCommunity={communityId =>
            onSelectCommunity?.(communityId)
          }
          getCremaDetail={getCremaDetail}
        />
      ),
    },
    {
      key: 'CECMember',
      label: (
        <Space
          className="text-medium clickable"
          onClick={() => toggleDrawer(CremaDrawer.CECMember)}
        >
          <UserOutlined />
          {t('CECMember')}
          <Badge count={crema?.totalCECMembers} showZero />
        </Space>
      ),
      showArrow: false,
      collapsible: 'icon' as CollapsibleType,
    },
    ...(hideMeetingRecords
      ? []
      : [
          {
            key: 'MeetingRecords',
            label: (
              <Space
                className="text-medium clickable"
                onClick={() =>
                  history.push(
                    `/crema-formation/meeting-records?cremaId=${cremaInfo.id}`
                  )
                }
              >
                <ProfileOutlined />
                {t('MeetingRecords')}
                <Badge count={crema?.totalMeetingNotes} showZero />
              </Space>
            ),
            showArrow: false,
            collapsible: 'icon' as CollapsibleType,
          },
        ]),
    ...(hideDocumentations
      ? []
      : [
          {
            key: 'Documentations',
            label: (
              <Space className="text-medium">
                <FileTextOutlined />
                {t('Documentations')}
                <Badge count={crema?.totalDocuments} showZero />
              </Space>
            ),
            children: (
              <DocumentationsTab
                cremaId={cremaInfo.id}
                triggerDocumentationTimes={triggerDocumentationTimes}
                setShowCreationDocumentationModal={() =>
                  toggleDrawer(CremaDrawer.AddDocumentation)
                }
              />
            ),
          },
        ]),
  ];

  const onUpdateCremaSuccess = () => {
    getCremaDetail();
    validateCrema();
    onRefreshCremas();
  };

  return (
    <OrganizationDetail
      organizationName={
        <Space>
          {crema?.cremaName}
          {canUpdateCrema && crema?.cremaId && (
            <Button
              icon={<EditOutlined />}
              type="text"
              onClick={() => toggleDrawer(CremaDrawer.UpdateCrema)}
            />
          )}
        </Space>
      }
      alert={
        !!warnings.length && (
          <Alert
            type="warning"
            showIcon
            description={
              warnings.length > 1 ? (
                <ul className="ml-base">
                  {warnings.map((warning, index) => (
                    <li key={index}>{t(warning.error)}</li>
                  ))}
                </ul>
              ) : (
                t(warnings[0]?.error)
              )
            }
            className="align-items-center p-half mb-half"
          />
        )
      }
      organizationImageUrl={crema?.cremaImageUrl}
      loading={loading}
      open={open}
      items={items}
      onClose={onClose}
    >
      {newStaffUIEnabled ? (
        <AddStaffDrawer
          organization={{
            organizationId: cremaInfo.id,
            organizationTypeName: t('Crema'),
            organizationName: crema?.cremaName,
            organizationTypeId: crema?.cremaTypeId,
          }}
          addStaffRequest={payload =>
            cremaServices.upsertCremasCECMembers({
              ...payload,
              cremaId: payload.organizationId,
            })
          }
          deleteStaffRequest={payload => {
            return isCAR1514Enabled
              ? cremaServices.deleteCECMember({
                  ...payload,
                  cremaId: payload.organizationId,
                })
              : cremaServices.upsertCremasCECMembers({
                  ...payload,
                  cremaId: payload.organizationId,
                  members: [
                    {
                      memberId: payload.memberId,
                      isActive: false,
                    },
                  ],
                });
          }}
          getOrgStaffsRequest={async params => {
            const {
              cecMembers,
              pagination,
            } = await cremaServices.getCremaCecMembers({
              ...params,
              cremaId: params.organizationId,
            });
            return {
              staffs: cecMembers?.map(m => ({
                ...m,
                memberRoleName: m.userRoleName,
              })),
              pagination,
            };
          }}
          onChangeStaffNumbers={updateStaffNumbers}
        />
      ) : (
        <CECMemberDrawer
          cremaId={cremaInfo.id}
          getCremaDetail={getCremaDetail}
        />
      )}

      <AddDocumentationDrawer
        cremaId={cremaInfo.id}
        setTriggerDocumentationTimes={setTriggerDocumentationTimes}
      />
      {canUpdateCrema && crema?.cremaId && (
        <UpdateCremaDrawer
          key={crema?.cremaId}
          initialValues={{
            cremaId: cremaInfo.id,
            cremaName: crema?.cremaName,
            cremaImageFileUrl: crema?.cremaImageUrl
              ? [crema?.cremaImageUrl]
              : undefined,
            cremaPolygonFileUrl: crema?.cremaPolygonFileUrl || undefined,
          }}
          onSuccess={onUpdateCremaSuccess}
        />
      )}
    </OrganizationDetail>
  );
};

export default CremaDetail;
