import {
  IPaginationResponse,
  IPaginationParams,
  IPaginationOffsetParams,
  IPaginationOffsetResponse,
  SortDirection,
} from './common';
import { ICommunity } from './community';
import { IFormAnswer } from './fpic';

export interface ICrema {
  cremaId: number;
  cremaName: string;
  cremaImageUrl: string;
  cremaPolygonMap: {
    latitude: number;
    longitude: number;
  }[];
  cremaPolygonHexColorCode: string;
  cremaPolygonZIndex: number;
}

export interface ICremaDetail extends ICrema {
  cremaTypeId: number; // organization type id
  cremaPolygonFileUrl: string;
  totalCommunities: number;
  totalCECMembers: number;
  totalMeetingNotes: number;
  totalDocuments: number;
}

export interface ICremasResponse {
  code: number;
  traceId: string;
  message: string;
  data: {
    cremas: ICrema[];
    pagination: IPaginationResponse;
  };
}

export interface IGetCremasCrmcMemberPayload {
  page?: number;
  pageSize?: number;
  projectId: number;
  cremaId?: number;
  communityIds?: number[];
}

export interface ICrmcMember {
  memberId: number;
  memberName: string;
  memberRoleName: string;
  phoneNumber: string;
  communityName: string;
}

export interface IGetCremasCrmcMemberResponse {
  crmcMembers: ICrmcMember[];
  pagination: IPaginationResponse;
}

export interface IUpsertCrmcMember {
  memberId: number;
  memberRoleName?: string;
  isActive?: boolean;
}

export interface IUpsertCECMemberPayload {
  projectId: number;
  cremaId: number;
  members: IUpsertCrmcMember[];
}

export interface IUpsertCECMembersResponse {}

export interface IGetCecCandidatesPayload {
  page?: number;
  pageSize?: number;
  projectId: number;
  cremaIds: number[];
}

export interface IGetCremasPayload extends IPaginationOffsetParams {
  projectId: number;
}

export interface IGetCremasResponseData {
  cremas: ICrema[];
  pagination: IPaginationResponse;
}

export enum CremaMeetingRecordStatus {
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Approved = 'APPROVED',
  NeedUpdate = 'NEED_TO_UPDATE',
  Rejected = 'REJECTED',
}

export interface CremaMeetingRecord {
  submissionId: number;
  templateFormTitle: string;
  meetingName: string;
  status: CremaMeetingRecordStatus;
  meetingTime: number;
  communities: Pick<ICommunity, 'communityId' | 'communityName'>[];
  cremaName: string;
}

export interface IGetCremaMeetingRecordParams extends IPaginationOffsetParams {
  projectId: number;
  cremaId?: number;
  communityIds?: number[];
  templateFormIds?: number[];
  submissionStatus?: string;
}

export interface IGetCremaMeetingRecordResponse {
  meetingNoteSubmissions: CremaMeetingRecord[];
  pagination: IPaginationOffsetResponse;
}

export interface FormAnswer extends Omit<Required<IFormAnswer>, 'children'> {
  suggestionValue: { label: string; value: string }[];
  children: Omit<FormAnswer, 'value' | 'sectionNumber'>[];
  fieldDescription: string;
  hint: string;
  ordinal: number;
}

export interface CremaMeetingRecord {
  submissionId: number;
  templateFormTitle: string;
  answers: FormAnswer[];
  uniqueTekon: string;
}

export interface IGetCremaMeetingRecordDetailParams {
  submissionId: number;
  projectId: number;
}

export interface IUpsertMeetingNoteSubmission {
  submissionId: number;
  answers: Pick<FormAnswer, 'questionId' | 'fieldName' | 'value'>[];
}

export interface MeetingNoteSubmissionApprovalPayload {
  submissionId: number;
  projectId: number;
  action: 'REQUEST_TO_UPDATE' | 'APPROVE' | 'REJECT';
  note?: string;
}

export interface ICremaMeetingNoteComment {
  id: number;
  createdAt: number;
  content: string;
  userEmail: string;
  comment: string;
}

export interface IGetCremaMeetingNoteCommentsParams extends IPaginationParams {
  submissionId: number;
  orderDirection?: SortDirection;
}

export interface IGetCremaMeetingNoteCommentsResponse {
  comments: ICremaMeetingNoteComment[];
  pagination: IPaginationResponse;
}

export interface ICreateCremaMeetingNoteComment {
  submissionId: number;
  content: string;
}

export interface CreateCremaPayload {
  projectId: number;
  cremaName: string;
  communityIds: number[];
}

export interface IGetListCremaOrganizationsParams
  extends IPaginationOffsetParams {
  projectId: number;
  keyword?: string;
  organizationType?: 'CREMA' | 'COMMUNITY';
}

export interface ICremaOrganization {
  projectId: number;
  organizationId: number;
  organizationName: string;
  organizationType: 'CREMA' | 'COMMUNITY';
}

export interface IGetListCremaOrganizationsResponse {
  organizations: ICremaOrganization[];
  pagination: IPaginationOffsetResponse;
}

export interface IGetCremaDetailParams {
  projectId: number;
  cremaId: number;
}

export interface IUpdateCremaPayload {
  cremaId?: number;
  projectId: number;
  cremaName?: string;
  cremaImageFileId?: string;
  cremaPolygonFileUrl?: string;
  cremaPolygonMap?: { latitude: number; longitude: number }[];
}

export interface IValidateCremaResponse {
  invalidCases: {
    fieldName: string;
    error: string;
    relatedEntityIds: number[];
  }[];
}

export enum CremaInvalidCase {
  DuplicatedCremaName = 'DUPLICATED_CREMA_NAME',
  CommunityOutsideCremaPolygon = 'COMMUNITY_OUTSIDE_CREMA_POLYGON',
  CremaPolygonOverlapped = 'CREMA_POLYGON_OVERLAPPED',
  CremaOutsideProjectBoundary = 'CREMA_OUTSIDE_PROJECT_BOUNDARY',
}

export enum CremaDrawer {
  CremaDetail = 'CremaDetailDrawer',
  CommunityDetail = 'CommunityDetailDrawer',
  CreateCommunity = 'CreateCommunityDrawer',
  CreateCrema = 'CreateCremaDrawer',
  UpdateCrema = 'UpdateCremaDrawer',
  UpdateCommunity = 'UpdateCommunityDrawer',
  CRMCMember = 'CRMCMemberDrawer',
  AddDocumentation = 'AddDocumentationDrawer',
  AddCommunity = 'AddCommunityDrawer',
  Assigment = 'AssignmentDrawer',
  CECMember = 'CECMemberDrawer',
}

export enum CremaTemplateFormType {
  CremaMeeting = 'CREMA_MEETING',
}

export interface ICremaFormTemplate {
  projectId: number;
  templateFormId: number;
  templateFormType: CremaTemplateFormType;
  templateFormTitle: string;
}

export interface IGetCremaMeetingNoteTemplatesParams {
  projectId: number;
  templateFormTypes?: CremaTemplateFormType[];
}

export interface IGetCremaMeetingNoteTemplatesResponse {
  templateForms: ICremaFormTemplate[];
}
