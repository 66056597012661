import {
  CustomLeafletMarkerIcon,
  LeafletMarkerIcon,
} from 'components/shared/Map';
import { IManualSamplingPlot } from 'interfaces';
import { LeafletEvent } from 'leaflet';
import React, { useEffect, useMemo, useRef } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { updateManualSamplingPlots } from 'redux/features/samplingPlot';
import { AppDispatch } from 'redux/store';
import { ManualAddSamplingPlotForm } from './ManualAddSamplingPlotForm';
import { selectProject } from 'redux/features/project';

interface IManualAddSplingPlotMarkerProps {
  index: number;
  samplingPlot: IManualSamplingPlot;
}

const ManualAddSplingPlotMarker: React.FC<IManualAddSplingPlotMarkerProps> = ({
  index,
  samplingPlot,
}) => {
  const markerRef = useRef<any>(null);
  const dispatch: AppDispatch = useDispatch();
  const projectDetails = useSelector(selectProject);

  const handleMarkerDragEnd = (
    index: number,
    event: LeafletEvent,
    radius: number
  ) => {
    const { lat, lng } = event.target.getLatLng();
    dispatch(
      updateManualSamplingPlots({
        lat: lat,
        lng: lng,
        radius: radius,
        index: index,
        isActive: true,
      })
    );
  };

  const markerData = useMemo(() => {
    return projectDetails?.samplingModules?.find(samplingModule => {
      return samplingModule.id === samplingPlot.moduleId;
    });
  }, [samplingPlot.moduleId]);

  const markerIcon = useMemo(() => {
    if (markerData) {
      return CustomLeafletMarkerIcon(
        markerData.backgroundColorCode,
        markerData.colorCode
      );
    } else {
      return LeafletMarkerIcon.DEFAULT.NO_DATA;
    }
  }, [samplingPlot.moduleId]);

  useEffect(() => {
    if (samplingPlot.isActive) markerRef.current?.openPopup();
  }, [samplingPlot.isActive]);

  return (
    <Marker
      position={samplingPlot.center}
      draggable={!samplingPlot.submitted}
      icon={markerIcon}
      key={index}
      eventHandlers={{
        dragend: e => {
          handleMarkerDragEnd(index, e, samplingPlot.radius);
          markerRef.current?.openPopup();
        },
      }}
      ref={markerRef}
    >
      <Popup
        minWidth={350}
        className="sampling-plot-point-popup"
        autoClose={false}
        closeOnClick={false}
      >
        <ManualAddSamplingPlotForm
          location={samplingPlot.center}
          index={index}
          plotRadius={samplingPlot.radius}
          submitted={samplingPlot.submitted}
          markerRef={markerRef}
        />
      </Popup>
    </Marker>
  );
};

export { ManualAddSplingPlotMarker };
export default ManualAddSplingPlotMarker;
