import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Upload,
  Radio,
} from 'antd';
import { KML_TYPES } from 'constants/file';
import { ProjectDetailV2Context } from 'containers/Project/ProjectDetailMapV2/context';
import { t } from 'helpers/i18n';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import './AddPolygonDrawer.scss';
import { DrawState } from 'constants/map';
import { polygonHooks, uploadHooks } from 'hooks';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/es/upload';
import { fileHelpers, mapHelpers } from 'helpers';
import { IUpsertPolygonPayload } from 'interfaces';
import { useHistory } from 'react-router-dom';
import { cleanObject } from 'helpers/common';
import { POLYGON_TYPES } from 'constants/project';
import { DRAWER_WIDTH } from 'constants/common';

const { getPolygonFromKmlFile } = fileHelpers;
const { getPolygonsMapToSendAPI, getPolygonWktFromLatLng } = mapHelpers;

// BE currently cannot handle add CREMA and Farmland polygon type, FE need to remove them
const CREMA_AND_FARMLAND: number[] = [
  POLYGON_TYPES.CREMA.id,
  POLYGON_TYPES.FARMLAND.id,
];

const AddPolygonDrawer: React.FC = () => {
  const [uploadPolygonType, setUploadPolygonType] = useState(
    'UPLOAD_SHAPE_FILE'
  );
  const [file, setFile] = useState<RcFile>();
  const [form] = Form.useForm();
  const {
    setDrawState,
    isAddProjectBoundary,
    showAddPolygon,
    setShowAddPolygon,
    drawRef,
    setShowAddPolygonScreen,
    setCurrentPolygonId,
  } = useContext(ProjectDetailV2Context);
  const { projectId } = useProjectContext();
  const { upsertingPolygon, upsertPolygon } = polygonHooks.usePolygon();
  const {
    polygonTypes: originalPolygonTypes,
    loading: loadingPolygonTypes,
  } = polygonHooks.usePolygonTypes();
  const { uploadFile, uploading } = uploadHooks.useUploadFile();
  const history = useHistory();
  const { featureFlagsData } = useFlagsupContext();
  const isCAR2887Enabled = featureFlagsData.CAR_2887?.enabled;

  const onClose = () => {
    setShowAddPolygon(false);
    setUploadPolygonType('UPLOAD_SHAPE_FILE');
    setDrawState?.(DrawState.NONE);
    form.resetFields();
    setFile(undefined);
    if (isAddProjectBoundary) {
      setShowAddPolygonScreen(true);
    }
  };

  const validateAndUploadKmlFile = async (file: RcFile) => {
    if (file.size > 30 * 1024 * 1024) {
      notification.warning({
        message: t('OnlySupportKML'),
      });
      return;
    }

    const splitName = file.name?.split('.');
    const fileType = splitName?.length
      ? splitName[splitName.length - 1]
      : undefined;

    if (fileType !== 'kml') {
      notification.warning({
        message: t('InvalidKMLFile'),
      });
      return;
    }

    const url = await uploadFile(file as File, {
      type: 'doc',
      fileContentType: 'application/vnd.google-earth.kml+xml',
      // isPrivate: true,
    });

    form.setFieldValue('polygonFileUrl', url);
    setFile(file as RcFile);
  };

  const customRequest = async (options: UploadRequestOption) => {
    const { file } = options;
    const loadedData = await getPolygonFromKmlFile(file as RcFile);
    if (loadedData.error) {
      notification.error({
        message: loadedData.error,
      });
      return;
    }
    validateAndUploadKmlFile(file as RcFile);
    drawRef?.current?.clear();
  };

  const onFinish = async (values: any) => {
    let result;
    const polygonsMap = drawRef?.current?.getDrawPolygon();
    const params = cleanObject({
      ...values,
      ...(isCAR2887Enabled
        ? { multiplePolygonWkt: getPolygonWktFromLatLng(polygonsMap) }
        : uploadPolygonType !== 'DRAW_POLYGON'
        ? { polygonMap: [] }
        : { multiplePolygonMap: getPolygonsMapToSendAPI(polygonsMap) }),
      projectId,
    });
    if (
      params.polygonFileUrl ||
      params.polygonMap ||
      params.multiplePolygonMap ||
      params.multiplePolygonWkt
    ) {
      result = await upsertPolygon(params as IUpsertPolygonPayload);
    } else {
      notification.warning({
        message: t('InvalidPolygon'),
      });
    }

    if (result?.polygonId) {
      const { pathname } = history.location;
      if (result?.isOverlapped)
        Modal.warning({ title: t('TheAreaOverlapped'), centered: true });
      else
        notification.success({
          message: t('AddPolygonSuccessfully'),
        });
      setShowAddPolygon(false);
      form.resetFields();
      setDrawState?.(DrawState.NONE);
      drawRef?.current?.clear();
      history.replace(pathname);
      onClose();
      setShowAddPolygonScreen(false);
      setCurrentPolygonId(result.polygonId);
    }
  };

  // Remove project boundary type when needed
  const polygonTypes = useMemo(() => {
    if (isAddProjectBoundary) return originalPolygonTypes;
    return originalPolygonTypes
      ?.filter(p => !p.isProjectAreaType)
      .filter(p => !CREMA_AND_FARMLAND.includes(p.polygonTypeId));
  }, [originalPolygonTypes, isAddProjectBoundary]);

  useEffect(() => {
    if (isAddProjectBoundary) {
      form.setFieldsValue({
        polygonTypeId: polygonTypes?.filter(p => p.isProjectAreaType)?.[0]
          ?.polygonTypeId,
      });
    } else {
      form.resetFields();
    }
  }, [polygonTypes, isAddProjectBoundary]);

  return (
    <Drawer
      title={t('AddPolygon')}
      placement="right"
      closable={true}
      destroyOnClose={true}
      maskClosable={false}
      onClose={onClose}
      open={showAddPolygon}
      getContainer={false}
      mask={false}
      zIndex={1002}
      width={DRAWER_WIDTH.MEDIUM_LARGE}
      rootStyle={{ position: 'absolute' }}
      styles={{
        footer: { textAlign: 'center' },
      }}
      className="add-polygon-drawer"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t('PolygonName')}
          name="polygonName"
          rules={[{ required: true, whitespace: true }]}
        >
          <Input placeholder={t('AreaNamePlaceholder')} />
        </Form.Item>
        <Form.Item
          label={t('PolygonType')}
          name="polygonTypeId"
          rules={[{ required: true }]}
        >
          <Select
            disabled={isAddProjectBoundary}
            options={polygonTypes?.map(polygonType => {
              return {
                label: polygonType.polygonTypeName,
                value: polygonType.polygonTypeId,
              };
            })}
            loading={loadingPolygonTypes}
            placeholder={t('SelectPolygonType')}
          />
        </Form.Item>
        <Form.Item>
          <Radio.Group
            value={uploadPolygonType}
            onChange={e => setUploadPolygonType(e.target.value)}
          >
            <Radio.Button
              value="UPLOAD_SHAPE_FILE"
              type="primary"
              onClick={() => {
                setDrawState?.(DrawState.NONE);
              }}
            >
              {t('UploadShapeFile')}
            </Radio.Button>
            <Radio.Button
              value="DRAW_POLYGON"
              type="primary"
              onChange={() => {
                setDrawState?.(DrawState.DRAW);
                setFile(undefined);
              }}
            >
              {t('DrawPolygon')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        {uploadPolygonType === 'UPLOAD_SHAPE_FILE' && (
          <Form.Item
            name="polygonFileUrl"
            rules={[
              {
                required: uploadPolygonType === 'UPLOAD_SHAPE_FILE',
                message: t('PleaseUploadShapeFile'),
              },
            ]}
          >
            <Upload.Dragger
              maxCount={1}
              showUploadList={false}
              customRequest={customRequest}
              accept={KML_TYPES.join(',')}
              children={
                <Space direction="vertical" align="center">
                  {!file && (
                    <>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{t('DropYourFileHere')}</p>
                      <p className="ant-upload-hint">{t('OnlySupportKML')}</p>
                    </>
                  )}
                  <Spin size="small" spinning={uploading}>
                    {file && file.name}
                  </Spin>
                </Space>
              }
            />
          </Form.Item>
        )}

        <Form.Item>
          <Space className="justify-content-center w-100">
            <Button onClick={onClose}>{t('Cancel')}</Button>
            <Button
              className="submit-button"
              htmlType="submit"
              loading={upsertingPolygon}
              type="primary"
            >
              {t('Save')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export { AddPolygonDrawer };
export default AddPolygonDrawer;
