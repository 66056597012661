const APPS = {
  PROJECT_MANAGER: 'project-manager-bff',
  PROJECT_MANAGER_TEKBONE: 'pm-tekbone-bff',
  SUPERSET_PUBLIC: 'superset-public',
};

const RESOURCES = {
  FPIC: {
    PM: 'fpic-pm',
    VOLUNTEER: 'fpic-volunteer',
  },
  AJA_INVESTORS: 'aja-investors',
  FPIC_INTERNAL_REPORT: 'fpic-internal-report',
  USER: 'user',
  FUND: 'fund',
  CARBON_POTENTIAL_REPORT: 'carbon-potential-report',
  FS_REPORT: 'fs-report',
  CONSULTANT_REPORT: 'consultant-report',
  PROJECT_BOUNDARIES: 'project-boundaries',
  CARBON_ASSESSMENT: 'carbon-assessment',
  RISK_ASSESSMENT: 'risk-assessment',
  CONTROL_PLOTS: 'control-plots',
  CARBON_BASELINE: 'carbon-baseline',
  PERFORMANCE_BENCHMARK: 'performance-benchmark',
  VERRA_PDD: 'verra-pdd',
  MEETING_RECORDS: 'meeting-records',
  TEMPLATE_MANAGEMENT: 'template-management',
  FPIC_DASHBOARDS: 'fpic-dashboards',
  CREMA_FORMATION: 'crema-formation',
  CREMA_MEETING_RECORD: 'crema-meeting-record',
  CREMA_OPERATION: 'crema-operation',
  CREMA_REPORTS: 'crema-reports',
  FARMER_ENROLLMENT: 'farmer-enrollment',
  FARM_LAND_MANAGEMENT: 'farm-land-management',
  FARM_ELIGIBLE_REVIEW: 'farm-eligible-review',
  FARMER_PROFILES: 'farmer-profiles',
  LPR: 'lpr',
  BENEFIT_MANAGEMENT: 'benefit-management',
  PAYMENT_SERVICES: 'payment-services',
  FINANCIAL_REPORTING: 'financial-reporting',
  CARBON: 'carbon',
  BIODIVERSITY: 'biodiversity',
  ME_COMMUNITY: 'me-community',
  LANDSCAPE_GOVERNANCE: 'landscape-governance',
  ME_REPORTS: 'me-reports',
  TICKETS: 'tickets',
  FPIC_MANAGEMENT: 'fpic-management',
  FARMER_MANAGEMENT: 'farmer-management',
  USER_PROJECT: 'user-project',
  PROJECTS: 'projects',
  ACTIVITY: 'activity',
  COMMUNITIES: 'communities',
  REPORT: 'report',
  HUMAN_RESOURCE_MANAGEMENT: 'human-resource-management',
  ACCOUNT_MANAGEMENT: 'accounts-management',
  SUBMISSION: 'submission',
  PLAN: 'plan',
  FIRE_PLAN_AND_TRAINING: 'fire-plan-and-training',
  QUARTERLY_REPORTS: 'quarterly-reports',
  FARMER_MANAGEMENT_CONSULTANT_REPORT: 'farmer-management-consultant-report',
  MEMBER_MANAGEMENT: 'member-management',
  DOCUMENT: 'document',
  WILDASIA_QUARTERLY_REPORT: 'view-wildasia-quarterly-report',

  // Superset
  VIEW_PLANTING_KPI: 'view-planting-kpi',
  DASHBOARD: 'dashboard',
};

const ACTIONS = {
  CREATE: 'create',
  READ: 'read',
  READ_LIST: 'read:list',
  UPDATE: 'update',
  DELETE: 'delete',
  IMPORT: 'create:import',
  READ_REPORT: 'read:report',
  APPROVE: 'update:approve',
  EDIT: 'update:edit',
  PRINT: 'read:print',
  UPDATE_SURVEY: 'update:survey',
  DOWNLOAD: 'read:download',

  // Crema Formation
  CREATE_CEC: 'create:cec',
  DELETE_CEC: 'delete:cec',
  CREATE_CRMC: 'create:crmc',
  DELETE_CRMC: 'delete:crmc',
};

export default {
  APPS,
  RESOURCES,
  ACTIONS,
};
