import {
  UserOutlined,
  ProfileOutlined,
  TeamOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Badge, Drawer, DrawerProps, Space, Button, CollapseProps } from 'antd';
import { CremaDrawer, IFarmer } from 'interfaces';
import { FarmersTab } from './FarmersTab';
import './CommunityDetail.scss';
import { useEffect, useState } from 'react';
import { communityHooks } from 'hooks';
import { ID_DEFAULT } from 'constants/common';
import { useHistory } from 'react-router-dom';
import OrganizationDetail from '../OrganizationDetail';
import { FarmerDetails } from 'components/shared/Farmer';
import { CRMCMember } from './CRMCMember';
import { userHooks } from 'hooks';
import { UpsertCommunity } from 'components/CREMA/UpsertCommunity/UpsertCommunity';
import { Assignment } from '../Assignment';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';
import { useFlagsupContext } from 'contexts';
import { useCremaFormationContext } from 'contexts/cremaFormation';
import withDrawerManager from 'hocs/withDrawerManager';
import { useMapContext } from 'contexts/map';
import { useTranslation } from 'react-i18next';
import { commonConstants } from 'constants/index';
import AddOrgStaffDrawer from 'components/CREMA/OrganizationStaffDrawer';
import { cremaServices } from 'services';

const { useUserPermissions } = userHooks;
const { IS_WA, IS_AJA } = commonConstants;

interface CommunityDetailProps extends DrawerProps {
  communityInfo: { id: number };
  onUpdateSuccess?: () => void;
  onClose?: () => void;
  hideMeetingRecords?: boolean;
}

const UpsertCommunityDrawer = withDrawerManager(
  UpsertCommunity,
  CremaDrawer.UpdateCommunity
);
const AssignmentDrawer = withDrawerManager(Assignment, CremaDrawer.Assigment);
const CRMCMemberDrawer = withDrawerManager(CRMCMember, CremaDrawer.CRMCMember);
const StaffDrawer = withDrawerManager(
  AddOrgStaffDrawer,
  CremaDrawer.CRMCMember
);

const CommunityDetail: React.FC<CommunityDetailProps> = ({
  open,
  communityInfo,
  rootStyle,
  onClose,
  onUpdateSuccess,
  hideMeetingRecords,
}) => {
  const { t } = useTranslation(window.appConfig?.appName);
  const [farmerDetail, setFarmerDetail] = useState<IFarmer>();
  const {
    community,
    loading,
    getCommunityDetail,
    updateStaffNumbers,
  } = communityHooks.useCommunityDetail(communityInfo);
  const history = useHistory();
  const { canUpdateCrema } = useUserPermissions();
  const { toggleDrawer } = useCremaFormationContext();
  const { setPOI } = useMapContext();
  const { featureFlagsData } = useFlagsupContext();

  // CAR-2311: Assign staff to community - AJA only
  const isCAR2311Enabled = featureFlagsData.CAR_2311?.enabled;
  const staffMenuEnabled = isCAR2311Enabled && !IS_WA;

  // CAR-2787 Phase 2: New organization staff management UI (~ CRMC Members of AJA), expected to be enabled for all except AJA
  const isCAR2787Phase2Enabled = featureFlagsData.CAR_2787_phase_2?.enabled;
  const newStaffUIEnabled = isCAR2787Phase2Enabled && !IS_AJA;
  const isCAR1514Enabled = featureFlagsData.CAR_1514?.enabled;

  const items: CollapseProps['items'] = [
    ...(staffMenuEnabled
      ? [
          {
            key: 'Staffs',
            label: (
              <Space
                className="text-medium clickable"
                onClick={() => toggleDrawer(CremaDrawer.Assigment)}
              >
                <TeamOutlined />
                {t('AssignedStaffs')}
                <Badge count={community?.totalAssignedStaffs} showZero />
              </Space>
            ),
            showArrow: false,
            collapsible: 'icon' as CollapsibleType,
          },
        ]
      : []),
    {
      key: 'Farmers',
      label: (
        <Space className="text-medium">
          <TeamOutlined />
          {t('Farmers')}
          <Badge count={community?.totalFarmers} showZero />
        </Space>
      ),
      children: (
        <FarmersTab
          communityId={communityInfo.id}
          onClickFarmer={setFarmerDetail}
        />
      ),
    },
    {
      key: 'CRMC',
      label: (
        <Space
          className="text-medium clickable"
          onClick={() => toggleDrawer(CremaDrawer.CRMCMember)}
        >
          <UserOutlined />
          {t('CRMCMembers')}
          <Badge count={community?.totalCRMCMembers} showZero />
        </Space>
      ),
      showArrow: false,
      collapsible: 'icon',
    },
    ...(!hideMeetingRecords
      ? [
          {
            key: 'MeetingRecords',
            label: (
              <Space
                className="text-medium clickable"
                onClick={() =>
                  history.push(
                    `/crema-formation/meeting-records?communityId=${communityInfo.id}`
                  )
                }
              >
                <ProfileOutlined />
                {t('MeetingRecords')}
                <Badge count={community?.totalMeetingNotes} showZero />
              </Space>
            ),
            showArrow: false,
            collapsible: 'icon' as CollapsibleType,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (communityInfo.id === ID_DEFAULT) {
      setFarmerDetail(undefined);
    }
  }, [communityInfo]);

  useEffect(() => {
    if (community)
      setPOI({
        poiId: community.communityId,
        placeName: community.communityName,
      });
  }, [community]);

  return (
    <OrganizationDetail
      zIndex={1001}
      rootStyle={rootStyle}
      open={open}
      loading={loading}
      organizationName={
        <Space>
          {community?.communityName}
          {canUpdateCrema && (
            <Button
              icon={<EditOutlined />}
              type="text"
              onClick={() => toggleDrawer(CremaDrawer.UpdateCommunity)}
            />
          )}
        </Space>
      }
      organizationImageUrl={community?.communityImageUrl}
      items={items}
      onClose={() => {
        setFarmerDetail(undefined);
        onClose?.();
      }}
    >
      {communityInfo.id !== ID_DEFAULT && (
        <>
          <Drawer
            placement="left"
            width={340}
            open={!!farmerDetail}
            mask={false}
            getContainer={false}
            zIndex={998}
            rootStyle={{ left: Number(rootStyle?.left || 0) + 350, margin: 10 }}
            styles={{ body: { padding: 0 }, header: { padding: 5 } }}
            onClose={() => setFarmerDetail(undefined)}
            closeIcon={<CloseOutlined className="organization-close-icon" />}
          >
            {!!farmerDetail && (
              <FarmerDetails farmerId={farmerDetail.farmerId} />
            )}
          </Drawer>
          {newStaffUIEnabled ? (
            <StaffDrawer
              organization={{
                organizationId: communityInfo.id,
                organizationTypeName: t('Community'),
                organizationName: community?.communityName,
                organizationTypeId: community?.communityTypeId,
              }}
              getOrgStaffsRequest={async payload => {
                const {
                  crmcMembers: staffs,
                  pagination,
                } = await cremaServices.getCremasCrmcMembers({
                  ...payload,
                  communityIds: [communityInfo.id],
                });
                return { staffs, pagination };
              }}
              addStaffRequest={payload =>
                cremaServices.upsertCRMCMembers({
                  ...payload,
                  communityId: payload.organizationId,
                })
              }
              deleteStaffRequest={payload =>
                isCAR1514Enabled
                  ? cremaServices.deleteCRMCMember({
                      ...payload,
                      communityId: communityInfo.id,
                    })
                  : cremaServices.upsertCRMCMembers({
                      ...payload,
                      communityId: communityInfo.id,
                      members: [
                        {
                          memberId: payload.memberId,
                          isActive: false,
                        },
                      ],
                    })
              }
              onChangeStaffNumbers={updateStaffNumbers}
            />
          ) : (
            <CRMCMemberDrawer
              communityId={communityInfo.id}
              getCommunityDetail={getCommunityDetail}
              hideFarmerCheckbox={IS_WA}
            />
          )}

          {/* Mount UpsertCommunity after fetching community details to ensure correct initial values */}
          {canUpdateCrema && (
            <UpsertCommunityDrawer
              key={community?.communityId}
              initialValues={{
                ...community,
                poiId: communityInfo.id,
                communityImageId: community?.communityImageUrl
                  ? [community?.communityImageUrl]
                  : [],
              }}
              onUpsertSuccess={() => {
                getCommunityDetail();
                onUpdateSuccess?.();
              }}
            />
          )}
          <AssignmentDrawer
            communityId={communityInfo.id}
            getCommunityDetail={getCommunityDetail}
          />
        </>
      )}
    </OrganizationDetail>
  );
};

export default CommunityDetail;
