import { ConfigProviderProps } from 'antd';
import { ReactNode } from 'react';

type Locale = ConfigProviderProps['locale'];

export interface IPermission {
  app: string;
  resource: string;
  action?: string;
}

export interface IRoute {
  exact?: boolean;
  path: string;
  name: string;
  useParamName?: string;
  component: React.ElementType;
  permissions?: IPermission[];
  icon?: React.ComponentType<{ className?: string }>;
  children?: string[];
  title?: string;
  hideTitle?: boolean;
  iFrameSrc?: string;
  redirectTo?: string;
  hideBreadcrumb?: boolean;
  flagKey?: FLAGS;
  hideProjectSelect?: boolean;
}

export interface IRegionItem {
  key: string;
  name: string;
  flag: string;
  antdLocale: Locale;
}

export interface IRegion {
  [key: string]: IRegionItem;
}

export interface IMutable {
  mutate: () => void;
}

export type ICountry = 'vi' | 'id' | 'en';

export interface IPaginationParams {
  page: number;
  pageSize: number;
}

export interface IPaginationResponse extends IPaginationParams {
  total: number;
}

export interface IPaginationOffsetParams {
  offset: number;
  limit: number;
}

export interface IPaginationOffsetResponse {
  total?: number;
  offset: number; //return next offset
}

export interface IBaseApiResponse {
  code: number;
  message: string;
  traceId: string;
  status: number;
}

export interface IApiResponse<T> {
  data?: {
    code: number;
    message: string;
    traceId: string;
    data?: T;
    pagination?: IPaginationResponse;
  };
}
export interface IMap<T> {
  [key: string]: T;
}

export type PartialAllExceptOne<T, K extends keyof T> = Partial<T> &
  Required<Pick<T, K>>;

export interface IItem {
  label?: string;
  value?: any;
  icon?: ReactNode;
  onClick?: (e: any) => void;
}

export type AnyObject = IMap<any>;

export interface IDefaultOption {
  value: string | number;
  label: React.ReactNode | any;
  disabled?: boolean;
  title?: string;
}

export type SortDirection = 'asc' | 'desc';

export interface IFlagItem {
  enabled: boolean;
  expBranchId?: number;
  expId?: number;
  treatment?: string;
}

export type FLAGS =
  | 'CAR_418'
  | 'CAR_2311'
  | 'CAR_2398'
  | 'CAR_2458'
  | 'CAR_2355'
  | 'CARSA_243_VIEWPORT'
  | 'CARHCM_507'
  | 'CAR_1598'
  | 'CAR_377'
  | 'CAR_2356'
  | 'CAR_2399'
  | 'CAR_1514'
  | 'CARSA_658'
  | 'CARHCM_786'
  | 'CARSA_919'
  | 'CAR_613'
  | 'CAR_2619'
  | 'CARHCM_856'
  | 'CARSA_1046'
  | 'CARSA_853'
  | 'CAR_2787'
  | 'CAR_2787_phase_2'
  | 'CAR_2887';

export type IFlagsData = {
  [key in FLAGS]?: IFlagItem;
};

export interface IFlagsSDK {
  [key: string]: IFlagItem;
}

export interface IGoogleImageSizingOptions {
  size?: number;
  width?: number;
  height?: number;
  squareCrop?: boolean;
  smartCrop?: boolean;
}

export enum ApprovalAction {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}
