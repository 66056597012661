import { DeleteOutlined } from '@ant-design/icons';
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Drawer,
  DrawerProps,
  Form,
  Input,
  Popconfirm,
  Select,
  Spin,
  Table,
  notification,
} from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import classNames from 'classnames';
import { IS_CEC_MEMBER_CODE } from 'components/CREMA/constants';
import { CRMC_ROLES } from 'constants/community';
import {
  DEFAULT_PAGE,
  PAGE_SIZE_ALL,
  PAGINATION_TOTAL_DEFAULT,
} from 'constants/pagination';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { filterSelect } from 'helpers/common';
import paginationHelpers from 'helpers/pagination';
import { userHooks } from 'hooks';
import {
  ICRMCCandidate,
  ICRMCMember,
  IPaginationParams,
  IPaginationResponse,
  IUpsertCRMCMember,
} from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cremaServices } from 'services';
import { checkTelephone } from 'utils/formValidator';

const { generateRowIndex } = paginationHelpers;
const { useUserPermissions } = userHooks;

interface CRMCMemberProps extends Pick<DrawerProps, 'open' | 'onClose'> {
  communityId: number;
  getCommunityDetail: () => void;
  hideFarmerCheckbox?: boolean;
}

interface IAddCRMCMemberForm extends IUpsertCRMCMember {
  roleName?: string;
}

export const CRMCMember: React.FC<CRMCMemberProps> = ({
  communityId,
  getCommunityDetail,
  open,
  onClose,
  hideFarmerCheckbox,
}) => {
  const { t } = useTranslation(window.appConfig?.appName);
  const { projectId } = useProjectContext();
  const [loading, setLoading] = useState(false);
  const [CRMCMembers, setCRMCMembers] = useState<ICRMCMember[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>(
    PAGINATION_TOTAL_DEFAULT
  );
  const [gettingCRMCCandidates, setGettingCRMCCandidates] = useState(false);
  const [CRMCCandidates, setCRMCCandidates] = useState<ICRMCCandidate[]>([]);
  const [form] = Form.useForm<IAddCRMCMemberForm>();

  const [showWarning, setShowWarning] = useState(false);
  const { featureFlagsData } = useFlagsupContext();
  const isCAR1514Enabled = featureFlagsData.CAR_1514?.enabled;

  const { canDeleteCRMCMember, canCreateCRMCMember } = useUserPermissions();

  const columns: ColumnsType<ICRMCMember> = [
    {
      title: '#',
      dataIndex: 'key',
      align: 'center',
      width: 60,
      render: (key, member, index) =>
        generateRowIndex(pagination.page, pagination.pageSize, index),
    },
    {
      title: t('Member'),
      dataIndex: 'memberName',
      width: 140,
    },
    {
      title: t('PhoneNumber'),
      dataIndex: 'phoneNumber',
      width: 140,
    },
    {
      title: t('Role'),
      dataIndex: 'memberRoleName',
    },
    {
      title: t('Action'),
      align: 'center',
      width: 60,
      hidden: !canDeleteCRMCMember,
      render: member => {
        return (
          <div className="text-center">
            <Popconfirm
              title=""
              description={t('AreYouSureToDeleteThisMember')}
              onConfirm={() => deleteCRMCMember(member)}
              okText={t('Yes')}
              cancelText={t('No')}
              rootClassName="crema-popconfirm"
            >
              <Button type="text" danger icon={<DeleteOutlined size={20} />} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const close = (e: any) => {
    onClose?.(e);
    setShowWarning(false);
    setLoading(false);
    setGettingCRMCCandidates(false);
    form.resetFields();
  };

  const getCRMCMembers = async (curPagination?: IPaginationParams) => {
    try {
      setLoading(true);
      const page = curPagination?.page || PAGINATION_TOTAL_DEFAULT.page;
      const {
        crmcMembers: crmcMemRes,
        pagination: paginationRes,
      } = await cremaServices.getCremasCrmcMembers({
        page,
        pageSize: curPagination?.pageSize || PAGINATION_TOTAL_DEFAULT.pageSize,
        communityIds: [communityId],
        projectId,
      });
      setCRMCMembers(crmcMemRes || []);
      setPagination(paginationRes || PAGINATION_TOTAL_DEFAULT);
    } finally {
      setLoading(false);
    }
  };

  const deleteCRMCMember = async (member: ICRMCMember) => {
    const customHandlerErrorCodes = [IS_CEC_MEMBER_CODE];
    try {
      setShowWarning(false);
      setLoading(true);
      isCAR1514Enabled
        ? await cremaServices.deleteCRMCMember(
            {
              projectId,
              communityId,
              memberId: member.memberId,
            },
            customHandlerErrorCodes
          )
        : await cremaServices.upsertCRMCMembers(
            {
              projectId,
              communityId,
              members: [
                {
                  ...member,
                  isActive: false,
                },
              ],
            },
            customHandlerErrorCodes
          );

      notification.success({
        message: t('Success'),
        description: t('DeleteMemberSuccessfully'),
      });
      getCRMCMembers();
      getCRMCCandidates();
      getCommunityDetail();
    } catch (error) {
      setShowWarning(true);
    } finally {
      setLoading(false);
    }
  };

  const getCRMCCandidates = async () => {
    try {
      setGettingCRMCCandidates(true);
      const {
        crmcCandidates: crmcCandidatesRes,
      } = await cremaServices.getCremaCrmcCandidates({
        page: DEFAULT_PAGE,
        pageSize: PAGE_SIZE_ALL,
        communityIds: [communityId],
        projectId,
      });
      setCRMCCandidates(crmcCandidatesRes || []);
    } finally {
      setGettingCRMCCandidates(false);
    }
  };

  const addCRMCMember = async (values: IAddCRMCMemberForm) => {
    const newCRMCMember = {
      ...values,
      memberId: form.getFieldValue('memberId'),
      isFarmer: !!values?.isFarmer,
      memberRoleName:
        values.memberRoleName === 'Other'
          ? values.roleName
          : values.memberRoleName,
      isActive: true,
    };
    try {
      setLoading(true);
      await cremaServices.upsertCRMCMembers({
        projectId,
        communityId,
        members: [newCRMCMember],
      });
      notification.success({
        message: t('Success'),
        description: t('AddMemberSuccessfully'),
      });
      form.resetFields();
      getCRMCMembers();
      getCRMCCandidates();
      getCommunityDetail();
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = (newPagination: TablePaginationConfig) => {
    getCRMCMembers({
      page: newPagination.current || pagination.page,
      pageSize: newPagination.pageSize || pagination.pageSize,
    });
  };

  useEffect(() => {
    getCRMCMembers();
    getCRMCCandidates();
  }, [communityId]);

  return (
    <Drawer
      open={open}
      onClose={close}
      maskClosable={false}
      title={t('CRMCMembers')}
      styles={{ header: { textAlign: 'center', borderBottom: 'none' } }}
      width={500}
      getContainer={false}
      zIndex={1005}
    >
      {canCreateCRMCMember && (
        <Form
          form={form}
          layout="vertical"
          onFinish={addCRMCMember}
          className="CRMC-form"
        >
          <Form.Item
            name="memberName"
            label={t('FullName')}
            rules={[{ required: true }]}
          >
            <AutoComplete
              filterOption={filterSelect}
              placeholder={t('EnterFullName')}
              options={CRMCCandidates.map(CRMCCandidate => ({
                ...CRMCCandidate,
                key: CRMCCandidate.candidateId,
                label: `${CRMCCandidate.candidateName} - ${CRMCCandidate.candidatePhone}`,
                value: CRMCCandidate.candidateName,
              }))}
              onChange={() => {
                form.resetFields(['memberId', 'memberPhone']);
              }}
              onSelect={(value, option) => {
                form.setFieldsValue({
                  memberId: option.candidateId,
                  memberPhone: option.candidatePhone,
                });
              }}
              notFoundContent={
                <div className="d-flex justify-content-center p-base">
                  {gettingCRMCCandidates ? (
                    <Spin size="small" />
                  ) : (
                    <div>{t('NoData')}</div>
                  )}
                </div>
              }
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              return (
                <Form.Item
                  name="memberPhone"
                  label={t('PhoneNumber')}
                  rules={[
                    { required: true },
                    {
                      message: t('InvalidTelephone'),
                      validator: (_, value) => {
                        if (!value || checkTelephone(value)) {
                          return Promise.resolve();
                        } else return Promise.reject();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder={t('EnterPhoneNumber')}
                    readOnly={!!form.getFieldValue('memberId')}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            name="memberRoleName"
            label={t('Role')}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              filterOption={filterSelect}
              placeholder={t('SelectRole')}
              options={CRMC_ROLES}
            />
          </Form.Item>
          <Form.Item noStyle dependencies={['memberRoleName']}>
            {() =>
              form.getFieldValue('memberRoleName') === 'Other' ? (
                <Form.Item
                  name="roleName"
                  label={t('RoleName')}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('EnterRole')} />
                </Form.Item>
              ) : (
                <></>
              )
            }
          </Form.Item>
          {!hideFarmerCheckbox && (
            <Form.Item name="isFarmer" valuePropName="checked">
              <Checkbox>{t('IsFarmer')}</Checkbox>
            </Form.Item>
          )}
          <div className="d-flex justify-content-center">
            <Button
              htmlType="submit"
              loading={loading}
              type="primary"
              className="w-120px"
            >
              {t('Save')}
            </Button>
          </div>
        </Form>
      )}
      <div className={classNames({ 'mt-base': canCreateCRMCMember })}>
        {showWarning && (
          <Alert
            message={t('AlsoCECMember')}
            type="warning"
            showIcon
            closable
            className="mb-half"
          />
        )}
        <span>
          {t('TotalRecords')}: {pagination.total}
        </span>
        <Table
          loading={loading}
          bordered
          size="middle"
          rowKey="memberId"
          columns={columns}
          dataSource={CRMCMembers}
          pagination={{
            ...pagination,
            style: { gap: '10px' },
            showSizeChanger: true,
          }}
          className="mt-base"
          onChange={handlePagination}
        />
      </div>
    </Drawer>
  );
};
