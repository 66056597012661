export const FIXED_NUMBER = 2;

export const PROJECT_TYPE = [
  { value: 'PLAN_VIVO', label: 'Plan Vivo' },
  { value: 'VERRA', label: 'Verra' },
  { value: 'GOLD_STANDARD', label: 'Gold standard' },
  { value: 'JICA', label: 'Jica' },
  { value: 'OX_CARBON', label: 'OxCarbon' },
  { value: 'TREES', label: 'Trees' },
];

export const POLYGON_TYPES = {
  PROJECT_BOUNDARY: {
    id: window.config.polygonTypeIds?.ProjectBoundary || 1000,
  },
  INTERVENTION_AREA: {
    id: window.config.polygonTypeIds?.InterventionArea || 1001,
  },
  CREMA: {
    id: window.config.polygonTypeIds?.Crema || 1002,
  },
  COMMUNITY: {
    id: 1003,
  },
  FARMLAND: {
    id: 1004,
  },
  DEFORESTED: {
    id: 1005,
  },
  AGROFORESTRY: {
    id: 1006,
  },
  WITHIN_FOREST_RESERVE: {
    id: 1007,
  },
};

export default {
  PROJECT_TYPE,
  POLYGON_TYPES,
  FIXED_NUMBER,
};
