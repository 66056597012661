import {
  Filter,
  IPolygonFilter,
  Map,
} from 'components/ProjectList/ProjectDetailMapV2';
import AppContainer from 'containers/AppLayout/AppContainer';
import { polygonHooks } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { IStatusMapHandler, ProjectDetailV2Context } from './context';
import {
  IDrawPolygonRef,
  IGetPolygonTekboneParams,
  IProjectDetails,
} from 'interfaces';
import { DrawState } from 'constants/map';
import { LayerType } from 'components/shared/Map';
import { useForm } from 'antd/es/form/Form';
import { useLocation } from 'react-router-dom';
import { PROJECT_BOUNDARY_Z_INDEX } from 'constants/common';
import { PAGING_OFFSET_ALL } from 'constants/pagination';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { useQuery } from '@tanstack/react-query';
import { getData } from 'helpers/request';
import { projectServices } from 'services';
import { commonConstants } from 'constants/index';
import { ProjectMapDrawers } from './ProjectMapDrawers';
import ProjectDrawersProvider from './context/ProjectDrawersProvider';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { setGettingProject, setProject } from 'redux/features/project';

const { QUERY_KEYS } = commonConstants;

const ProjectDetailMapV2: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { projectId } = useProjectContext();
  const [maxZIndex, setMaxZIndex] = useState<number>(1);
  const [isAddProjectBoundary, setIsAddProjectBoundary] = useState(false);
  const [showAddPolygon, setShowAddPolygon] = useState(false);
  const [drawState, setDrawState] = useState<DrawState>(DrawState.NONE);
  const [currentPolygonId, setCurrentPolygonId] = useState(-1);
  const [statusAfterMapChanges, setStatusAfterMapChanges] = useState<
    IStatusMapHandler
  >({
    callAPI: false,
    isCenter: true,
  });
  const [showAddPolygonScreen, setShowAddPolygonScreen] = useState(false);
  const [triggered, setTriggered] = useState(false);
  const [form] = useForm<IPolygonFilter>();
  const [isPlotMode, setIsPlotMode] = useState(false); // plots from farmer uploaded
  const [params, setParams] = useState<IGetPolygonTekboneParams>({
    zIndex: PROJECT_BOUNDARY_Z_INDEX,
    projectId,
    ...PAGING_OFFSET_ALL,
  });

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [isAddBoundary, setIsAddBoundary] = useState(
    query.get('addBoundary') === 'true'
  );

  const isGenerateReport = query.get('generateReport') === 'true';
  const [selectedLayerTypes, setSelectedLayerTypes] = useState<LayerType[]>(
    isGenerateReport ? ['LandCover'] : ['Default']
  );
  const [viewARREligible, setViewARREligible] = useState(isGenerateReport);
  const { featureFlagsData } = useFlagsupContext();
  const isCAR613Enabled = featureFlagsData.CAR_613?.enabled;
  const isCARSA853Enabled = featureFlagsData.CARSA_853?.enabled;

  const {
    isLoading: loadingProjectDetails,
    refetch: refetchProjectDetails,
  } = useQuery({
    queryKey: [QUERY_KEYS.PROJECT_DETAIL, projectId],
    queryFn: async (): Promise<IProjectDetails | undefined> =>
      isCAR613Enabled
        ? projectServices
            .getProjectByIdTekbone(projectId)
            .then(getData)
            .then(project => {
              dispatch(setProject(project));
              return project;
            })
        : projectServices
            .getProjectById(projectId)
            .then(getData)
            .then(project => {
              dispatch(setProject(project));
              return project;
            }),
    retry: false,
    enabled: true,
  });

  const drawRef = useRef<IDrawPolygonRef>({
    getDrawPolygon: () => [],
    setInitialPolygon: () => {},
    stopDrawing: () => {},
    edit: () => {},
    setPolygonViewOnly: () => {},
    clear: () => {},
  });

  const {
    polygons,
    loading,
    cancelPreviousRequest,
    getPolygons,
    canLoadMore,
    loadMore,
    setPolygons,
  } = polygonHooks.usePolygons(params);

  useEffect(() => {
    dispatch(setGettingProject(loadingProjectDetails));
  }, [loadingProjectDetails]);

  // Logic chỉ hiển màn hình thêm polygon tại lần đầu vào map khi mảng polygon là rỗng
  // và không bị ảnh hưởng bởi tọa độ view port
  useEffect(() => {
    if (
      polygons.length === 0 &&
      params.zIndex === 1 &&
      !triggered &&
      !params?.hasOwnProperty('searchLatitudeMax') &&
      !isAddBoundary
    ) {
      setShowAddPolygonScreen(true);
      setIsAddProjectBoundary(true);
    }
    if (polygons.length > 0) {
      setShowAddPolygonScreen(false);
    }
  }, [polygons]);

  // Logic show add polygon drawer after click ok in upsertion drawer
  useEffect(() => {
    if (isAddBoundary) {
      setIsAddProjectBoundary(true);
      setShowAddPolygonScreen(false);
      setTriggered(true);
      setShowAddPolygon(true);
    }
  }, [isAddBoundary]);

  useEffect(() => {
    setIsAddBoundary(query.get('addBoundary') === 'true');
  }, [query]);

  return (
    <AppContainer>
      <ProjectDetailV2Context.Provider
        value={{
          showAddPolygon,
          setShowAddPolygon,
          drawRef,
          drawState,
          setDrawState,
          isAddProjectBoundary,
          setIsAddProjectBoundary,
          currentPolygonId,
          setCurrentPolygonId,
          selectedLayerTypes,
          setSelectedLayerTypes,
          statusAfterMapChanges,
          setStatusAfterMapChanges,
          canLoadMore,
          loadMore,
          gettingPolygons: loading,
          showAddPolygonScreen,
          setShowAddPolygonScreen,
          setTriggered,
          form,
          isPlotMode,
          setIsPlotMode,
          polygons,
          setPolygons,
          viewARREligible,
          setViewARREligible,
        }}
      >
        <ProjectDrawersProvider isGenerateReport={isGenerateReport}>
          <Filter
            setParams={setParams}
            setMaxZIndex={setMaxZIndex}
            gettingPolygons={loading}
            form={form}
          />
          <Map
            polygons={polygons}
            gettingPolygons={loading}
            maxZIndex={maxZIndex}
            params={params}
            setParams={setParams}
            getPolygons={getPolygons}
            cancelPreviousRequest={cancelPreviousRequest}
            showAddPolygonScreen={showAddPolygonScreen}
            showSearchButton={isCARSA853Enabled}
          >
            <ProjectMapDrawers refetchProject={refetchProjectDetails} />
          </Map>
        </ProjectDrawersProvider>
      </ProjectDetailV2Context.Provider>
    </AppContainer>
  );
};

export default ProjectDetailMapV2;
