import React, { useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Form, Card, Row, Col } from 'antd';
import { t } from 'helpers/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
  getMembers,
  onSearch,
  selectFilter,
} from 'redux/features/memberManagement';
import { useProjectContext } from 'contexts';

interface IMemberFilterForm {
  keyword?: string;
}

const MemberFilter: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const filter = useSelector(selectFilter);
  const { projectId } = useProjectContext();
  const [form] = Form.useForm<IMemberFilterForm>();

  useEffect(() => {
    dispatch(getMembers({ projectId, ...filter }));
  }, [filter, projectId]);

  const onFinish = (formValues: IMemberFilterForm) => {
    dispatch(onSearch({ keyword: formValues.keyword }));
  };

  return (
    <Card classNames={{ body: 'p-half' }}>
      <Form form={form} onFinish={onFinish}>
        <Row>
          <Col xxl={10} xs={14}>
            <Form.Item name="keyword" className="mb-0">
              <Input
                placeholder={t('SearchByMemberNamePhoneNumber')}
                suffix={<SearchOutlined onClick={form.submit} />}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default MemberFilter;
