import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import TagStatus from 'components/shared/Tag/TagStatus';
import { NOT_AVAILABLE } from 'constants/common';
import { DATE_TIME_SLASH_FORMAT } from 'constants/dateTime';
import { COLOR } from 'constants/styles/color';
import { metricConstants } from 'constants/index';
import { TreeSpeciesContext } from 'containers/Submission/context/useTreeSpecies';
import { useFlagsupContext, useProjectContext } from 'contexts';
import dayjs from 'dayjs';
import { commonHelpers } from 'helpers';
import { convertNumberFormat, filterSelect } from 'helpers/common';
import { t } from 'helpers/i18n';
import { translateTreeSpecies } from 'helpers/localization';
import { findSpeciesId, TREE_SPECIES_OPTIONS } from 'helpers/species';
import trackCarbonContent from 'helpers/tracker/trackCarbonContent';
import trackSubmissionInfoCard from 'helpers/tracker/trackSubmissionInfoCard';
import { userHooks } from 'hooks';
import {
  ISubmission,
  ISubmissionAfterCAR2355,
  ISubmissionDetail,
  IUpdateSubmission,
} from 'interfaces';
import debounce from 'lodash/debounce';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { setSubmissionId } from 'redux/features/submission';
import { AppDispatch } from 'redux/store';
import { submissionServices } from 'services';
import styled from 'styled-components';
import {
  validateCircumferenceUpdate,
  validateTreeAgeUpdate,
} from 'utils/formValidator';

export type TypeSave = 'circumference' | 'species' | 'treeAge';

interface ISubmissionInfoCard {
  currentSubmission?: ISubmission;
  setSubmissions?: Dispatch<SetStateAction<ISubmission[]>>;
  setSubmissionsAfterCAR2355?: Dispatch<
    SetStateAction<ISubmissionAfterCAR2355[]>
  >;
  currentSubmissionAfterCAR2355?: ISubmissionAfterCAR2355;
  afterUpdateSuccessfully?: (typeSave?: TypeSave) => void;
}

const { convertDateFormat } = commonHelpers;
const {
  trackEditingField,
  EDIT_BUTTON_INDEX,
  INPUT_INDEX,
  CANCEL_BUTTON_INDEX,
  SAVE_BUTTON_INDEX,
  SAVE_BUTTON_VALUE,
  CANCEL_BUTTON_VALUE,
  NAME_TO_EVENT,
} = trackSubmissionInfoCard;
const { trackCarbonExplaination } = trackCarbonContent;
const { KG_C, KG_CO2 } = metricConstants;

const HORIZONTAL_GUTTER = 16;
const VERTICAL_GUTTER = 8;

const EditableTextContainer = styled(Typography.Text)`
  display: flex;
  align-items: start;
  gap: 20px;
  .ant-btn {
    height: auto;
    padding: 0;
    width: 60px;
    line-height: 1.4;
  }
  .ant-btn span {
    font-size: 0.8em;
  }
  .form-wrapper {
    width: 100%;
    margin-top: -2px;
  }
  .ant-form-item-explain-error {
    font-size: 0.8em;
    margin: 2px -16px;
    margin-bottom: -2px;
  }
  .anticon {
    vertical-align: -0.2em;
  }
`;

interface IEditableField {
  onSave: () => any;
  onEvent: {
    onCancel: () => any;
    onEdit: () => any;
    onChange: (event: any) => any;
    handleBlur: () => any;
    isEditing: boolean;
  };
  canEdit: boolean;
  suffix?: string | React.ReactNode;
  label?: string;
  formWidth?: string | number;
  types?: 'select' | 'input';
  options?: any[];
  initalValue?: any;
  inputType?: 'text' | 'number';
  name: string;
  validate?: {
    errorMessage: string;
    validator: any;
  };
  displayValue?: string | number;
  maxLength?: number;
}

const { useUserPermissions } = userHooks;

const useEditable = (name: string) => {
  const [editable, setEditable] = useState(false);
  const onEdit = useCallback(() => {
    trackEditingField(`edit${name}`, `edit${name}`, EDIT_BUTTON_INDEX);
    setEditable(true);
  }, []);
  const onCancel = useCallback(() => {
    trackEditingField(
      CANCEL_BUTTON_VALUE,
      `saveNew${name}`,
      CANCEL_BUTTON_INDEX
    );
    setEditable(false);
  }, []);
  const onChange = debounce((event: any) => {
    trackEditingField(event.target.value, `inputNew${name}`, INPUT_INDEX);
  }, 1000);
  const handleBlur = () => {
    onChange.cancel();
  };
  return { isEditing: editable, onEdit, onCancel, onChange, handleBlur };
};

const EditableField = (props: IEditableField) => {
  const [value] = useState(props.initalValue[props.name]);
  const [form] = Form.useForm();
  const [isDisable, setIsDisable] = useState(false);
  const watchingValue = Form.useWatch(props.name, form);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    form.setFieldValue(props.name, props.initalValue[props.name]);
  }, [props.initalValue[props.name]]);

  useEffect(() => {
    const validate = async () => {
      try {
        setIsDisable(false);
        await form.validateFields();
      } catch (_e) {
        setIsDisable(true);
      }
    };

    validate();
  }, [watchingValue]);

  const onSubmit = async (name: string) => {
    trackEditingField(SAVE_BUTTON_VALUE, `saveNew${name}`, SAVE_BUTTON_INDEX);
    try {
      setIsSubmitting(true);
      const onSave = props.onSave();
      const res = await onSave(form.getFieldsValue());
      if (res) props.onEvent.onCancel();
    } catch (_error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <EditableTextContainer>
      <Typography.Text style={{ whiteSpace: 'nowrap' }}>
        {props.label}:{' '}
      </Typography.Text>
      {props.canEdit ? (
        <>
          {props.onEvent.isEditing ? (
            <Form
              form={form}
              style={{ width: props.formWidth }}
              initialValues={props.initalValue}
              preserve={false}
            >
              <Space direction="vertical" className="form-wrapper">
                {props.types && props.types === 'select' ? (
                  <Form.Item name={props.name} style={{ margin: 0 }}>
                    <Select
                      options={props.options}
                      showSearch
                      labelInValue
                      filterOption={(input, option) =>
                        filterSelect(input, option)
                      }
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name={props.name}
                    style={{ margin: 0 }}
                    rules={[
                      {
                        message: props.validate?.errorMessage,
                        validator: props.validate?.validator,
                      },
                    ]}
                  >
                    <Input
                      maxLength={props.maxLength}
                      suffix={props.suffix}
                      type={props.inputType || 'text'}
                      onChange={props.onEvent.onChange}
                      onBlur={props.onEvent.handleBlur}
                    />
                  </Form.Item>
                )}
                <Space
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button size="small" onClick={props.onEvent.onCancel}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    size="small"
                    color={COLOR.PRIMARY}
                    type="primary"
                    onClick={() =>
                      onSubmit(
                        NAME_TO_EVENT[props.name as keyof Object].toString()
                      )
                    }
                    disabled={isDisable}
                    loading={isSubmitting}
                  >
                    {t('Save')}
                  </Button>
                </Space>
              </Space>
            </Form>
          ) : (
            <Space
              style={{
                fontWeight: 'bold',
                marginLeft: '-10px',
              }}
              align="start"
              size={'small'}
            >
              <span>
                {props.displayValue ?? props.initalValue[props.name]}{' '}
                {props.suffix}
              </span>
              <span
                onClick={props.onEvent.onEdit}
                style={{
                  cursor: 'pointer',
                  color: COLOR.SUPPORT,
                }}
              >
                <EditOutlined color={COLOR.SUPPORT} />
              </span>
            </Space>
          )}
        </>
      ) : (
        <Space
          style={{
            alignItems: 'start',
            fontWeight: 'bold',
            marginLeft: '-10px',
          }}
        >
          <span data-testid="span-value">
            {props.displayValue ?? value} {props.suffix}
          </span>
        </Space>
      )}
    </EditableTextContainer>
  );
};

const SubmissionInfoCard = ({
  currentSubmission: initialSubmission,
  setSubmissions,
  currentSubmissionAfterCAR2355,
  setSubmissionsAfterCAR2355,
  afterUpdateSuccessfully,
}: ISubmissionInfoCard) => {
  const { featureFlagsData } = useFlagsupContext();
  const dispatch: AppDispatch = useDispatch();

  const isCAR2355Enabled = featureFlagsData.CAR_2355?.enabled;
  const isCAR2356Enabled = featureFlagsData.CAR_2356?.enabled;
  const circumEdit = useEditable('TrunkCirumfere');
  const treeAgeEdit = useEditable('TreeAge');
  const specieEdit = useEditable('TreeSpecies');
  const [api, contextHolder] = notification.useNotification();
  const [currentSubmission, setCurrentSubmission] = useState<
    ISubmissionDetail & { submissionTime: any }
  >(
    isCAR2355Enabled
      ? currentSubmissionAfterCAR2355!
      : initialSubmission!.submission
  );
  const { canUpdateSubmission } = useUserPermissions();
  const canEdit = currentSubmission.isEditable && canUpdateSubmission;
  const { treeSpecies } = useContext(TreeSpeciesContext);
  const { currentProject, projectId } = useProjectContext();
  const imgStatusCnt = useMemo(() => {
    return {
      fail:
        (currentSubmission.checksumTreeImages?.length || 0) -
        currentSubmission.treeImages.length,
      success: currentSubmission.treeImages.length,
    };
  }, [currentSubmission]);
  const totalImages =
    currentSubmission.checksumTreeImages?.length ||
    currentSubmission.treeImages.length;
  const isSaplingOrSeedling =
    currentSubmission.treeType === 'SAPLING' ||
    currentSubmission.treeType === 'SEEDLING';

  useEffect(() => {
    setCurrentSubmission(
      isCAR2355Enabled
        ? currentSubmissionAfterCAR2355!
        : initialSubmission!.submission
    );
  }, [initialSubmission, currentSubmissionAfterCAR2355]);

  useEffect(() => {
    circumEdit.onCancel();
    treeAgeEdit.onCancel();
    specieEdit.onCancel();
  }, [currentSubmission.submissionId]);

  const onSubmissionSave = (typeSave: TypeSave) => {
    return async function(value: any) {
      try {
        if (treeSpecies) {
          const bodyUpdate: IUpdateSubmission = {
            treeTrunkDiameter:
              value?.circum && typeSave !== 'species'
                ? value.circum / Math.PI
                : currentSubmission.treeTrunkDiameter,
            treeSpeciesId: value?.species?.value
              ? findSpeciesId(treeSpecies, value?.species?.value)!
              : findSpeciesId(
                  treeSpecies,
                  currentSubmission.treeSpeciesCodeName
                )!,
            submissionId: currentSubmission.submissionId,
            treeAge: treeAgeEdit.isEditing
              ? typeSave !== 'treeAge'
                ? currentSubmission.treeAge
                : value.treeAge || null
              : currentSubmission.treeAge,
            treeType: currentSubmission.treeType,
            projectId,
          };
          if (bodyUpdate.treeAge) bodyUpdate.treeAge = +bodyUpdate.treeAge;
          const apiUpdateSubmission = isCAR2355Enabled
            ? submissionServices.updateSubmissionTekbone
            : submissionServices.updateSubmission;
          const res = await apiUpdateSubmission(bodyUpdate);
          if (res?.code === 0 || res?.code === 200) {
            api.success({
              duration: 2,
              message: `${t('Update')} ${t('Submissions').toLowerCase()} ${t(
                'Success'
              ).toLowerCase()}`,
            });
            circumEdit.onCancel();
            treeAgeEdit.onCancel();
            specieEdit.onCancel();
            setCurrentSubmission(prevSub => ({
              ...prevSub,
              ...bodyUpdate,
              treeSpeciesCodeName:
                value?.species?.value || currentSubmission.treeSpeciesCodeName,
            }));
            if (isCAR2355Enabled) {
              setSubmissionsAfterCAR2355?.(prevSubmissions => {
                return [...prevSubmissions].map(submission => {
                  if (
                    submission.submissionId === currentSubmission.submissionId
                  ) {
                    submission = {
                      ...submission,
                      ...bodyUpdate,
                      treeSpeciesCodeName:
                        value?.species?.value ||
                        currentSubmission.treeSpeciesCodeName,
                    };
                  }
                  return submission;
                });
              });
            } else {
              setSubmissions?.(prevSubmissions => {
                return [...prevSubmissions].map(submission => {
                  if (
                    submission.submission.submissionId ===
                    currentSubmission.submissionId
                  ) {
                    submission.submission = {
                      ...submission.submission,
                      ...res?.data,
                    };
                  }
                  return submission;
                });
              });
            }
            afterUpdateSuccessfully?.(typeSave);
          }
          return res;
        }
      } catch (_error) {}
    };
  };

  return (
    <Form
      style={{
        backgroundColor: COLOR.BG_NEUTRAL,
        borderRadius: 10,
      }}
      className="mt-base p-base"
    >
      {contextHolder}
      <Space direction="vertical">
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('Project')}:{' '}
              <Typography.Text strong>
                {currentProject?.projectTitle}
              </Typography.Text>
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={[HORIZONTAL_GUTTER, VERTICAL_GUTTER]}>
          <Col span={24}>
            <EditableField
              canEdit={canEdit}
              label={t('Species')}
              name={'species'}
              onEvent={specieEdit}
              formWidth={'35%'}
              types="select"
              initalValue={{
                species: translateTreeSpecies(
                  currentSubmission.treeSpeciesCodeName
                ),
              }}
              displayValue={
                translateTreeSpecies(
                  currentSubmission.treeSpeciesCodeName
                ) as any
              }
              onSave={() => onSubmissionSave('species')}
              options={TREE_SPECIES_OPTIONS}
            />
          </Col>
        </Row>
        <Row gutter={[HORIZONTAL_GUTTER, VERTICAL_GUTTER]}>
          {isCAR2356Enabled && (
            <Col span={8}>
              <Typography.Text data-testid="tree-label-val">
                {t('TreeLabel')}:{' '}
                <Typography.Text strong>
                  {currentSubmission.treeLabel
                    ? t(currentSubmission.treeLabel)
                    : NOT_AVAILABLE}
                </Typography.Text>
              </Typography.Text>
            </Col>
          )}
          <Col span={8}>
            <Typography.Text>
              {t('TreeType')}:{' '}
              <Typography.Text strong data-testid="tree-type-val">
                {currentSubmission.treeType
                  ? t(currentSubmission.treeType)
                  : NOT_AVAILABLE}
              </Typography.Text>
            </Typography.Text>
          </Col>
          {!isSaplingOrSeedling && (
            <>
              <Col span={8}>
                <EditableField
                  canEdit={canEdit}
                  label={t('TreeAge')}
                  name={'treeAge'}
                  onEvent={treeAgeEdit}
                  formWidth={'35%'}
                  inputType={'number'}
                  initalValue={{
                    treeAge: currentSubmission.treeAge || '',
                  }}
                  displayValue={
                    currentSubmission.treeAge
                      ? convertNumberFormat(currentSubmission.treeAge)
                      : t('Unspecified')
                  }
                  validate={{
                    errorMessage: `${t('TreeAge')} ${t(
                      'TreeAgeUpdateErr'
                    ).toLowerCase()}`,
                    validator: validateTreeAgeUpdate,
                  }}
                  onSave={() => onSubmissionSave('treeAge')}
                />
              </Col>
              <Col span={8}>
                <Typography.Text>
                  {t('Diameter')}:{' '}
                  <Typography.Text strong>
                    {currentSubmission.treeTrunkDiameter
                      ? `${convertNumberFormat(
                          currentSubmission.treeTrunkDiameter
                        )} cm`
                      : NOT_AVAILABLE}
                  </Typography.Text>
                </Typography.Text>
              </Col>
            </>
          )}
          <Col span={8}>
            <Typography.Text>
              {t('TreeHeight')}:{' '}
              <Typography.Text strong data-testid="tree-height-val">
                {currentSubmission.treeHeight
                  ? `${convertNumberFormat(currentSubmission.treeHeight)} cm`
                  : NOT_AVAILABLE}
              </Typography.Text>
            </Typography.Text>
          </Col>
          {!isSaplingOrSeedling && (
            <>
              <Col span={8}>
                <EditableField
                  canEdit={canEdit}
                  onEvent={circumEdit}
                  onSave={() => onSubmissionSave('circumference')}
                  suffix={currentSubmission.treeTrunkDiameter ? 'cm' : ''}
                  label={t('Circumference')}
                  formWidth={'35%'}
                  name={'circum'}
                  initalValue={{
                    circum: currentSubmission.treeTrunkDiameter
                      ? convertNumberFormat(
                          currentSubmission.treeTrunkDiameter * Math.PI
                        )
                      : undefined,
                  }}
                  validate={{
                    errorMessage: `${t('Circumference')} ${t(
                      'CircumferenceUpdateErr'
                    ).toLowerCase()}`,
                    validator: validateCircumferenceUpdate,
                  }}
                  displayValue={
                    currentSubmission.treeTrunkDiameter
                      ? convertNumberFormat(
                          currentSubmission.treeTrunkDiameter * Math.PI
                        )
                      : NOT_AVAILABLE
                  }
                  inputType={'number'}
                />
              </Col>
              {isCAR2356Enabled && (
                <Col span={8}>
                  <Typography.Text>
                    {t('AIDiameterDetection')}:{' '}
                    <Typography.Text strong>
                      {currentSubmission.systemDetectedDiameter
                        ? `${convertNumberFormat(
                            currentSubmission.systemDetectedDiameter
                          )} cm`
                        : NOT_AVAILABLE}
                    </Typography.Text>
                  </Typography.Text>
                </Col>
              )}
            </>
          )}
          {isCAR2356Enabled && (
            <Col span={8}>
              <Typography.Text>
                {t('AITreeHeightDetection')}:{' '}
                <Typography.Text strong>
                  {currentSubmission.arTreeHeight
                    ? `${convertNumberFormat(
                        currentSubmission.arTreeHeight
                      )} cm`
                    : NOT_AVAILABLE}
                </Typography.Text>
              </Typography.Text>
            </Col>
          )}
          {!isSaplingOrSeedling && (
            <>
              <Col span={8}>
                <Typography.Text>
                  {t('Degrees')}:{' '}
                  <Typography.Text strong data-testid="degrees-val">
                    {currentSubmission.treeDegrees
                      ? convertNumberFormat(currentSubmission.treeDegrees)
                      : t('Unspecified')}
                  </Typography.Text>
                </Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text>
                  {t('Distance')}:{' '}
                  <Typography.Text strong data-testid="distance-val">
                    {currentSubmission.distance
                      ? `${convertNumberFormat(currentSubmission.distance)} m`
                      : t('Unspecified')}
                  </Typography.Text>
                </Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text>
                  {t('CarbonContent')}:{' '}
                  <Typography.Text strong>
                    {currentSubmission?.carbonContent ? (
                      <span
                        onClick={() => {
                          trackCarbonExplaination('submissionDetail');
                          dispatch(
                            setSubmissionId(currentSubmission.submissionId)
                          );
                        }}
                        style={{ color: COLOR.SUPPORT, cursor: 'pointer' }}
                      >
                        {convertNumberFormat(
                          currentSubmission.carbonContent,
                          3
                        )}{' '}
                        {KG_C}
                      </span>
                    ) : (
                      NOT_AVAILABLE
                    )}
                  </Typography.Text>
                </Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text>
                  {t('CO₂OffsetkgCO₂')}:{' '}
                  <Typography.Text strong>
                    {currentSubmission.co2Offset ? (
                      <>
                        {convertNumberFormat(currentSubmission.co2Offset, 3)}{' '}
                        {KG_CO2}
                      </>
                    ) : (
                      NOT_AVAILABLE
                    )}
                  </Typography.Text>
                </Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text>
                  {t('SubmissionTime')}:{' '}
                  <Typography.Text strong>
                    {isCAR2355Enabled
                      ? dayjs
                          .unix(currentSubmission.submissionTime)
                          .format(DATE_TIME_SLASH_FORMAT)
                      : convertDateFormat(currentSubmission.submissionTime)}
                  </Typography.Text>
                </Typography.Text>
              </Col>
            </>
          )}
          <Col span={8}>
            <Space align="baseline">
              <Typography.Text>{t('Status')}:</Typography.Text>
              <TagStatus
                type="solid"
                size="small"
                style={{ fontWeight: 'bold' }}
                isSelected={
                  isCAR2355Enabled
                    ? currentSubmissionAfterCAR2355?.isSelected
                    : initialSubmission?.submissionStatus?.isSelected
                }
              />
            </Space>
          </Col>
          {!totalImages || (
            <Col span={8}>
              <Space align="baseline">
                <Typography.Text>{t('ImageStatus')}:</Typography.Text>
                {imgStatusCnt.success <= 0 || (
                  <div>
                    <TagStatus
                      size="small"
                      type="solid"
                      style={{ fontWeight: 'bold' }}
                      data-testid="img-status-success"
                    >
                      {t('Successful')} {`(${imgStatusCnt.success})`}
                    </TagStatus>
                  </div>
                )}
                {imgStatusCnt.fail <= 0 || (
                  <div>
                    <TagStatus
                      size="small"
                      type="solid"
                      color={COLOR.ERROR}
                      style={{ fontWeight: 'bold' }}
                      data-testid="img-status-fail"
                    >
                      {t('Failed')} {`(${imgStatusCnt.fail})`}
                    </TagStatus>
                  </div>
                )}
              </Space>
            </Col>
          )}
        </Row>
      </Space>
    </Form>
  );
};

export default SubmissionInfoCard;
