import { orgChartRoutes } from './common';

const ticketRoutes = [
  {
    path: '/tickets',
    children: ['/tickets/dashboard', '/tickets/list'],
  },
  { path: '/tickets/dashboard' },
  { path: '/tickets/list' },
];

export const waRoutes = [
  { path: '/' },
  { path: '/project-boundaries' },
  { path: '/management-group' },
  { path: '/group-scheme', children: ['/group-scheme/member-support-unit'] },
  { path: '/group-scheme/member-support-unit' },
  { path: '/members-management' },
  { path: '/submissions' },
  { path: '/carbon-monitoring' },
  { path: '/meeting-notes' },
  { path: '/meeting-notes/create' },
  { path: '/meeting-notes/:id' },
  {
    path: '/dashboards',
    children: [
      '/kpi-progress-tracking',
      '/me-indices-tracking',
      '/operational-metrics',
    ],
  },
  { path: '/kpi-progress-tracking' },
  { path: '/me-indices-tracking' },
  { path: '/operational-metrics' },
  {
    path: '/reports',
    children: ['/reports/mills-reports', '/reports/quarterly-reports'],
  },
  { path: '/reports/mills-reports' },
  { path: '/reports/quarterly-reports' },
  { path: '/payment-services' },
  ...ticketRoutes,
  ...orgChartRoutes,
];
