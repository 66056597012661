import React, { useMemo, useState } from 'react';
import { Checkbox, Flex, Input } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import { OTHER_VALUE } from 'constants/common';
import { t } from 'helpers/i18n';

const CustomizableCheckbox: React.FC<CheckboxGroupProps> = ({
  options,
  onChange,
  value,
  ...props
}) => {
  const [otherValue, setOtherValue] = useState('');
  const optionsWithLabel = useMemo(
    () => [
      ...(options?.map(option =>
        typeof option === 'string' || typeof option === 'number'
          ? { label: option, value: option }
          : option
      ) || []),
    ],
    [options]
  );
  const hasOtherValue = useMemo(
    () => value?.find(value => value.includes(OTHER_VALUE)),
    [value]
  );

  const handleCheckboxChange = (checkedValues: string[]) => {
    const normalValues = checkedValues.filter(
      value => !value.includes(OTHER_VALUE)
    );

    if (checkedValues.includes(OTHER_VALUE) && otherValue) {
      onChange?.(normalValues.concat(`${OTHER_VALUE}${otherValue}`));
    } else {
      onChange?.(normalValues);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const normalValues =
      value?.filter(value => !value.includes(OTHER_VALUE)) || [];

    setOtherValue(newValue);
    if (hasOtherValue) {
      onChange?.(
        newValue
          ? normalValues?.concat(`${OTHER_VALUE}${newValue}`)
          : normalValues
      );
    }
  };

  return (
    <Checkbox.Group
      options={optionsWithLabel.concat({
        label: (
          <Flex gap={8} align="center">
            <span>{t('Other')}:</span>
            <Input
              placeholder={t('InputText')}
              className="custom-input p-0"
              value={otherValue}
              onChange={handleInputChange}
            />
          </Flex>
        ),
        value: OTHER_VALUE,
      })}
      onChange={handleCheckboxChange}
      value={hasOtherValue ? value?.concat(OTHER_VALUE) : value}
      {...props}
    />
  );
};

export default CustomizableCheckbox;
