import { Button } from 'antd';
import ContentBlock from 'components/shared/ContentBlock';
import { commonConstants } from 'constants/index';
import IFrameContainer from 'containers/shared/IFrameContainer';
import { reportHooks } from 'hooks';
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

export interface IQuarterlyReportContainerProps extends RouteComponentProps {
  iFrameSrc: string;
}

const { IS_WA } = commonConstants;

const QuarterlyReportContainer: React.FC<IQuarterlyReportContainerProps> = props => {
  const QuarterlyReports = ['Q4-2023', 'Q1-2024', 'Q2-2024'];
  const history = useHistory();
  const { generateQuarterlyReport } = reportHooks.useWaReport();

  return (
    <>
      {IS_WA ? (
        <IFrameContainer {...props} iFrameSrc={generateQuarterlyReport()} />
      ) : (
        <ContentBlock className="h-100">
          {QuarterlyReports.map(quarter => (
            <Button
              type="link"
              key={quarter}
              onClick={() => history.push(`/quarterly-reports/${quarter}`)}
            >
              {quarter}
            </Button>
          ))}
        </ContentBlock>
      )}
    </>
  );
};

export { QuarterlyReportContainer };
export default QuarterlyReportContainer;
