import React from 'react';
import { Col, Row } from 'antd';
import {
  ListMember,
  MemberGroupApproval,
  MemberFilter,
} from 'components/MemberManagement';
import AppContainer from 'containers/AppLayout/AppContainer';
import { useSelector } from 'react-redux';
import { selectCurrentMemberId } from 'redux/features/memberManagement';

const MembersManagement: React.FC = () => {
  const currentMemberId = useSelector(selectCurrentMemberId);

  return (
    <AppContainer>
      <MemberFilter />
      <Row gutter={16} className="mt-half">
        <Col xxl={8} xl={10} xs={12}>
          <ListMember />
        </Col>
        <Col xxl={8} xl={10} xs={12}>
          <MemberGroupApproval key={currentMemberId} />
        </Col>
      </Row>
    </AppContainer>
  );
};

export default MembersManagement;
