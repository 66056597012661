import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ID_DEFAULT } from 'constants/common';
import {
  DEFAULT_NEXT_OFFSET,
  DEFAULT_PAGINATION_OFFSET,
} from 'constants/pagination';
import { IPaginationOffsetResponse } from 'interfaces';
import { IGetListMemberParams, IMember } from 'interfaces/memberManagement';
import { RootState } from 'redux/store';
import { memberManagementServices } from 'services';

// Define a type for the slice state
export interface IMemberManagementState {
  gettingMembers: boolean;
  members: IMember[];
  pagination: IPaginationOffsetResponse;
  currentMemberId: number;
  filter: Pick<IGetListMemberParams, 'keyword' | 'limit' | 'offset'>;
}

// Define the initial state using that type
const initialState: IMemberManagementState = {
  gettingMembers: false,
  members: [],
  pagination: { offset: DEFAULT_NEXT_OFFSET },
  currentMemberId: ID_DEFAULT,
  filter: DEFAULT_PAGINATION_OFFSET,
};

export const selectGettingMembers = (state: RootState) =>
  state.memberManagement.gettingMembers;

export const selectMembers = (state: RootState) =>
  state.memberManagement.members;

export const selectPagination = (state: RootState) =>
  state.memberManagement.pagination;

export const selectCurrentMemberId = (state: RootState) =>
  state.memberManagement.currentMemberId;

export const selectFilter = (state: RootState) => state.memberManagement.filter;

export const selectShowLoadMore = (state: RootState) => {
  const { offset, limit } = state.memberManagement.filter;
  const { offset: nextOffset } = state.memberManagement.pagination;
  return offset + limit === nextOffset;
};

export const getMembers = createAsyncThunk(
  'memberManagement/getMembers',
  memberManagementServices.getListMember
);

export const memberManagementSlice = createSlice({
  name: 'memberManagement',
  initialState,
  reducers: {
    setCurrentMemberId: (state, action: PayloadAction<number>) => {
      state.currentMemberId = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        Pick<IGetListMemberParams, 'keyword' | 'limit' | 'offset'>
      >
    ) => {
      state.filter = action.payload;
    },
    loadMore: state => {
      state.filter.offset = state.pagination.offset;
    },
    onSearch: (
      state,
      action: PayloadAction<Pick<IGetListMemberParams, 'keyword'>>
    ) => {
      state.filter = { ...action.payload, ...DEFAULT_PAGINATION_OFFSET };
      state.currentMemberId = ID_DEFAULT;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMembers.pending, state => {
        state.gettingMembers = true;
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.gettingMembers = false;
        if (state.filter.offset === 0) {
          state.members = action.payload.farmers;
        } else {
          state.members = [...state.members, ...action.payload.farmers];
        }
        state.pagination = action.payload.pagination;
      })
      .addCase(getMembers.rejected, state => {
        state.gettingMembers = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentMemberId,
  setFilter,
  loadMore,
  onSearch,
} = memberManagementSlice.actions;

export default memberManagementSlice.reducer;
