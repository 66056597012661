import { useProjectContext } from 'contexts';
import { encodeQueryData } from 'helpers/request';
import { commonHooks } from 'hooks';

const { useIam } = commonHooks;

const TEKODATA_SUPERSET_DASHBOARD_URL =
  'https://superset-public.tekodata.com/superset/dashboard';

const KARBONMAP_SUPERSET_DASHBOARD_URL =
  'https://report.karbonmap.com/superset/dashboard';

const useSupersetReport = (
  baseUrl: string = TEKODATA_SUPERSET_DASHBOARD_URL
) => {
  const { getProjectInfo } = useIam();
  const { clientId, oauthDomain } = getProjectInfo();

  const generateReportUrl = (reportId: number, params?: object) => {
    const reportUrl = `${baseUrl}/${reportId}`;
    const suffixUrl = encodeQueryData({
      standalone: 2,
      clientId,
      oauthDomain,
      ...params,
    });
    return `${reportUrl}?${suffixUrl}`;
  };

  return {
    generateReportUrl,
  };
};

export const useReport = () => {
  const { getProjectInfo } = useIam();

  const generateInvestorReportUrl = () => {
    const { clientId, oauthDomain } = getProjectInfo();

    const baseUrl =
      'https://superset-public.tekodata.com/superset/dashboard/144/?edit=true&standalone=2';
    const suffixUrl = `&clientId=${clientId}&oauthDomain=${encodeURIComponent(
      oauthDomain
    )}`;

    return `${baseUrl}${suffixUrl}`;
  };

  const generateFpicInternalReportUrl = () => {
    const { clientId, oauthDomain } = getProjectInfo();

    const baseUrl =
      'https://superset-public.tekodata.com/superset/dashboard/153/?native_filters_key=x9P1sdYEk36kzGaF0iyU_nQmhFKAVX_N_3PE3bw59b2tzdNwnnSIAJP9VG3K6zaR&standalone=2';
    const suffixUrl = `&clientId=${clientId}&oauthDomain=${encodeURIComponent(
      oauthDomain
    )}`;

    return `${baseUrl}${suffixUrl}`;
  };

  const generateFarmerEnrollmentReportUrl = () => {
    const { clientId, oauthDomain } = getProjectInfo();

    const baseUrl =
      'https://superset-public.tekodata.com/superset/dashboard/179?standalone=2';
    const suffixUrl = `&clientId=${clientId}&oauthDomain=${encodeURIComponent(
      oauthDomain
    )}`;

    return `${baseUrl}${suffixUrl}`;
  };
  return {
    generateInvestorReportUrl,
    generateFpicInternalReportUrl,
    generateFarmerEnrollmentReportUrl,
  };
};

export const useAjaReport = () => {
  const { projectId } = useProjectContext();
  const { generateReportUrl } = useSupersetReport(
    KARBONMAP_SUPERSET_DASHBOARD_URL
  );

  const generateFpicManagementReport = () => {
    return generateReportUrl(3);
  };

  const generateFpicDashboardReport = () => {
    return generateReportUrl(2);
  };

  const generateFarmerManagementReport = () => {
    return generateReportUrl(1);
  };

  const generateBiodiversityReport = () => {
    return generateReportUrl(6, { projectId });
  };

  const generateCremaKpiReport = () => {
    return generateReportUrl(9);
  };

  const generatePlantingKpiReport = () => {
    return generateReportUrl(10);
  };

  const generateDashboards = () => {
    return generateReportUrl(11);
  };

  const generateMillsReports = () => {
    return generateReportUrl(7);
  };

  return {
    generateFpicManagementReport,
    generateFpicDashboardReport,
    generateFarmerManagementReport,
    generateBiodiversityReport,
    generateCremaKpiReport,
    generatePlantingKpiReport,
    generateDashboards,
    generateMillsReports,
  };
};

export const useWbReport = () => {
  const { generateReportUrl } = useSupersetReport();

  const generateInternalReport = () => {
    const internalReportId = window?.appConfig?.internalReportId;
    if (internalReportId) return generateReportUrl(internalReportId);
  };

  return {
    generateInternalReport,
  };
};

export const useWaReport = () => {
  const { generateReportUrl } = useSupersetReport();

  const generateQuarterlyReport = () => {
    return generateReportUrl(13);
  };

  return {
    generateQuarterlyReport,
  };
};

export default {
  useReport,
  useAjaReport,
  useWbReport,
  useWaReport,
};
