import { FARM_STATUS } from 'constants/farm';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGING_OFFSET_ALL,
} from 'constants/pagination';
import { useProjectContext } from 'contexts';
import {
  IFarmLand,
  IFarmSimple,
  IGetFarmParams,
  INearbyPolygon,
  IPaginationResponse,
  ISurveyForm,
  IUpsertFarmMappingFormParams,
} from 'interfaces';
import { useEffect, useMemo, useState } from 'react';
import { discussionHistoryServices, farmServices } from 'services';
import { useAbortController } from './axios';
import { useFlagsupContext } from '../contexts/ConfigFlagsupProvider';

const useFarms = () => {
  const { projectId } = useProjectContext();
  const DEFAULT_PARAMS: IGetFarmParams = {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    farmStatus: FARM_STATUS.IN_REVIEW,
    projectId,
  };
  const DEFAULT_PAGINATION = {
    pageSize: DEFAULT_PARAMS.pageSize,
    total: 0,
    page: DEFAULT_PARAMS.page,
  };

  const [params, setParams] = useState<IGetFarmParams>(DEFAULT_PARAMS);
  const [farms, setFarms] = useState<IFarmSimple[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<
    Omit<IPaginationResponse, 'totalPages'>
  >(DEFAULT_PAGINATION);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const { cancelPreviousRequest, newAbortSignal } = useAbortController();

  const getFarms = async () => {
    try {
      const queryParams = params ?? DEFAULT_PARAMS;
      setLoading(true);
      setPagination(DEFAULT_PAGINATION);
      setFarms([]);
      cancelPreviousRequest();
      const { farmLands, pagination } = await farmServices.getFarms(
        {
          ...queryParams,
        },
        { signal: newAbortSignal() }
      );
      setFarms(farmLands || []);
      setPagination(pagination);
      setShowLoadMore(isLoadedAll);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async () => {
    if (!loading && !isLoadedAll) {
      try {
        setLoading(true);
        cancelPreviousRequest();
        const data = await farmServices.getFarms(
          {
            ...params,
            page: pagination.page + 1,
            pageSize: pagination.pageSize,
          },
          { signal: newAbortSignal() }
        );
        setFarms([...farms, ...(data.farmLands || [])]);
        setPagination(data.pagination);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const isLoadedAll = useMemo(() => {
    if (loading) return false;
    return pagination.page * pagination.pageSize >= pagination.total;
  }, [pagination, loading]);

  const refresh = () => setParams(DEFAULT_PARAMS);

  useEffect(() => {
    getFarms();
  }, [params]);

  useEffect(() => {
    setShowLoadMore(false);
  }, [params.farmStatus]);

  useEffect(() => {
    if (pagination.page * pagination.pageSize >= pagination.total) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }
  }, [pagination]);

  return {
    params,
    setParams,
    farms,
    pagination,
    loading,
    loadMore,
    isLoadedAll,
    refresh,
    showLoadMore,
  };
};

const useFarmDetail = () => {
  const { projectId } = useProjectContext();
  const { featureFlagsData } = useFlagsupContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [updatingPolygon, setUpdatingPolygon] = useState<boolean>(false);
  const [upsertingComment, setUpsertingComment] = useState<boolean>(false);
  const [farmDetails, setFarmDetails] = useState<IFarmLand>();

  const isCAR1598Enabled = featureFlagsData.CAR_1598?.enabled;

  const getFarmDetails = async (farmId: number) => {
    try {
      setLoading(true);
      const result = await farmServices.getFarmDetails(
        farmId,
        isCAR1598Enabled ? projectId : undefined
      );
      setFarmDetails(result.farmLand);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const upsertCommentFarmLand = async (farmLandId: number, content: string) => {
    try {
      setUpsertingComment(true);
      const result = await discussionHistoryServices.commentFarmLand({
        farmLandId,
        content,
      });
      return result;
    } finally {
      setUpsertingComment(false);
    }
  };

  const updateFarmLandMappingStatus = async (
    farmLandId: number,
    status: string
  ) => {
    try {
      setLoading(true);
      const result = await farmServices.updateFarmLandMappingStatus(
        farmLandId,
        { status, projectId }
      );
      return result;
    } finally {
      setLoading(false);
    }
  };

  const updateFarmMappingForm = async (
    payload: IUpsertFarmMappingFormParams
  ) => {
    try {
      setUpdatingPolygon(true);
      const result = await farmServices.upsertFarmMappingForm(payload);
      return result;
    } finally {
      setUpdatingPolygon(false);
    }
  };

  const removeSurvey = async (surveyId: number) => {
    if (!farmDetails || !farmDetails.polygonMap) return;

    const params = {
      polygonId: farmDetails?.farmLandId,
      projectId,
      polygonName: farmDetails.name,
      farmerId: farmDetails.farmerId,
      polygon: farmDetails.polygonMap,
      surveyForms: farmDetails?.formSurveys?.filter(
        survey => survey.formSubmissionId !== surveyId
      ),
    };
    await farmServices.upsertFarmMappingForm(params);
  };

  const updateSurvey = async (
    submissionId: ISurveyForm['formSubmissionId'],
    changedAnswers: ISurveyForm['answers']
  ) => {
    if (!farmDetails || !farmDetails.polygonMap) return;

    const params = {
      polygonId: farmDetails?.farmLandId,
      projectId,
      polygonName: farmDetails.name,
      farmerId: farmDetails.farmerId,
      polygon: farmDetails.polygonMap,
      surveyForms: farmDetails?.formSurveys?.map(survey => {
        if (survey.formSubmissionId === submissionId) {
          return {
            ...survey,
            answers: changedAnswers,
          };
        }
        return survey;
      }),
    };
    await farmServices.upsertFarmMappingForm(params);
  };

  const refresh = async () => {
    if (farmDetails) {
      await getFarmDetails(farmDetails.farmLandId);
    }
  };

  return {
    loading,
    farmDetails,
    updatingPolygon,
    upsertingComment,
    setFarmDetails,
    getFarmDetails,
    upsertCommentFarmLand,
    updateFarmLandMappingStatus,
    updateFarmMappingForm,
    removeSurvey,
    updateSurvey,
    refresh,
  };
};

const useNearbyFarms = () => {
  const { projectId } = useProjectContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [nearbyFarms, setNearbyFarms] = useState<INearbyPolygon[]>([]);

  const getNearbyFarms = async (polygonId: number) => {
    try {
      setLoading(true);
      const result = await farmServices.getNearbyFarms({
        projectId,
        polygonId,
        ...PAGING_OFFSET_ALL,
      });
      if (result) {
        setNearbyFarms(result.nearbyPolygons);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    nearbyFarms,
    loading,
    getNearbyFarms,
  };
};

export default {
  useFarms,
  useFarmDetail,
  useNearbyFarms,
};
