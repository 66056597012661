import { useQuery } from '@tanstack/react-query';
import { commonConstants } from 'constants/index';
import { useProjectContext } from 'contexts';
import { OrganizationRole } from 'interfaces/organization';
import organizationServices from 'services/apis/organization';

const { QUERY_KEYS } = commonConstants;

const useOrganizationRoles = () => {
  const { projectId } = useProjectContext();
  const { data, error, refetch } = useQuery<OrganizationRole[]>({
    initialData: [],
    queryKey: [QUERY_KEYS.ORGANIZATION_ROLES, projectId],
    queryFn: async () => {
      return await organizationServices.getOrganizationRoles({
        projectId,
      });
    },
  });

  return {
    organizationRoles: data,
    loading: !error && !data,
    refetch,
  };
};

export default {
  useOrganizationRoles,
};
