import { Button, Flex, Result, Space } from 'antd';
import FormationPage from 'components/CREMA/Formation';
import {
  LiveSelectTypeOffset,
  IBaseFilter,
} from 'components/shared/Select/LiveSelectTypeOffset';
import AppContainer from 'containers/AppLayout/AppContainer';
import { useProjectContext } from 'contexts';
import { MapProvider } from 'contexts/map';
import { cremaHooks, userHooks } from 'hooks';
import React from 'react';
import Icon, { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as Polygon } from 'assets/svgs/Polygon.svg';
import { ReactComponent as Marker } from 'assets/svgs/EnvironmentOutlined.svg';
import Navbar from 'components/shared/Navbar';
import {
  CremaFormationProvider,
  useCremaFormationContext,
} from 'contexts/cremaFormation';
import { ID_DEFAULT } from 'constants/common';
import { paginationHelpers } from 'helpers';
import { CremaDrawer } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { commonConstants } from 'constants/index';

const { IS_WA } = commonConstants;
const { useUserPermissions } = userHooks;

const CremaFormation: React.FC = () => {
  const { t } = useTranslation(window.appConfig?.appName);
  const { projectId } = useProjectContext();
  const { loading, onSearch } = cremaHooks.useSearchOrganizations();
  const {
    setCremaInfo,
    setCommunityInfo,
    toggleDrawer,
  } = useCremaFormationContext();
  const { canCreateCrema, canUpdateCrema } = useUserPermissions();

  if (projectId === ID_DEFAULT)
    return <Result status="404" title={t('ThereAreNoProjects')} />;

  const optionFetcher = async ({ keyword, offset, limit }: IBaseFilter) => {
    const { organizations, pagination } = await onSearch({
      keyword,
      limit,
      offset,
    });
    let filterData = { ...pagination, keyword, limit };
    const shouldLoadMore = paginationHelpers.shouldLoadMore(
      organizations.length,
      limit || 0
    );
    if (!shouldLoadMore) filterData.offset = -1;
    return {
      options: organizations.map(organization => ({
        label: organization.organizationName,
        value: organization.organizationId,
        key: organization.organizationId,
        ...organization,
      })),
      ...filterData,
    };
  };

  const openCremaDetail = (organizationId: number) => {
    // open crema detail drawer
    setCremaInfo?.({ id: organizationId });
    toggleDrawer(CremaDrawer.CremaDetail);
  };

  const openCommunityDetail = (communityId: number) => {
    // open community detail drawer
    setCommunityInfo?.({ id: communityId });
    setCremaInfo?.({ id: ID_DEFAULT });
    toggleDrawer(CremaDrawer.CommunityDetail);
  };

  return (
    <>
      <Navbar>
        <Flex justify="space-between">
          <LiveSelectTypeOffset
            allowClear
            suffixIcon={<SearchOutlined />}
            allowSearchEmpty={false}
            optionKey="organizationId"
            loading={loading}
            optionFetcher={optionFetcher}
            onSelect={(_, { data }) => {
              const { organizationType, organizationId } = data;
              organizationType === 'CREMA'
                ? openCremaDetail?.(organizationId)
                : openCommunityDetail?.(organizationId);
            }}
            optionRender={({ label, data }) => {
              const { data: organization } = data;
              return (
                <Space>
                  <Icon
                    component={
                      organization.organizationType === 'CREMA'
                        ? Polygon
                        : Marker
                    }
                  />
                  {label}
                </Space>
              );
            }}
            style={{ width: 300 }}
            placeholder={t('TypeCremaCommunityNameToSearch')}
          />
          <Space>
            {canUpdateCrema && (
              <Button
                type="default"
                onClick={() => toggleDrawer(CremaDrawer.CreateCommunity)}
              >
                {t('CreateCommunity')}
              </Button>
            )}
            {canCreateCrema && (
              <Button
                type="primary"
                onClick={() => toggleDrawer(CremaDrawer.CreateCrema)}
              >
                {t('CreateCREMA')}
              </Button>
            )}
          </Space>
        </Flex>
      </Navbar>
      <FormationPage hideMeetingRecords={IS_WA} hideDocumentations={IS_WA} />
    </>
  );
};

const CremaFormationWrapper: React.FC = () => {
  const { projectId } = useProjectContext();
  const { t } = useTranslation(window.appConfig?.appName);

  if (projectId === ID_DEFAULT)
    return <Result status="404" title={t('ThereAreNoProjects')} />;

  return (
    <AppContainer className="d-flex flex-column">
      <CremaFormationProvider>
        <MapProvider>
          <CremaFormation />
        </MapProvider>
      </CremaFormationProvider>
    </AppContainer>
  );
};

export default CremaFormationWrapper;
