import { ID_DEFAULT } from 'constants/common';
import { useProjectContext } from 'contexts';
import { IGetListJoinMGRequestResponse } from 'interfaces';
import { useEffect, useState } from 'react';

import { memberManagementServices } from 'services';

const useJoinMGRequests = (memberId: number) => {
  const { projectId } = useProjectContext();
  const [loading, setLoading] = useState(false);
  const [joinMGRequests, setJoinMGRequests] = useState<
    IGetListJoinMGRequestResponse
  >();

  const getJoinMGRequests = async () => {
    try {
      setLoading(true);
      const res = await memberManagementServices.getListJoinMGRequest({
        projectId,
        farmerUserId: memberId,
      });

      setJoinMGRequests(res);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (memberId !== ID_DEFAULT) {
      getJoinMGRequests();
    }
  }, [memberId]);

  return { loading, joinMGRequests, getJoinMGRequests };
};

export default {
  useJoinMGRequests,
};
