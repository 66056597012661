import {
  Button,
  Checkbox,
  Drawer,
  Flex,
  Form,
  Select,
  notification,
} from 'antd';
import { tSelectPlaceholder } from 'helpers/i18n';
import { useContext, useEffect, useState } from 'react';
import { cremaServices } from 'services';
import { ProjectContext } from 'contexts';
import { CloseOutlined } from '@ant-design/icons';
import { IAddCommunity } from 'interfaces';
import { communityHooks, mapHooks } from 'hooks';
import { filterSelect } from 'helpers/common';
import { DEFAULT_MAX_TAG_COUNT, ID_DEFAULT } from 'constants/common';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { commonConstants } from 'constants/index';

const { IS_WA } = commonConstants;

const AddCommunity: React.FC<IAddCommunity> = props => {
  const { t } = useTranslation(window.appConfig?.appName);
  const { projectId } = useContext(ProjectContext);

  const { cremaId, refetchCommunities, ...drawerProps } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = useForm();
  const onlyInCREMA = Form.useWatch('onlyInCREMA', form);
  const selectedCommunityIds = Form.useWatch('communityIds', form);

  const {
    communities: unlinkCommunities,
    loading: gettingCommunities,
    getCommunities: getUnlinkCommunities,
  } = communityHooks.useCremaCommunities({
    coordinateInCremaId:
      onlyInCREMA !== false && cremaId !== ID_DEFAULT ? cremaId : undefined,
    isCremaLinked: false,
  });

  // Highlight & focus selected communities
  mapHooks.useMarkerHighlight(selectedCommunityIds, { focus: true });

  useEffect(() => {
    resetValues();
  }, [cremaId]);

  const close = (e?: any) => {
    resetValues();
    drawerProps.onClose?.(e);
  };

  const handleSubmit = async () => {
    try {
      setSaveLoading(true);
      await cremaServices.addCommunityToCrema({
        projectId,
        cremaId,
        communityIds: selectedCommunityIds,
      });
      notification.success({
        message: t('AddCommunitiesSuccessfully'),
      });
      getUnlinkCommunities();
      close();
      refetchCommunities();
    } finally {
      setSaveLoading(false);
    }
  };

  const resetValues = () => {
    form.resetFields();
  };

  const footer = (
    <Flex className="p-half" justify="center">
      <Button
        className="w-120px"
        type="primary"
        onClick={handleSubmit}
        loading={saveLoading}
        disabled={!selectedCommunityIds?.length}
      >
        {t('Save')}
      </Button>
    </Flex>
  );

  return (
    <Drawer
      placement="right"
      getContainer={'.formation-wrapper'}
      rootStyle={{ position: 'absolute' }}
      zIndex={1002}
      title={t('AddCommunityToCREMA')}
      styles={{ header: { textAlign: 'center', borderBottom: 'none' } }}
      closeIcon={<CloseOutlined />}
      onClose={close}
      maskClosable={false}
      mask={false}
      footer={footer}
      className="add-community-drawer"
      {...drawerProps}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          className="mb-0 d-flex justify-content-end"
          name="onlyInCREMA"
          valuePropName="checked"
        >
          <Checkbox
            defaultChecked
            onChange={() => {
              form.resetFields(['communityIds']);
            }}
          >
            {t('OnlyInCREMA')}
          </Checkbox>
        </Form.Item>

        <Form.Item
          label={t('ListCommunity')}
          required
          name="communityIds"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            allowClear
            mode="multiple"
            loading={gettingCommunities}
            placeholder={tSelectPlaceholder('Community', {
              ns: window.appConfig?.appName,
              lowerCase: !IS_WA,
            })}
            options={unlinkCommunities.map(community => ({
              label: community.communityName,
              value: community.communityId,
            }))}
            maxTagCount={DEFAULT_MAX_TAG_COUNT}
            filterOption={filterSelect}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddCommunity;
