import { getData } from 'helpers/request';
import {
  GenerateARRCarbonPotentialReportPayload,
  IARRCarbonPotentialReport,
  ICarbonPotentialReport,
} from 'interfaces';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;
const prefixUrl = '/tekbone/api/v1';

const getARRCarbonPotentialReport = (
  projectId: number
): Promise<IARRCarbonPotentialReport> =>
  projectManagerClient
    .get(`${prefixUrl}/carbon-report/feasibility-study/arr-carbon-potential`, {
      params: { projectId },
    })
    .then(getData);

const generateAREACarbonPotentialReport = (
  payload: GenerateARRCarbonPotentialReportPayload
): Promise<void> =>
  projectManagerClient.post(
    `${prefixUrl}/carbon-report/feasibility-study/generate-arr-carbon-potential`,
    payload
  );

const getCarbonPotentialReport = (
  projectId: number
): Promise<ICarbonPotentialReport> =>
  projectManagerClient
    .get(`${prefixUrl}/carbon-report/feasibility-study/carbon-potential`, {
      params: { projectId },
    })
    .then(getData);

export default {
  getARRCarbonPotentialReport,
  generateAREACarbonPotentialReport,
  getCarbonPotentialReport,
};
