const HA = 'ha';
const CM = 'cm';
const T_CO2 = 'tCO2';
const KG_CO2 = 'kgCO2';
const KG_C = 'kgC';

export default {
  HA,
  T_CO2,
  KG_CO2,
  KG_C,
  CM,
};
