import { Select, SelectProps } from 'antd';
import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
import { useProjectContext } from 'contexts';
import { IMemberGroup } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { memberManagementServices } from 'services';

interface IMemberGroupRecomendationSelectProps extends SelectProps {
  landId: number;
}

const MemberGroupRecomendationSelect: React.FC<IMemberGroupRecomendationSelectProps> = ({
  landId,
  ...selectProps
}) => {
  const [loading, setLoading] = useState(false);
  const [memberGroups, setMemberGroups] = useState<IMemberGroup[]>([]);
  const { projectId } = useProjectContext();

  useEffect(() => {
    const getMemberGroups = async () => {
      try {
        setLoading(true);
        const res = await memberManagementServices.getListMGRecommendation({
          projectId,
          landId,
          ...DEFAULT_PAGINATION_OFFSET,
        });
        setMemberGroups(res.memberGroups);
      } finally {
        setLoading(false);
      }
    };
    getMemberGroups();
  }, []);

  return (
    <Select
      loading={loading}
      options={memberGroups}
      fieldNames={{ label: 'name', value: 'id' }}
      {...selectProps}
    />
  );
};

export default MemberGroupRecomendationSelect;
