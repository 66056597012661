export const HIDE_ERROR_URLS = [
  '/api/v1/basemap',
  '/api/v1/user-appointment',
  '/api/v1/projects',
  '/tekbone/api/v1/get-basemap-labels',
  '/tekbone/api/v1/get-basemap-polygons',
  '/tekbone/api/v1/search-polygons',
  '/tekbone/api/v1/cremas',
  '/tekbone/api/v1/member-groups/staffs/farmers/join-requests/:requestId/approval',
];

// Get the ticket domain from the app config in case customer have their own domain otherwise use the default ticket domain
const TICKET_DOMAIN =
  window.appConfig?.iframeEndpoint?.ticketManagement ||
  window.config?.iframeEndpoint?.ticketManagement;

export default {
  HIDE_ERROR_URLS,
  TICKET_DOMAIN,
};
