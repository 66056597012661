/** Third party libs **/
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  notification,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  EnvironmentOutlined,
  UserOutlined,
  TeamOutlined,
  CloseOutlined,
  EditOutlined,
  DoubleLeftOutlined,
  SaveOutlined,
} from '@ant-design/icons';

/** Local libs **/
import { CommentCard } from './CommentCard';
import { SubmitFarmButton } from './SubmitFarmButton';
import { SurveyCard } from './SurveyCard';
import FarmSubmitInfoCard from './FarmSubmitInfoCard/FarmSubmitInfoCard';
import { farmHooks, userHooks } from 'hooks';
import EligibleCriteria from './EligibleCriteria';
import { t } from 'helpers/i18n';
import AlertCard from './AlertCard';
import ContentBlock from 'components/shared/ContentBlock';
import { convertNumberFormat } from 'helpers/common';
import { DrawState } from 'constants/map';
import { CurrentSelectedFarm } from '../../Context';
import { FARM_EDITABLE_STATUSES } from 'constants/farm';

/** Styles **/
import './FarmInfo.scss';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { useFarmDetailContext } from 'components/FarmerManagement/FarmerEnrolment';
import { AppDispatch } from 'redux/store';
import { useDispatch } from 'react-redux';
import {
  setFarmMap,
  setFarmMapPolygonInitialzed,
} from 'redux/features/farmMap';
import { mapHelpers } from 'helpers';
import { IUpsertFarmMappingFormParams } from 'interfaces';

/** Interfaces, enum... **/

interface IFarmInfoProps {
  refresh: (farmStatus: string, keyword?: string) => void;
  listFarmType: string;
  setListFarmType: (type: string) => void;
  keyword?: string;
}

/** Variables **/
const { Text, Title } = Typography;
const { useUserPermissions } = userHooks;
const { getPolygonWktFromLatLng, getPolygonsMapToSendAPI } = mapHelpers;

/** ------------------------- **/
/** FarmInfo Component */
/** ------------------------- **/
const FarmInfo: React.FC<IFarmInfoProps> = ({
  listFarmType,
  setListFarmType,
  refresh,
}) => {
  /** States **/
  const [triggerReload, setTriggerReload] = useState(false);

  /** Hooks **/
  const [formComment] = Form.useForm();
  const { farmDetails } = useFarmDetailContext();
  const { projectId } = useProjectContext();
  const {
    drawState,
    setDrawState,
    drawRef,
    selectedFarmId,
    setSelectedFarmId,
    selectedSurveyForm,
    setSelectedSurveyForm,
    showDiscussionHistory,
    setShowDiscussionHistory,
    showFarmList,
    setShowFarmList,
    setShowFarmInfo,
    setCurrentFarmArea,
    showSurveyReport,
    setShowSurveyReport,
    setListPlacemark,
    setListFormSurvey,
    setCurrentSurveyLocation,
    setListNearbyFarm,
    setOverlapPolygon,
    currentSurveyLocation,
  } = useContext(CurrentSelectedFarm);
  const { canApproveFarmerEnrollment } = useUserPermissions();
  const { nearbyFarms, getNearbyFarms } = farmHooks.useNearbyFarms();
  const { featureFlagsData } = useFlagsupContext();
  const {
    getFarmDetails,
    setFarmDetails,
    updateFarmMappingForm,
    loading,
    updatingPolygon,
  } = useFarmDetailContext();
  const dispatch: AppDispatch = useDispatch();

  /** Variables **/
  const editable =
    farmDetails &&
    FARM_EDITABLE_STATUSES.includes(farmDetails.farmStatus) &&
    canApproveFarmerEnrollment;
  const isCARSA658Enabled = featureFlagsData.CARSA_658?.enabled;
  const isCAR2887Enabled = featureFlagsData.CAR_2887?.enabled;

  /** Functions, Events, Actions... **/
  const onCloseFarmInfo = () => {
    setSelectedFarmId(undefined);
    setSelectedSurveyForm(undefined);
    setShowDiscussionHistory(false);
    setShowFarmList(true);
    setShowFarmInfo(false);
    setDrawState?.(DrawState.NONE);
    setShowSurveyReport(false);
    setListPlacemark([]);
    setCurrentSurveyLocation(undefined);
    setOverlapPolygon([]);
    setListNearbyFarm([]);
  };

  const onOpenFarmList = () => {
    setShowFarmList(prev => !prev);
    if (showSurveyReport) {
      setShowSurveyReport(false);
    }
  };

  const onEditPolygon = () => {
    if (editable) {
      setShowFarmList(false);
      setDrawState?.(DrawState.EDIT);
      setShowSurveyReport(false);
    }
  };

  const onSavePolygon = async () => {
    if (editable && selectedFarmId) {
      const polygonsMap = drawRef?.current?.getDrawPolygon();

      if (polygonsMap) {
        const payload: IUpsertFarmMappingFormParams = {
          polygonId: selectedFarmId,
          polygonName: farmDetails?.name,
          farmerId: farmDetails?.farmerId,
          createdAt: farmDetails?.submittedDate,
          surveyForms: farmDetails?.formSurveys,
          projectId,
          ...(isCAR2887Enabled
            ? { multiplePolygonWkt: getPolygonWktFromLatLng(polygonsMap) }
            : {
                polygon:
                  getPolygonsMapToSendAPI(polygonsMap)?.[0]?.polygonMap || [],
              }),
        };
        const result = await updateFarmMappingForm(payload);

        if (result.status === 200) {
          notification.success({
            message: t('Success'),
            description: t('UpdateSuccessfully'),
          });
          setTriggerReload(prev => !prev);
          refresh(listFarmType);
        }
      }
    }
  };

  /** Effects **/
  useEffect(() => {
    if (selectedFarmId) {
      setShowFarmList(false);
      setFarmDetails(undefined);
      getFarmDetails(selectedFarmId).then();
    }
  }, [selectedFarmId, triggerReload]);

  useEffect(() => {
    if (farmDetails) {
      setDrawState?.(DrawState.NONE);
      formComment.resetFields();
      dispatch(
        setFarmMap({
          id: farmDetails.farmLandId,
          polygonMap: farmDetails?.polygonMap || [],
          multiplePolygonWkt: farmDetails?.multiplePolygonWkt,
        })
      );
      dispatch(setFarmMapPolygonInitialzed(true));
      setCurrentFarmArea(farmDetails?.area || 0);
      if (
        Array.isArray(farmDetails?.placemarks) &&
        farmDetails?.placemarks?.length
      ) {
        setListPlacemark(farmDetails.placemarks);
      }
      if (
        Array.isArray(farmDetails?.formSurveys) &&
        farmDetails?.formSurveys.length
      ) {
        setListFormSurvey(farmDetails.formSurveys);
        const currentSurvey = farmDetails.formSurveys?.find(
          survey =>
            survey.formSubmissionId === currentSurveyLocation?.formSurveyId
        );
        if (!currentSurvey) {
          setCurrentSurveyLocation(undefined);
        } else {
          setSelectedSurveyForm(currentSurvey);
        }
      }
    }
  }, [farmDetails]);

  useEffect(() => {
    if (selectedFarmId) {
      setListNearbyFarm([]);
      setOverlapPolygon([]);
      if (isCARSA658Enabled) getNearbyFarms(selectedFarmId);
    }
  }, [selectedFarmId]);

  useEffect(() => {
    if (
      Array.isArray(nearbyFarms) &&
      nearbyFarms.length > 0 &&
      isCARSA658Enabled
    ) {
      setListNearbyFarm(nearbyFarms);
    }
  }, [nearbyFarms]);

  /** Elements **/
  return (
    <ContentBlock
      className="farmInfo"
      extra={
        <div className="d-flex justify-content-between">
          <DoubleLeftOutlined
            className="d-block extraButton"
            onClick={onOpenFarmList}
            rotate={showFarmList ? 0 : -180}
          />
          <CloseOutlined
            className="d-block extraButton"
            onClick={onCloseFarmInfo}
          />
        </div>
      }
    >
      <Spin spinning={loading}>
        {farmDetails ? (
          <Space direction="vertical" className="w-100">
            <div className="w-100 farm-name-wrapper position-relative">
              <div className="d-block farm-name">
                <Title level={5} className="d-block w-100 text-center mb-0">
                  {farmDetails?.name}
                </Title>
              </div>
              {editable && (
                <div className="position-absolute" style={{ top: 2, right: 4 }}>
                  {drawState === DrawState.EDIT ? (
                    <Button
                      icon={<SaveOutlined />}
                      className="editButton"
                      onClick={onSavePolygon}
                      loading={updatingPolygon}
                      type="text"
                    />
                  ) : (
                    <Button
                      icon={<EditOutlined />}
                      className="editButton"
                      onClick={onEditPolygon}
                      type="text"
                    />
                  )}
                </div>
              )}
            </div>
            <div className="farm-info-content-wrapper">
              <Form form={formComment}>
                <Space direction="vertical" className="w-100">
                  <FarmSubmitInfoCard selectedFarmLand={farmDetails} />
                  <Card>
                    <Row>
                      <Col span={24} className="d-flex justify-content-between">
                        <Text className="d-block">
                          <EnvironmentOutlined className="green-icon" />{' '}
                          {t('Area')}
                        </Text>
                        <Text className="d-block w-50 text-right">
                          {farmDetails?.area &&
                            convertNumberFormat(farmDetails?.area)}{' '}
                          Ha
                        </Text>
                      </Col>
                      <Col span={24} className="d-flex justify-content-between">
                        <Text className="d-block">
                          <UserOutlined className="green-icon" /> {t('Farmer')}
                        </Text>
                        <Text className="d-block w-50 text-right">
                          {farmDetails?.farmerName}
                        </Text>
                      </Col>
                      <Col span={24} className="d-flex justify-content-between">
                        <Text className="d-block">
                          <TeamOutlined className="green-icon" />{' '}
                          {t('Community')}
                        </Text>
                        <Text className="d-block w-50 text-right">
                          {farmDetails?.communityName}
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                  {farmDetails?.warnings && (
                    <AlertCard warnings={farmDetails?.warnings} />
                  )}
                  {!!farmDetails?.eligibleCriteria?.length && (
                    <EligibleCriteria
                      eligibleCriterias={farmDetails?.eligibleCriteria}
                    />
                  )}
                  {!!farmDetails?.formSurveys?.length && (
                    <SurveyCard
                      listSurvey={farmDetails?.formSurveys}
                      selectedSurveyForm={selectedSurveyForm}
                      onSelectSurveyForm={setSelectedSurveyForm}
                      setShowDiscussionHistory={setShowDiscussionHistory}
                      setShowFarmList={setShowFarmList}
                    />
                  )}
                  <CommentCard
                    setShowDiscussionHistory={setShowDiscussionHistory}
                    setSelectedSurveyForm={setSelectedSurveyForm}
                    setShowFarmList={setShowFarmList}
                    showDiscussionHistory={showDiscussionHistory}
                    formComment={formComment}
                    farmLandId={farmDetails?.farmLandId}
                  />
                </Space>
              </Form>
            </div>
            <SubmitFarmButton
              isApproved={!editable}
              farmLandId={farmDetails?.farmLandId}
              getFarmDetails={getFarmDetails}
              setListFarmType={setListFarmType}
              setShowDiscussionHistory={setShowDiscussionHistory}
              farmDetails={farmDetails}
              refreshFarmList={refresh}
            />
          </Space>
        ) : (
          <Empty />
        )}
      </Spin>
    </ContentBlock>
  );
};

export { FarmInfo };
export default FarmInfo;
