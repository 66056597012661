import {
  ApprovalAction,
  IPaginationOffsetParams,
  IPaginationOffsetResponse,
} from './common';

export enum MembershipStatus {
  PROVISIONAL = 'PROVISIONAL',
  BASIC = 'BASIC',
  GOLD = 'GOLD',
  VERIFIED = 'VERIFIED',
  CERTIFIED = 'CERTIFIED',
}

export enum MembershipCategory {
  SMALL_HOLDER = 'SMALL_HOLDER',
  SMALL_GROWER = 'SMALL_GROWER',
  PLANTATION = 'PLANTATION',
}

export enum JoinMGRequestStatus {
  APPROVED = 'APPROVED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
}

export interface IMember {
  userId: number;
  fullName: string;
  membershipStatus: MembershipStatus;
  membershipCategory: MembershipCategory;
  phoneNumber: string;
  isPendingJoinMGRequest: boolean;
}

export interface IGetListMemberParams extends IPaginationOffsetParams {
  projectId: number;
  keyword?: string;
  membershipCategory?: MembershipCategory;
  membershipStatus?: MembershipStatus;
  isPendingJoinRequest?: boolean;
}

export interface IGetListMemberResponse {
  farmers: IMember[];
  pagination: IPaginationOffsetResponse;
}

export interface IJoinMGRequest {
  requestId: number;
  landId: number;
  landName: string;
  landReferenceNumber: string;
  memberGroupId: number;
  memberGroupName: string;
  status: JoinMGRequestStatus;
}

export interface IGetListJoinMGRequestParams {
  projectId: number;
  farmerUserId: number;
}

export interface IGetListJoinMGRequestResponse {
  farmerUserId: number;
  farmerName: string;
  joinMemberGroupRequests: IJoinMGRequest[];
  pagination: IPaginationOffsetResponse;
}

export interface IMemberGroup {
  id: number;
  name: string;
}

export interface IGetListMGRecommendationParams
  extends IPaginationOffsetParams {
  projectId: number;
  landId: number;
  keyword?: string;
}

export interface IGetListMGRecommendationResponse {
  memberGroups: IMemberGroup[];
  pagination: IPaginationOffsetResponse;
}

export interface IJoinMGRequestApprovalPayload {
  requestId: number;
  projectId: number;
  memberGroupId?: number;
  action: ApprovalAction;
}
