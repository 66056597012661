import { DeleteOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Popconfirm, Select, Table } from 'antd';
import { Store } from 'antd/es/form/interface';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { DRAWER_WIDTH } from 'constants/common';
import { DEFAULT_OPTION, PAGING_PAGE_SIZE_ALL } from 'constants/pagination';
import { useProjectContext } from 'contexts';
import { paginationHelpers } from 'helpers';
import { filterSelect } from 'helpers/common';
import { t, tSelectPlaceholder } from 'helpers/i18n';
import { staffHooks, organizationHooks, userHooks } from 'hooks';
import { IPaginationResponse } from 'interfaces';
import { useEffect, useMemo, useState } from 'react';
import {
  OrganizationStaffDrawerProps,
  Staff,
} from './OrganizationStaffDrawer.type';
const { generateRowIndex } = paginationHelpers;

const OrganizationStaffDrawer: React.FC<OrganizationStaffDrawerProps> = ({
  organization,
  getOrgStaffsRequest,
  addStaffRequest,
  deleteStaffRequest,
  onChangeStaffNumbers,
  ...props
}) => {
  const { projectId } = useProjectContext();
  const {
    getStaffs: getUnassignedStaffs,
    staffs: unassignedStaffs,
    loading: getStaffLoading,
  } = staffHooks.useStaff();
  const {
    organizationRoles,
    loading: getRoleLoading,
    refetch: getRoles,
  } = organizationHooks.useOrganizationRoles();
  const [form] = Form.useForm();
  const { canUpdateCrema } = userHooks.useUserPermissions();

  const [staffs, setStaffs] = useState<Staff[]>([]);
  const [addStaffLoading, setAddStaffLoading] = useState(false);
  const [listStaffLoading, setListStaffLoading] = useState(false);
  const [pagination, setPagination] = useState<IPaginationResponse>({
    total: 0,
    pageSize: 10,
    page: 1,
  });
  const totalStaffNumbers = pagination?.total || 0;
  const {
    organizationId,
    organizationTypeName,
    organizationName,
    organizationTypeId,
  } = organization;

  useEffect(() => {
    onChangeStaffNumbers?.(totalStaffNumbers);
  }, [totalStaffNumbers]);

  const getOrganizationStaffs = async () => {
    try {
      setListStaffLoading(true);
      // Fetch all staffs
      const { staffs, pagination } = await getOrgStaffsRequest({
        ...PAGING_PAGE_SIZE_ALL,
        organizationId,
        projectId,
      });
      setStaffs(staffs || []);
      setPagination(prev => ({ ...prev, total: pagination.total }));
    } finally {
      setListStaffLoading(false);
    }
  };

  const addStaff = async (values: Store) => {
    const currentMembers =
      staffs?.map(staff => ({
        memberId: staff.memberId,
        memberRoleName: staff.memberRoleName,
      })) || [];
    const member = {
      memberId: values.memberId,
      memberRoleName: values.memberRoleName,
      isActive: true,
    };
    try {
      setAddStaffLoading(true);
      await addStaffRequest?.({
        projectId,
        organizationId,
        members: [...currentMembers, member],
      });
      getOrganizationStaffs();
      form.resetFields();
    } finally {
      setAddStaffLoading(false);
    }
  };

  useEffect(() => {
    if (props.open) {
      form.resetFields();
      getOrganizationStaffs();
      getRoles();
      getUnassignedStaffs(projectId);
    }
  }, [props.open, organizationId]);

  const actionColumn: ColumnType<Staff> = {
    title: t('Action'),
    align: 'center',
    render: (_, record) => (
      <Popconfirm
        title={t('AreYouSureToDeleteThisMember')}
        onConfirm={async () => {
          await deleteStaffRequest?.({
            memberId: record.memberId,
            organizationId,
            projectId,
          });
          getOrganizationStaffs();
        }}
      >
        <Button icon={<DeleteOutlined />} type="text" danger />
      </Popconfirm>
    ),
  };

  const columns: ColumnsType<Staff> = [
    {
      title: '#',
      render: (_, record, index) =>
        generateRowIndex(pagination.page, pagination.pageSize, index),
    },
    {
      title: t('StaffName'),
      dataIndex: 'memberName',
    },
    {
      title: t('Role'),
      dataIndex: 'memberRoleName',
    },
    ...(canUpdateCrema ? [actionColumn] : []),
  ];

  const staffIds = staffs.map(staff => staff.memberId);

  // Filter unassigned staffs
  const unassignedStaffOptions = useMemo(() => {
    return unassignedStaffs
      .filter(unassignedStaff => !staffIds.includes(unassignedStaff.userId))
      .map(staff => ({
        value: staff.userId,
        label: staff.userName || staff.userEmail,
      }));
  }, [unassignedStaffs, staffIds]);

  const roleOptions = useMemo(() => {
    return organizationRoles
      .filter(role => role.organizationTypeId === organizationTypeId)
      .map(role => ({
        value: role.roleName,
        label: role.roleName,
      }));
  }, [organizationRoles, organizationTypeId]);

  return (
    <Drawer
      title={t('AddOrgStaff', { organizationType: organizationTypeName })}
      mask={false}
      getContainer={false}
      width={DRAWER_WIDTH.MEDIUM}
      zIndex={1005}
      destroyOnClose
      {...props}
    >
      {canUpdateCrema && (
        <Form
          layout="vertical"
          className="mb-half"
          form={form}
          onFinish={addStaff}
          clearOnDestroy
        >
          <Form.Item
            label={t('OrganizationName', {
              organizationType: organizationTypeName,
            })}
          >
            <Input readOnly value={organizationName} />
          </Form.Item>
          <Form.Item
            label={t('Staff')}
            required
            rules={[{ required: true }]}
            name="memberId"
          >
            <Select
              showSearch
              placeholder={tSelectPlaceholder('Staff')}
              loading={getStaffLoading}
              options={unassignedStaffOptions}
              filterOption={filterSelect}
            />
          </Form.Item>
          <Form.Item
            label={t('Role')}
            required
            rules={[{ required: true }]}
            name="memberRoleName"
          >
            <Select
              showSearch
              placeholder={tSelectPlaceholder('Role')}
              loading={getRoleLoading}
              options={roleOptions}
              filterOption={filterSelect}
            />
          </Form.Item>
          <div className="text-center">
            <Button type="primary" htmlType="submit" loading={addStaffLoading}>
              {t('Save')}
            </Button>
          </div>
        </Form>
      )}
      <Table
        rowKey="memberId"
        size="small"
        bordered
        columns={columns}
        dataSource={staffs}
        loading={listStaffLoading}
        pagination={{
          onChange: page => setPagination(prev => ({ ...prev, page })),
          total: pagination.total,
          pageSize: DEFAULT_OPTION.pageSize,
          showTotal: total => `${t('TotalRecords')}: ${total}`,
        }}
      />
    </Drawer>
  );
};

export default OrganizationStaffDrawer;
