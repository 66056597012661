import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMapPolygon } from 'components/shared/Map';
import { RootState } from 'redux/store';

export interface IFarmMapState {
  farmMap: IMapPolygon | undefined;
  farmMapPolygonInitialzed: boolean;
}

const initialState: IFarmMapState = {
  farmMap: {},
  farmMapPolygonInitialzed: true,
};

export const selectFarmMap = (state: RootState) => state.farmMap.farmMap;

export const selectInitFarmMapPolygon = (state: RootState) =>
  state.farmMap.farmMapPolygonInitialzed;

export const farmMapSlice = createSlice({
  name: 'farmMap',
  initialState,
  reducers: {
    setFarmMap: (state, action: PayloadAction<IMapPolygon | undefined>) => {
      state.farmMap = action.payload;
    },
    setFarmMapPolygonInitialzed: (state, action: PayloadAction<boolean>) => {
      state.farmMapPolygonInitialzed = action.payload;
    },
  },
});

export const { setFarmMap, setFarmMapPolygonInitialzed } = farmMapSlice.actions;

export default farmMapSlice.reducer;
