import {
  BarChartOutlined,
  CarryOutOutlined,
  CreditCardOutlined,
  CustomerServiceOutlined,
  EnvironmentOutlined,
  GroupOutlined,
  IdcardOutlined,
  ProjectOutlined,
  SnippetsOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import Community from 'containers/CREMA/Community';
import FPICCreate from 'containers/FPIC/Create';
import FPICDetail from 'containers/FPIC/Detail';
import FPICList from 'containers/FPIC/List';
import ProjectDetail from 'containers/Project/ProjectDetail/ProjectDetail';
import ProjectDetailMapV2 from 'containers/Project/ProjectDetailMapV2';
import ProjectMap from 'containers/Project/ProjectMap';
import SubmissionList from 'containers/Submission/SubmissionList';
import { t } from 'helpers/i18n';
import { withFeatureFlag } from 'hocs';
import { lazy } from 'react';
import { orgChartRoutes } from './common';
import CremaFormation from 'containers/CREMA/Formation';
import { roleHelpers } from 'helpers';
import { resourceConstants } from 'constants/index';
import MillsReports from 'containers/Report/MillsReports';
import { MemberManagement } from 'containers/MemberManagement';

const InProgressPage = lazy(() => import('containers/shared/InProgressPage'));
const TicketList = lazy(() => import('containers/Tickets/TicketList'));
const TicketDashboards = lazy(() =>
  import('containers/Tickets/TicketDashboards')
);
const QuarterlyReport = lazy(() =>
  import('containers/Report/QuarteryReports/QuarterlyReportContainer')
);

const { RESOURCES } = resourceConstants;
const {
  CREMA_FORMATION,
  MEMBER_MANAGEMENT,
  TICKETS,
  WILDASIA_QUARTERLY_REPORT,
} = RESOURCES;

const { tekboneReadPermission } = roleHelpers;

// Ticket mangement routes
const ticketRoutes = [
  {
    exact: true,
    path: '/tickets',
    name: t('Tickets'),
    icon: CustomerServiceOutlined,
  },
  {
    exact: true,
    path: '/tickets/dashboard',
    name: t('Dashboards'),
    component: TicketDashboards,
    permissions: [tekboneReadPermission(TICKETS)],
  },
  {
    exact: true,
    path: '/tickets/list',
    name: t('TicketList'),
    component: TicketList,
    permissions: [tekboneReadPermission(TICKETS)],
  },
];

// Route configs for Wild Asia
export const waRoutes = [
  {
    exact: true,
    path: '/project-boundaries',
    name: t('ProjectBoundaries'),
    icon: ProjectOutlined,
    component: withFeatureFlag(ProjectMap, ProjectDetailMapV2, 'CAR_418'),
  },
  {
    exact: true,
    path: '/management-group',
    name: t('Communities', { ns: window.appConfig?.appName }),
    icon: GroupOutlined,
    component: Community,
  },
  // WAGS Group Scheme Routes
  {
    exact: true,
    path: '/group-scheme',
    name: t('WAGSGroupScheme'),
    icon: GroupOutlined,
  },
  {
    exact: true,
    path: '/group-scheme/member-support-unit',
    name: t('MemberSupportUnit'),
    component: CremaFormation,
    permissions: [tekboneReadPermission(CREMA_FORMATION)],
  },
  {
    exact: true,
    path: '/members-management',
    name: t('MembersManagement'),
    icon: TeamOutlined,
    component: withFeatureFlag(InProgressPage, MemberManagement, 'CAR_2787'),
    permissions: [tekboneReadPermission(MEMBER_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/submissions',
    name: t('TreeSubmissions'),
    icon: EnvironmentOutlined,
    component: SubmissionList,
  },
  {
    exact: true,
    path: '/carbon-monitoring',
    name: t('CarbonMonitoring'),
    icon: CarryOutOutlined,
    component: ProjectDetail,
  },
  {
    exact: true,
    path: '/meeting-notes',
    name: t('MeetingRecords'),
    hideTitle: true,
    icon: IdcardOutlined,
    component: FPICList,
  },
  {
    exact: true,
    path: '/meeting-notes/create',
    name: t('CreateForm'),
    component: FPICCreate,
  },
  {
    exact: true,
    path: '/meeting-notes/:id',
    name: t('FormDetails'),
    component: FPICDetail,
  },
  {
    exact: true,
    path: '/dashboards',
    name: t('Dashboards'),
    icon: BarChartOutlined,
  },
  {
    exact: true,
    path: '/kpi-progress-tracking',
    name: t('KPIProgessTracking'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/me-indices-tracking',
    name: t('MEIndicesTracking'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/operational-metrics',
    name: t('OperationalMetrics'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/reports',
    name: t('Reports'),
    component: InProgressPage,
    icon: SnippetsOutlined,
  },
  {
    exact: true,
    path: '/reports/mills-reports',
    name: t('MillsReports'),
    component: MillsReports,
  },
  {
    exact: true,
    path: '/reports/quarterly-reports',
    name: t('QuarterlyReports'),
    component: QuarterlyReport,
    permission: [tekboneReadPermission(WILDASIA_QUARTERLY_REPORT)],
  },
  {
    exact: true,
    path: '/payment-services',
    name: t('PaymentServices'),
    component: InProgressPage,
    icon: CreditCardOutlined,
  },
  ...ticketRoutes,
  ...orgChartRoutes,
];
