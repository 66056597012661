import React from 'react';
import { Button, Card, Flex, List, Row, Tag, Typography } from 'antd';
import { DownOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGettingMembers,
  selectCurrentMemberId,
  selectMembers,
  setCurrentMemberId,
  loadMore,
  selectShowLoadMore,
} from 'redux/features/memberManagement';
import { AppDispatch } from 'redux/store';
import { t } from 'helpers/i18n';
import './ListMember.scss';

const ListMember: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const members = useSelector(selectMembers);
  const gettingMembers = useSelector(selectGettingMembers);
  const currentMember = useSelector(selectCurrentMemberId);
  const showLoadMore = useSelector(selectShowLoadMore);

  const onLoadMore = () => {
    dispatch(loadMore());
  };

  return (
    <Card className="list-member" classNames={{ body: 'p-base h-100' }}>
      <List
        loading={gettingMembers}
        dataSource={members}
        split={false}
        rowKey="userId"
        loadMore={
          showLoadMore && (
            <Row justify="center">
              <Button
                type="link"
                className="text-center"
                icon={<DownOutlined />}
                onClick={onLoadMore}
                disabled={gettingMembers}
              >
                {t('LoadMore')}
              </Button>
            </Row>
          )
        }
        renderItem={member => (
          <List.Item
            className={classNames(
              {
                'active-item': currentMember === member.userId,
                clickable: member.isPendingJoinMGRequest,
              },
              'd-flex flex-column align-items-start mb-base py-half px-base'
            )}
            onClick={() =>
              member.isPendingJoinMGRequest &&
              dispatch(setCurrentMemberId(member.userId))
            }
          >
            <Typography.Text strong>
              {member.fullName || t('NotAvailable')} - {member.userId}
            </Typography.Text>
            {member.isPendingJoinMGRequest && (
              <Tag color="processing">{t('PendingMemberGroupApproval')}</Tag>
            )}
            <Flex gap={8} align="center">
              <PhoneOutlined />
              <Typography.Text type="secondary">
                {member.phoneNumber || t('NotAvailable')}
              </Typography.Text>
            </Flex>
            <Flex gap={8} align="center">
              <UserOutlined />
              <Typography.Text type="secondary">
                {t(member.membershipCategory) || t('NotAvailable')}
              </Typography.Text>
            </Flex>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default ListMember;
