import { EditOutlined, ProjectOutlined, TagOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Drawer,
  Empty,
  Flex,
  Form,
  Input,
  Spin,
  Switch,
  Typography,
  notification,
} from 'antd';
import ProjectPlotMeasureForm from 'components/ProjectList/ProjectModal/ProjectPlotMeasureForm';
import { getDefaultGeneratePlotsValue } from 'helpers/plots';
import { polygonHooks, userHooks } from 'hooks';
import { t } from 'helpers/i18n';
import React, { useEffect, useState } from 'react';
import { ID_DEFAULT, PROJECT_BOUNDARY_Z_INDEX } from 'constants/common';
import { useProjectDetailV2Context } from 'containers/Project/ProjectDetailMapV2/context';
import { convertNumberFormat } from 'helpers/common';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { PAGING_OFFSET_ALL } from 'constants/pagination';
import { POLYGON_TYPES } from 'constants/project';
import { ReactComponent as FoldedPaper } from 'assets/svgs/FoldedPaper.svg';
import { COLOR } from 'constants/styles/color';
import { mapHelpers } from 'helpers';

interface PolygonDetailDrawerProps {
  polygonId: number;
  zIndexDrawer?: number;
  isPlotMode: boolean;
  setIsPlotMode: React.Dispatch<React.SetStateAction<boolean>>;
  getPolygons?: () => Promise<void>;
  setParams: React.Dispatch<any>;
}

const { Title, Text } = Typography;

interface IFormFields {
  polygonName: string;
}

const { minZIndexGenPlots } = window.config;
const {
  getPolygonsMapToSendAPI,
  getPolygonWktFromLatLng,
  initPoints,
} = mapHelpers;
const { useUserPermissions } = userHooks;

const PolygonDetailDrawer: React.FC<PolygonDetailDrawerProps> = ({
  polygonId,
  zIndexDrawer,
  isPlotMode,
  getPolygons,
  setIsPlotMode,
  setParams,
}) => {
  const [form] = Form.useForm<IFormFields>();
  const [isEdit, setIsEdit] = useState(false);
  const [isGenPlot, setIsGenPlot] = useState(false);
  const [isNewPolygon, setIsNewPolygon] = useState(false);
  const {
    polygonDetails,
    gettingDetails,
    upsertingPolygon,
    getPolygonDetails,
    upsertPolygon,
  } = polygonHooks.usePolygon();
  const {
    setCurrentPolygonId,
    setStatusAfterMapChanges,
    drawRef,
    setShowAddPolygon,
    polygons,
    setPolygons,
  } = useProjectDetailV2Context();
  const { projectId } = useProjectContext();
  const { featureFlagsData } = useFlagsupContext();
  const { canUpdateProject } = useUserPermissions();
  const isCAR2887Enabled = featureFlagsData.CAR_2887?.enabled;

  const polygonsFields = [
    {
      icon: (
        <FoldedPaper
          style={{
            width: 14,
            height: 14,
            filter:
              'invert(57%) sepia(45%) saturate(358%) hue-rotate(66deg) brightness(102%) contrast(82%)',
          }}
          className="d-block mr-half"
        />
      ),
      label: t('Area'),
      value: `${convertNumberFormat(polygonDetails?.area ?? 0)} ha`,
    },
    {
      icon: (
        <TagOutlined
          style={{
            transform: 'rotateY(180deg)',
            color: COLOR.PRIMARY,
          }}
          className="mr-half"
        />
      ),
      label: t('Type'),
      value: polygonDetails?.polygonTypeName,
    },
    {
      icon: (
        <ProjectOutlined
          style={{
            color: COLOR.PRIMARY,
          }}
          className="mr-half"
        />
      ),
      label: t('PlotWithData'),
      value: polygonDetails?.plotsCount,
    },
    {
      label: t('NeedToBeMeasured'),
      value: polygonDetails?.samplingPlots,
      hidden: polygonDetails?.samplingPlots === 0 || isGenPlot,
    },
  ];

  const onUpdate = async (values: IFormFields) => {
    const polygonsMap = drawRef?.current?.getDrawPolygon() || [];
    await upsertPolygon({
      polygonName: values.polygonName,
      polygonId,
      projectId,
      polygonTypeId: polygonDetails?.polygonTypeId,
      ...(isCAR2887Enabled
        ? { multiplePolygonWkt: getPolygonWktFromLatLng(polygonsMap) }
        : { multiplePolygonMap: getPolygonsMapToSendAPI(polygonsMap) }),
    });
    notification.success({ message: t('UpdatePolygonSuccessfully') });
    setIsEdit(false);
    drawRef?.current?.clear();
    getPolygonDetails(polygonId);
    getPolygons?.();
  };

  const onClose = () => {
    setIsEdit(false);
    setIsPlotMode(false);
    setIsGenPlot(false);
    drawRef?.current?.clear();
    setCurrentPolygonId(ID_DEFAULT);
    if (isNewPolygon) {
      setParams({
        zIndex: PROJECT_BOUNDARY_Z_INDEX,
        ...PAGING_OFFSET_ALL,
        projectId,
      });
      setStatusAfterMapChanges({ callAPI: false, isCenter: true });
    } else {
      setStatusAfterMapChanges({ callAPI: true, isCenter: false });
    }
  };

  const onEdit = () => {
    setIsEdit(true);
    setIsGenPlot(false);
    const initialPoints = initPoints(polygonDetails);
    drawRef?.current?.setInitialPolygon(initialPoints);
    drawRef?.current?.edit();
  };

  const onEditFarmland = () => {
    window.open(
      `/farmer-management/farm-land-management?farmlandId=${polygonId}&isEdit=true`,
      '_blank'
    );
  };

  useEffect(() => {
    setIsEdit(false);
    drawRef?.current?.clear();
    if (polygonId !== ID_DEFAULT) {
      setShowAddPolygon(false);
      getPolygonDetails(polygonId);
    }
  }, [polygonId]);

  useEffect(() => {
    form.setFieldValue('polygonName', polygonDetails?.polygonName);
    if (
      polygonDetails &&
      !polygons.find(polygon => polygon.polygonId === polygonDetails.polygonId)
    ) {
      setIsNewPolygon(true);
      setPolygons([polygonDetails]);
      setStatusAfterMapChanges({ callAPI: false, isCenter: true });
    }
  }, [polygonDetails]);

  return (
    <Drawer
      open={polygonId !== ID_DEFAULT}
      onClose={onClose}
      placement="left"
      getContainer={false}
      closeIcon
      width={280}
      zIndex={zIndexDrawer ?? 1050}
      mask={false}
      rootStyle={{ position: 'absolute' }}
      styles={{
        header: { padding: 4, border: 'none' },
        body: { paddingTop: 0 },
      }}
    >
      {gettingDetails ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : !polygonDetails ? (
        <Empty />
      ) : (
        <>
          <Form form={form} layout="horizontal" onFinish={onUpdate}>
            <Form.Item
              name="polygonName"
              className="text-center"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('NotBeEmpty'),
                },
              ]}
            >
              {isEdit ? (
                <Input disabled={upsertingPolygon} />
              ) : (
                <Title level={5}>
                  <span className="mr-half">{polygonDetails?.polygonName}</span>
                  {POLYGON_TYPES.CREMA.id !== polygonDetails?.polygonTypeId &&
                    canUpdateProject && (
                      <Button
                        type="text"
                        icon={<EditOutlined type="primary" />}
                        onClick={() => {
                          switch (polygonDetails.polygonTypeId) {
                            case POLYGON_TYPES.FARMLAND.id:
                              onEditFarmland();
                              break;
                            default:
                              onEdit();
                          }
                        }}
                      />
                    )}
                </Title>
              )}
            </Form.Item>
            {polygonsFields.map(
              field =>
                !field.hidden && (
                  <Flex
                    justify="space-between"
                    className="mb-half"
                    key={field.label}
                  >
                    <Text>
                      <Flex align="center">
                        {field.icon} {field.label}
                      </Flex>
                    </Text>
                    <Text>{field.value}</Text>
                  </Flex>
                )
            )}
            {isEdit && (
              <Alert
                showIcon
                type="info"
                message={t('ZoomInToShowDraggablePoint')}
              />
            )}
            {isEdit ? (
              <Flex justify="center" className="mt-base">
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={upsertingPolygon}
                >
                  {t('Save')}
                </Button>
              </Flex>
            ) : (
              !isGenPlot && (
                <>
                  <Flex justify="space-between" className="mb-half">
                    <Text>{t('GetPlot')} </Text>
                    <Switch
                      checked={isPlotMode}
                      onChange={(checked: boolean) => {
                        setIsPlotMode(checked);
                      }}
                    />
                  </Flex>
                  {polygonDetails.zIndex >= minZIndexGenPlots && (
                    <Flex justify="center" className="mt-base">
                      <Button type="primary" onClick={() => setIsGenPlot(true)}>
                        {t('GenerateSamplingPlot')}
                      </Button>
                    </Flex>
                  )}
                </>
              )
            )}
          </Form>
          {isGenPlot && (
            <>
              <div className="mb-half">{t('NeedToBeMeasured')}</div>
              <ProjectPlotMeasureForm
                suggestGeneratePlots={getDefaultGeneratePlotsValue(
                  polygonDetails.plotsCount || 0,
                  polygonDetails.area
                )}
                mutatePolygon={() => getPolygonDetails(polygonId)}
                samplingPlots={polygonDetails.samplingPlots}
                polygonId={polygonId}
                plotsWithData={polygonDetails.plotsCount || 0}
                isEditing={isGenPlot}
                onClose={() => setIsGenPlot(false)}
              />
            </>
          )}
        </>
      )}
    </Drawer>
  );
};

export { PolygonDetailDrawer };
