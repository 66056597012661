import { ILatLng, IPolygonMap } from 'interfaces';
import { degreesToRadians } from './math';
import { BASEMAP_LAYERS, EARTH_RADIUS_KM, VIEWBOX_SCALES } from 'constants/map';
import { IMapPolygon, LayerType } from 'components/shared/Map';
import { latLng, LatLng, LatLngTuple, Map } from 'leaflet';
import { GeoJSONPosition, parse, stringify } from 'wellknown';

export const generateDrawPointsMultiLayer = (
  polygonsPoints: ILatLng[][][]
): ILatLng[][][] => {
  return polygonsPoints.map(subPolygonMap =>
    subPolygonMap.map(polygonMapPoints => generateDrawPoints(polygonMapPoints))
  );
};

export const generateDrawPoints = (points: ILatLng[]) => {
  const realPoints = points.filter(p => !p.temporary);
  const length = realPoints.length;
  const lastIndex = length - 1;
  const tempPoints: ILatLng[] = [];

  for (let i = 0; i < lastIndex; i++) {
    tempPoints.push(realPoints[i]);
    tempPoints.push(
      new ILatLng(
        (realPoints[i].lat + realPoints[i + 1].lat) / 2,
        (realPoints[i].lng + realPoints[i + 1].lng) / 2
      )
    );
    tempPoints[tempPoints.length - 1].temporary = true;
  }
  tempPoints.push(realPoints[lastIndex]);

  tempPoints.push(
    new ILatLng(
      (realPoints[0].lat + realPoints[lastIndex].lat) / 2,
      (realPoints[0].lng + realPoints[lastIndex].lng) / 2
    )
  );
  tempPoints[tempPoints.length - 1].temporary = true;

  return tempPoints;
};

export const sphericalDistance = (coord1: ILatLng, coord2: ILatLng): number => {
  const dLat = degreesToRadians(coord2.lat - coord1.lng);
  const dLon = degreesToRadians(coord2.lng - coord1.lng);
  const lat1 = degreesToRadians(coord1.lat);
  const lat2 = degreesToRadians(coord2.lat);

  const haversine =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const angularDistance =
    2 * Math.atan2(Math.sqrt(haversine), Math.sqrt(1 - haversine));
  return EARTH_RADIUS_KM * angularDistance;
};

export const getPolygonArea = (coordinates: ILatLng[]): number => {
  let area = 0;
  const numCoords = coordinates.length;

  for (let i = 0; i < numCoords; i++) {
    const j = (i + 1) % numCoords;
    const coord1 = coordinates[i];
    const coord2 = coordinates[j];
    area +=
      degreesToRadians(coord2.lng - coord1.lng) *
      (2 +
        Math.sin(degreesToRadians(coord1.lat)) +
        Math.sin(degreesToRadians(coord2.lat)));
  }

  area = (area * EARTH_RADIUS_KM ** 2) / 2;
  return Math.abs(area) * 100;
};

export const getPolygonSignedArea = (coordinates: ILatLng[]): number => {
  let area = 0;
  const numCoords = coordinates.length;
  for (let i = 0; i < numCoords - 1; i++) {
    area +=
      coordinates[i].lat * coordinates[i + 1].lng -
      coordinates[i + 1].lat * coordinates[i].lng;
  }

  return area / 2;
};

export const getPolygonCentroid = (coordinates: ILatLng[]) => {
  // equation reference https://www.omnicalculator.com/math/centroid
  let centroidX = 0;
  let centroidY = 0;
  const numCoords = coordinates.length;
  const area = getPolygonSignedArea(coordinates);

  for (let i = 0; i < numCoords - 1; i++) {
    centroidX +=
      (coordinates[i].lat + coordinates[i + 1].lat) *
      (coordinates[i].lat * coordinates[i + 1].lng -
        coordinates[i + 1].lat * coordinates[i].lng);
    centroidY +=
      (coordinates[i].lng + coordinates[i + 1].lng) *
      (coordinates[i].lat * coordinates[i + 1].lng -
        coordinates[i + 1].lat * coordinates[i].lng);
  }
  centroidX = centroidX / (6 * area);
  centroidY = centroidY / (6 * area);
  return { lat: centroidX, lng: centroidY };
};

export const translateCentroidByZoomLevel = (zoomLevel: number) => {
  switch (zoomLevel) {
    case 15:
      return 0.06;
    case 16:
      return 0.035;
    case 17:
      return 0.02;
    case 18:
      return 0.01;
    case 19:
      return 0.0045;
    case 20:
      return 0.002;
    case 21:
      return 0.001;
    case 22:
      return 0.0003;
    case 23:
      return 0.0001;
    case 24:
      return 0.00008;
    case 25:
      return 0.00006;
    case 26:
      return 0.00004;
    case 27:
      return 0.00003;
    default:
      return 0;
  }
};

const getSearchBoxObjectFromMap = (map: Map) => {
  const mapBounds = map.getBounds();
  var currScale = VIEWBOX_SCALES[0];
  VIEWBOX_SCALES.forEach(scale => {
    if (map.getZoom() > scale.zoomLevel) currScale = scale;
  });
  return {
    searchLatitudeMin: mapBounds.getSouthWest().lat - currScale.offset,
    searchLatitudeMax: mapBounds.getNorthWest().lat + currScale.offset,
    searchLongitudeMin: mapBounds.getSouthWest().lng - currScale.offset,
    searchLongitudeMax: mapBounds.getSouthEast().lng + currScale.offset,
  };
};

// cause in leaflet the map you can drag infinity so you need check longtitude is valid
const isLngInvalid = (lngValue: number) => {
  return lngValue > 180 || lngValue < -180;
};

const getBaseMapLayerType = (selectedLayer: LayerType[]) =>
  BASEMAP_LAYERS.find(layer => selectedLayer.includes(layer));

const getPolygonPositions = (polygonMap: IPolygonMap[]): LatLngTuple[] =>
  polygonMap.map(position => [position.latitude, position.longitude]);

/**
 * @deprecated: Since CAR-2887
 */
const getPolygonsMapToSendAPI = (
  polygonsPoints: LatLng[][][] = []
): { polygonMap: IPolygonMap[] }[] => {
  return polygonsPoints.map(polygonPoints => ({
    polygonMap: polygonPoints?.[0].map(point => {
      return {
        latitude: point.lat,
        longitude: point.lng,
      };
    }),
  }));
};

// Use after CAR-2887 with hole
const getPolygonWktFromLatLng = (polygonsPoints: LatLng[][][] = []): string => {
  const geoJSONCoordinates = polygonsPoints.map(polygon =>
    polygon.map(
      ring => ring.map(({ lat, lng }) => [lng, lat]) // Convert LatLng to [lng, lat] as per GeoJSON spec
    )
  );
  return stringify({
    type: 'MultiPolygon',
    coordinates: geoJSONCoordinates as GeoJSONPosition[][][],
  });
};

const isValidLocation = (object: any) => {
  if (typeof object !== 'object' || object === null) {
    return false;
  }

  const { latitude, longitude } = object;
  if (typeof latitude !== 'number' || typeof longitude !== 'number') {
    return false;
  }

  return true;
};

const convertRadiusToPixel = (
  radius: number,
  map: Map,
  leafletContainer: React.RefObject<HTMLDivElement>
) => {
  const searchBox = getSearchBoxObjectFromMap(map);
  const leafletContainerWidth = leafletContainer.current?.offsetWidth || 1;
  const leafletContainerWidthDistance = sphericalDistance(
    {
      lat: searchBox.searchLatitudeMin,
      lng: searchBox.searchLongitudeMin,
    } as ILatLng,
    {
      lat: searchBox.searchLatitudeMin,
      lng: searchBox.searchLongitudeMax,
    } as ILatLng
  );

  const pixelRatio = leafletContainerWidthDistance / leafletContainerWidth;
  return (radius * 2 * Math.PI) / pixelRatio;
};

const convertCoordinatesToLatLng = (
  polygons: GeoJSONPosition[][][],
  sliceLastPoint: boolean
): ILatLng[][][] => {
  return polygons.map(polygon =>
    polygon.map(subPolygon =>
      (sliceLastPoint
        ? subPolygon.slice(0, -1)
        : subPolygon
      ).map(([lng, lat]) => latLng([lat, lng]))
    )
  );
};

const convertMktToArr = (
  multiplePolygonMkt: string,
  sliceLastPoint: boolean
) => {
  const polygons = parse(multiplePolygonMkt);
  if (polygons?.type === 'MultiPolygon' && polygons.coordinates.length)
    return convertCoordinatesToLatLng(polygons.coordinates, sliceLastPoint);
  if (polygons?.type === 'Polygon' && polygons.coordinates.length)
    return convertCoordinatesToLatLng([polygons.coordinates], sliceLastPoint);
  return [];
};

const initPoints = (
  polygon?: IMapPolygon,
  sliceLastPoint: boolean = true
): ILatLng[][][] => {
  if (!polygon) return [];
  let points: IPolygonMap[][] = [];
  const { multiplePolygonWkt, multiplePolygonMap, polygonMap = [] } = polygon;
  const normalizePolygonMap = polygonMap ?? [];
  const normalizeMultiPolygonMap = multiplePolygonMap ?? [];

  if (multiplePolygonWkt) {
    return convertMktToArr(multiplePolygonWkt, sliceLastPoint);
  }
  if (normalizeMultiPolygonMap.length) {
    points = normalizeMultiPolygonMap.map(({ polygonMap }) =>
      sliceLastPoint ? polygonMap.slice(0, -1) : polygonMap
    );
  } else {
    points = [
      sliceLastPoint ? normalizePolygonMap.slice(0, -1) : normalizePolygonMap,
    ];
  }

  return points.map(polygonPoints => [
    polygonPoints.map(point => latLng([point.latitude, point.longitude])),
  ]);
};

const flatPoints = (polygon: IMapPolygon) => {
  let listEdge: number[][] = [];
  const { multiplePolygonWkt, multiplePolygonMap, polygonMap = [] } = polygon;
  const normalizePolygonMap = polygonMap ?? [];
  const normalizeMultiPolygonMap = multiplePolygonMap ?? [];

  if (multiplePolygonWkt) {
    const polygons = parse(multiplePolygonWkt || '');

    if (polygons?.type === 'MultiPolygon' && polygons.coordinates.length)
      listEdge = polygons.coordinates.flat(2).map(([lng, lat]) => [lat, lng]);
    if (polygons?.type === 'Polygon' && polygons.coordinates.length)
      listEdge = polygons.coordinates.flat(1).map(([lng, lat]) => [lat, lng]);
  } else if (normalizeMultiPolygonMap.length) {
    listEdge = normalizeMultiPolygonMap
      .map(({ polygonMap }) => polygonMap)
      .flat(2)
      .map(({ latitude, longitude }) => [latitude, longitude]);
  } else {
    listEdge = normalizePolygonMap.map(({ latitude, longitude }) => [
      latitude,
      longitude,
    ]);
  }

  return listEdge;
};

export default {
  initPoints,
  flatPoints,
  getPolygonWktFromLatLng,
  sphericalDistance,
  getPolygonArea,
  getPolygonCentroid,
  translateCentroidByZoomLevel,
  getBaseMapLayerType,
  getSearchBoxObjectFromMap,
  isLngInvalid,
  getPolygonPositions,
  getPolygonsMapToSendAPI,
  isValidLocation,
  convertRadiusToPixel,
};
