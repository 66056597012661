import {
  DrawingPolygon,
  IMapPolygon,
  LayerType,
  LeafletMarkerIcon,
  MapPolygons,
} from 'components/shared/Map';
import { IGetPolygonTekboneParams } from 'interfaces';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Marker, Pane, Polygon, Tooltip, useMap } from 'react-leaflet';
import styled from 'styled-components';
import { CurrentSelectedFarm } from '../Context';
import {
  DEFAULT_z_INDEX_MARKER_ACTIVE,
  DEFAULT_z_INDEX_POLYGON,
  DrawState,
} from 'constants/map';
import { translateCentroidByZoomLevel } from 'helpers/map';
import { LatLngTuple } from 'leaflet';
import { polygonHooks } from 'hooks';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { PAGING_OFFSET_ALL } from 'constants/pagination';
import { POLYGON_TYPES } from 'constants/project';
import { PAGING_PAGE_SIZE_ALL } from 'constants/pagination';
import { farmServices } from 'services';
import MapContainerWrapper from 'components/shared/Map/MapContainerWrapper';
import { t } from 'helpers/i18n';
import Control from 'react-leaflet-custom-control';
import { SettingOutlined } from '@ant-design/icons';
import { Card, Checkbox, Flex, Popover, Typography } from 'antd';
import { convertNumberFormat } from 'helpers/common';
import { FARM_STATUS } from 'constants/farm';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFarmMap,
  selectInitFarmMapPolygon,
  setFarmMapPolygonInitialzed,
} from 'redux/features/farmMap';
import { AppDispatch } from 'redux/store';
import { mapHelpers } from 'helpers';

const LeafletContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
  .leaflet-container {
    width: 100%;
    height: 100%;
  }
  .leaflet-div-icon {
    background: none;
    border: none;
  }
  .leaflet-control-zoom {
    display: none;
  }
`;

enum BASE_TYPE {
  BOUNDARY = 'boundary',
  REWILDING_AREA = 'rewilding_area',
  DEFORESTED = 'deforested',
  AGROFORESTRY = 'agroforestry',
  WITHIN_FOREST_RESERVE = 'within_forest_reserve',
}

const NEARBY_FARM_COLOR = '#a83232';
const { flatPoints, initPoints } = mapHelpers;

const FarmMap: React.FC = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [selectedLayerTypes, setSelectedLayerTypes] = useState<LayerType[]>([
    'Default',
  ]);
  const {
    drawState,
    setDrawState,
    drawRef,
    currentSurveyLocation,
    listPlacemark,
    listFormSurvey,
    setShowFarmInfo,
    setSelectedSurveyForm,
    listNearbyFarm,
    overlapPolygon,
    setOverlapPolygon,
    setCurrentSurveyLocation,
  } = useContext(CurrentSelectedFarm);
  const [loadingOverlap, setLoadingOverlap] = useState(false);
  const [displayBaseTypes, setDisplayBaseTypes] = useState<BASE_TYPE[]>([
    BASE_TYPE.BOUNDARY,
    BASE_TYPE.REWILDING_AREA,
    BASE_TYPE.DEFORESTED,
    BASE_TYPE.AGROFORESTRY,
    BASE_TYPE.WITHIN_FOREST_RESERVE,
  ]);
  const { projectId } = useProjectContext();
  const { featureFlagsData } = useFlagsupContext();
  const currentSurveyLocationRef = useRef<any>(null);
  const polygon = useSelector(selectFarmMap);
  const initFarmMapPolygon = useSelector(selectInitFarmMapPolygon);
  const dispatch: AppDispatch = useDispatch();

  const isCAR1598Enabled = featureFlagsData.CAR_1598?.enabled;
  const isCAR2399Enabled = featureFlagsData.CAR_2399?.enabled;
  const isCAR2887Enabled = featureFlagsData.CAR_2887?.enabled;

  const params: IGetPolygonTekboneParams = useMemo(
    () => ({
      polygonTypeIds: [
        POLYGON_TYPES.PROJECT_BOUNDARY.id,
        POLYGON_TYPES.INTERVENTION_AREA.id,
        POLYGON_TYPES.DEFORESTED.id,
        POLYGON_TYPES.AGROFORESTRY.id,
        POLYGON_TYPES.WITHIN_FOREST_RESERVE.id,
      ],
      projectId,
      ...PAGING_OFFSET_ALL,
      limit: 5000,
    }),
    []
  );
  const { polygons } = polygonHooks.usePolygons(params);

  const polygonsWithKey = useMemo(() => {
    return polygons.reduce(
      (polygonTypes, polygon) => {
        const type = polygon.polygonTypeId;
        if (!polygonTypes[type]) {
          polygonTypes[type] = [];
        }
        polygonTypes[type].push({
          id: polygon.polygonId,
          polygonMap: polygon.polygonMap,
          multiplePolygonMap: polygon.multiplePolygonMap,
          multiplePolygonWkt: polygon.multiplePolygonWkt,
          color: polygon.hexColorCode,
          label: polygon.polygonName,
        });
        return polygonTypes;
      },
      {} as {
        [key: number]: IMapPolygon[];
      }
    );
  }, [polygons]);

  const nearbyFarmOverviewRenderer = (polygon: any) => {
    return (
      <Card className="tooltip-map" style={{ zIndex: 9999 }}>
        {polygon?.farmName && (
          <Typography>
            {t('FarmName')}: {polygon?.farmName}
          </Typography>
        )}
        {polygon?.farmerName && (
          <Typography>
            {t('FarmerName')}: {polygon?.farmerName}
          </Typography>
        )}
        {polygon?.status && (
          <Typography>
            {t('Status')}: {polygon?.status}
          </Typography>
        )}
        {polygon?.area && (
          <Typography>
            {t('Area')}: {convertNumberFormat(polygon?.area || 0)} Ha
          </Typography>
        )}
      </Card>
    );
  };

  const nearbyPolygon = useMemo(() => {
    if (!listNearbyFarm) return [];
    return listNearbyFarm
      ?.filter(p => p.status !== FARM_STATUS.INELIGIBLE)
      .map(nearbyFarm => {
        return {
          id: nearbyFarm.farmId,
          polygonMap: nearbyFarm.polygonMap,
          multiplePolygonWkt: nearbyFarm.multiplePolygonWkt,
          color: NEARBY_FARM_COLOR,
          status: nearbyFarm.status,
          farmName: nearbyFarm.farmName,
          farmerName: nearbyFarm.farmerName,
          area: nearbyFarm.area,
          polygonOverviewRenderer: nearbyFarmOverviewRenderer(nearbyFarm),
        };
      });
  }, [listNearbyFarm]);

  const polygonConfigs = [
    {
      baseType: BASE_TYPE.BOUNDARY,
      zIndex: DEFAULT_z_INDEX_POLYGON,
      polygons: polygonsWithKey[POLYGON_TYPES.PROJECT_BOUNDARY.id],
      label: 'Project Boundary',
    },
    {
      baseType: BASE_TYPE.REWILDING_AREA,
      zIndex: DEFAULT_z_INDEX_POLYGON + 1,
      polygons: polygonsWithKey[POLYGON_TYPES.INTERVENTION_AREA.id],
      label: 'Re-wilding Area',
    },
    {
      baseType: BASE_TYPE.DEFORESTED,
      zIndex: DEFAULT_z_INDEX_POLYGON + 2,
      polygons: polygonsWithKey[POLYGON_TYPES.DEFORESTED.id],
      label: 'Deforested',
    },
    {
      baseType: BASE_TYPE.AGROFORESTRY,
      zIndex: DEFAULT_z_INDEX_POLYGON + 3,
      polygons: polygonsWithKey[POLYGON_TYPES.AGROFORESTRY.id],
      label: 'Agroforestry',
    },
    {
      baseType: BASE_TYPE.WITHIN_FOREST_RESERVE,
      zIndex: DEFAULT_z_INDEX_POLYGON + 4,
      polygons: polygonsWithKey[POLYGON_TYPES.WITHIN_FOREST_RESERVE.id],
      label: 'Within Forest Reserve',
    },
  ];

  const overlappedFarms = useMemo(() => {
    return overlapPolygon
      .filter(
        p => p.farmId !== polygon?.id && p.status !== FARM_STATUS.INELIGIBLE
      )
      .map(polygon => {
        return {
          id: polygon.farmId,
          polygonMap: polygon.farmMap,
          multiplePolygonWkt: polygon.multiplePolygonWkt,
          color: NEARBY_FARM_COLOR,
          farmName: polygon.farmName,
          polygonOverviewRenderer: nearbyFarmOverviewRenderer(polygon),
        };
      });
  }, [overlapPolygon]);

  const listPlacemarkMap = useMemo(() => {
    return listPlacemark?.filter(
      placemark =>
        placemark.formSurveyId !== currentSurveyLocation?.formSurveyId
    );
  }, [listPlacemark, currentSurveyLocation?.formSurveyId]);

  const getOverlappedFarms = async () => {
    try {
      setLoadingOverlap(true);
      const params = {
        projectId,
        ...(polygon?.multiplePolygonWkt && isCAR2887Enabled
          ? {
              multiplePolygonWkt: polygon.multiplePolygonWkt,
            }
          : {
              farmMap: polygon?.polygonMap || [],
            }),
        ...PAGING_PAGE_SIZE_ALL,
      };
      const result = await farmServices.getOverlappedFarms(params);
      setOverlapPolygon(result.overlappedFarms);
    } finally {
      setLoadingOverlap(false);
    }
  };

  const FarmMapPolygon = () => {
    const map = useMap();

    useEffect(() => {
      if (polygon && initFarmMapPolygon) {
        const listEdge = flatPoints(polygon);
        if (!listEdge.length) return;

        const currentValue = translateCentroidByZoomLevel(
          map.getBoundsZoom(listEdge as LatLngTuple[])
        );
        const points = listEdge.map(([lat, lng]) => [lat, lng - currentValue]);
        if (!isCAR2399Enabled) {
          listEdge.push(...points);
        }
        map.fitBounds(listEdge as LatLngTuple[]);
        dispatch(setFarmMapPolygonInitialzed(false));
      }
    }, [polygon]);

    useEffect(() => {
      if (currentSurveyLocation) {
        map.setView([
          currentSurveyLocation.latitude,
          currentSurveyLocation.longitude,
        ]);
      }
    }, [currentSurveyLocation]);

    if (!polygon) return <></>;

    return (
      <Pane name="Farm" style={{ zIndex: DEFAULT_z_INDEX_POLYGON + 9 }}>
        <Polygon
          positions={initPoints(polygon, false)}
          color={polygon.color || 'rgba(0,0,0,0.4)'}
        />
      </Pane>
    );
  };

  const onCLickPlacemark = (id: number) => {
    const currentSelectedSurveyForm = listFormSurvey?.find(
      item => item.formSubmissionId === id
    );
    if (currentSelectedSurveyForm) {
      setShowFarmInfo(false);
      setSelectedSurveyForm(currentSelectedSurveyForm);
    }
  };

  useEffect(() => {
    if (polygon?.id && isCAR1598Enabled) {
      getOverlappedFarms();
    }
  }, [polygon?.id]);

  return (
    <LeafletContainer ref={mapRef} data-testid="leaflet-map">
      <MapContainerWrapper
        selectedLayerTypes={selectedLayerTypes}
        loading={false}
        boundPolygons={polygon ? [polygon] : []}
        allowTypes={['Default', 'Satellite', 'LandCover']}
        currentPolygonId={polygon?.id || -1}
        mapRef={mapRef}
        setSelectedLayerTypes={setSelectedLayerTypes}
        legendPlacement="right"
        isCenteredWhenPolygonChanges={false}
      >
        {currentSurveyLocation?.latitude && currentSurveyLocation?.longitude && (
          <Marker
            position={[
              currentSurveyLocation.latitude,
              currentSurveyLocation.longitude,
            ]}
            draggable={currentSurveyLocation?.draggable}
            eventHandlers={{
              dragend: () => {
                const location = currentSurveyLocationRef?.current.getLatLng();
                setCurrentSurveyLocation(prev => ({
                  ...prev,
                  latitude: location.lat,
                  longitude: location.lng,
                }));
              },
            }}
            icon={LeafletMarkerIcon.ACTIVE}
            title={`${t('Placemark')} ${currentSurveyLocation.formSurveyId}`}
            ref={currentSurveyLocationRef}
            zIndexOffset={DEFAULT_z_INDEX_MARKER_ACTIVE}
          >
            <Tooltip
              opacity={1}
              permanent
              offset={[10, -6]}
              className="marker-active-tooltip"
            >
              {t('Placemark')} {currentSurveyLocation.formSurveyId}
            </Tooltip>
          </Marker>
        )}

        {Array.isArray(listPlacemarkMap) &&
          listPlacemarkMap.length > 0 &&
          listPlacemarkMap.map(placemark => (
            <React.Fragment key={placemark.formSurveyId}>
              {placemark.latitude && placemark.longitude && (
                <Marker
                  position={[placemark.latitude, placemark.longitude]}
                  title={`${t('Placemark')} ${placemark.formSurveyId}`}
                  icon={LeafletMarkerIcon.DEFAULT.NO_DATA}
                  eventHandlers={{
                    click: () => {
                      onCLickPlacemark(placemark.formSurveyId);
                    },
                  }}
                >
                  <Tooltip opacity={1} permanent offset={[10, -6]}>
                    {t('Placemark')} {placemark.formSurveyId}
                  </Tooltip>
                </Marker>
              )}
            </React.Fragment>
          ))}

        {polygonConfigs.map(config => {
          return displayBaseTypes.includes(config.baseType) ? (
            <Pane
              key={config.baseType}
              name={config.baseType}
              style={{ zIndex: config.zIndex }}
            >
              {!!config.polygons?.length && (
                <MapPolygons polygons={config.polygons} />
              )}
            </Pane>
          ) : null;
        })}

        {/* Overlapped Farms  */}
        <Pane name="Overlapped" style={{ zIndex: DEFAULT_z_INDEX_POLYGON }}>
          {overlappedFarms.length > 0 && (
            <MapPolygons
              polygons={overlappedFarms}
              showPolygonOverviewOnHover={overlappedFarms.length > 0}
              fillColor={false}
            />
          )}
        </Pane>

        {/* Nearby Farms  */}
        <Pane name="Nearby" style={{ zIndex: DEFAULT_z_INDEX_POLYGON }}>
          {nearbyPolygon.length > 0 && (
            <MapPolygons
              polygons={nearbyPolygon}
              showPolygonOverviewOnHover={nearbyPolygon.length > 0}
              fillColor={false}
            />
          )}
        </Pane>

        {/* Control display polygons */}
        <Control position="topright" prepend>
          <Popover
            placement="leftTop"
            trigger="click"
            content={
              <Checkbox.Group
                value={displayBaseTypes}
                onChange={(checkedValues: BASE_TYPE[]) =>
                  setDisplayBaseTypes(checkedValues)
                }
              >
                <Flex vertical>
                  {polygonConfigs.map(config => {
                    return (
                      <Checkbox
                        key={config.baseType}
                        value={config.baseType}
                        className="p-half"
                      >
                        {config.label}
                      </Checkbox>
                    );
                  })}
                </Flex>
              </Checkbox.Group>
            }
          >
            <div className="display-type-control">
              <SettingOutlined style={{ fontSize: 20 }} />
            </div>
          </Popover>
        </Control>
        {drawState === DrawState.EDIT || drawState === DrawState.DRAW ? (
          <DrawingPolygon
            drawRef={drawRef}
            initPoints={initPoints(polygon)}
            drawState={drawState}
            setDrawState={setDrawState}
            checkOverlap={isCAR1598Enabled}
            loadingOverlap={loadingOverlap}
            onCheckOverlap={getOverlappedFarms}
            overlappedPolygons={overlappedFarms}
          />
        ) : (
          polygon && polygon?.id !== -1 && <FarmMapPolygon />
        )}
      </MapContainerWrapper>
    </LeafletContainer>
  );
};

export default FarmMap;
