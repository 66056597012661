import { DEFAULT_VISIBLE_NUMBER } from 'constants/common';

export const formatStringNumber = (number: number) => {
  return number.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
};

export const maskPhoneNumber = (
  phoneNumber: string,
  visibleNumber: number = DEFAULT_VISIBLE_NUMBER
) => {
  if (phoneNumber.length <= visibleNumber) {
    return phoneNumber;
  }
  const masked = phoneNumber.slice(-visibleNumber);
  return '*'.repeat(phoneNumber.length - visibleNumber) + masked;
};

export default {
  formatStringNumber,
  maskPhoneNumber,
};
