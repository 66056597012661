import { notification } from 'antd';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { t } from 'helpers/i18n';
import { userServices } from 'services';
import trackError from './tracker/trackError';
import { HIDE_ERROR_URLS } from 'constants/request';
import { IMap } from 'interfaces';

const { trackApiError } = trackError;

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  customHandlerErrorCodes?: number[];
}

const handleResponseError = (error: AxiosError) => {
  const status = error && error.response && error.response.status;
  switch (status) {
    case 401:
      userServices.logout();
      return;
    case 403:
      userServices.denyAccess();
      return;
    default:
      if (axios.isCancel({ ...error })) break; // ignore cancel request token

      // Bypass default error handling for specific error codes
      const requestConfig = error.config as CustomAxiosRequestConfig;
      const responseData = error?.response?.data as { code: number };
      const customCode = responseData.code;
      if (requestConfig?.customHandlerErrorCodes?.includes(customCode)) {
        return;
      }

      // Default error handling
      let message: any = null;
      const response = error.response;

      if (
        response &&
        response?.data // This is just work around for axios.isCancel method. Refereence: https://github.com/axios/axios/issues/5153
      ) {
        message = response?.data;
      }
      const { config } = (response || {}) as AxiosResponse;
      const { url } = config || {};
      const hideRequest = HIDE_ERROR_URLS.find(hideUrl => {
        const pattern = hideUrl;

        // Convert the dynamic segments (e.g., ":id") to a regex pattern
        const regexPattern = new RegExp(
          `^${pattern.replace(/:[^/]+/g, '[^/]+')}$`
        );

        // Check if the URL matches the pattern
        return regexPattern.test(url || '');
      });

      if (!hideRequest)
        notification.error({
          message: t('Error'),
          description:
            typeof message === 'object'
              ? message?.message
              : message || t('SomethingWentWrong'),
        });
      break;
  }
  trackApiError(error);
};

export const getData = (response: any) => response?.data.data;
export const getResult = (response: any) => response?.data;
export const getResultAndHeaders = (response: any) => ({
  result: response?.data,
  headers: response?.headers,
});

export const encodeQueryData = (data: IMap<any>) => {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
};

export const setCustomHandlerErrorCodes = (
  instance: AxiosInstance,
  customHandlerErrorCodes: CustomAxiosRequestConfig['customHandlerErrorCodes']
) => {
  instance.interceptors.request.use(config => ({
    ...config,
    customHandlerErrorCodes,
  }));
};

export default {
  handleResponseError,
  getData,
  getResult,
  getResultAndHeaders,
  setCustomHandlerErrorCodes,
};
