import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Drawer,
  DrawerProps,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  notification,
} from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import CECCandidatesSelect from 'components/shared/Select/CECCandidatesSelect';
import { CEC_ROLES } from 'constants/community';
import { PAGINATION_TOTAL_DEFAULT } from 'constants/pagination';
import { ProjectContext, useFlagsupContext } from 'contexts';
import { filterSelect } from 'helpers/common';
import paginationHelpers from 'helpers/pagination';
import { userHooks } from 'hooks';
import {
  ICECMember,
  IPaginationParams,
  IPaginationResponse,
  IUpsertCrmcMember,
} from 'interfaces';
import { get } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cremaServices } from 'services';

const { generateRowIndex } = paginationHelpers;
const { useUserPermissions } = userHooks;

interface ICECMemberProps extends Pick<DrawerProps, 'open' | 'onClose'> {
  cremaId: number;
  getCremaDetail: () => void;
}

interface IAddCECMemberForm extends IUpsertCrmcMember {
  roleName: string;
  communityName: string;
  memberName: string;
}

const CECMember: React.FC<ICECMemberProps> = props => {
  const { t } = useTranslation(window.appConfig?.appName);
  const { projectId } = useContext(ProjectContext);

  const { cremaId, getCremaDetail, open, onClose } = props;
  const [form] = Form.useForm<IAddCECMemberForm>();
  const [loading, setLoading] = useState(false);
  const [CECMembers, setCECMembers] = useState<ICECMember[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>(
    PAGINATION_TOTAL_DEFAULT
  );

  const { featureFlagsData } = useFlagsupContext();
  const isCAR1514Enabled = featureFlagsData.CAR_1514?.enabled;

  const { canCreateCECMember, canDeleteCECMember } = useUserPermissions();

  const columns: ColumnsType<ICECMember> = [
    {
      title: '#',
      render: (_, record, index) =>
        generateRowIndex(pagination.page, pagination.pageSize, index),
      width: 60,
      align: 'center',
    },
    {
      title: t('Member'),
      dataIndex: 'memberName',
      width: 140,
    },
    {
      title: t('Community'),
      dataIndex: 'communityName',
      width: 140,
    },
    {
      title: t('Role'),
      dataIndex: 'userRoleName',
    },
    {
      title: t('Action'),
      hidden: !canDeleteCECMember,
      render: member => (
        <div className="text-center">
          <Popconfirm
            title=""
            description={t('AreYouSureToDeleteThisMember')}
            onConfirm={() => deleteCECMember(member)}
            okText={t('Yes')}
            cancelText={t('No')}
            rootClassName="crema-popconfirm"
          >
            <Button type="text" danger icon={<DeleteOutlined size={20} />} />
          </Popconfirm>
        </div>
      ),
      align: 'center',
      width: 60,
    },
  ];

  const deleteCECMember = async (member: ICECMember) => {
    try {
      setLoading(true);

      isCAR1514Enabled
        ? await cremaServices.deleteCECMember({
            projectId,
            cremaId,
            memberId: member.memberId,
          })
        : await cremaServices.upsertCremasCECMembers({
            projectId,
            cremaId,
            members: [
              {
                memberId: member.memberId,
                isActive: false,
              },
            ],
          });

      notification.success({
        message: t('DeleteMemberSuccessfully'),
      });
      getCECMembers();
      getCremaDetail();
    } finally {
      setLoading(false);
    }
  };

  const addCECMember = async (values: IAddCECMemberForm) => {
    const member = {
      ...values,
      memberRoleName:
        values.memberRoleName === 'Other'
          ? values.roleName
          : values.memberRoleName,
    };
    try {
      setLoading(true);
      await cremaServices.upsertCremasCECMembers({
        projectId,
        cremaId,
        members: [member],
      });
      notification.success({
        message: t('AddMemberSuccessfully'),
      });
      form.resetFields();
      getCECMembers();
      getCremaDetail();
    } finally {
      setLoading(false);
    }
  };

  const close = (e: any) => {
    onClose?.(e);
    setLoading(false);
    form.resetFields();
  };

  const getCECMembers = async (curPagination?: IPaginationParams) => {
    try {
      setLoading(true);
      const page = curPagination?.page || PAGINATION_TOTAL_DEFAULT.page;
      const {
        cecMembers: cecMembersRes,
        pagination: paginationRes,
      } = await cremaServices.getCremaCecMembers({
        page,
        pageSize: curPagination?.pageSize || PAGINATION_TOTAL_DEFAULT.pageSize,
        cremaId,
        projectId,
      });
      setCECMembers(cecMembersRes || []);
      setPagination(paginationRes || PAGINATION_TOTAL_DEFAULT);
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = (newPagination: TablePaginationConfig) => {
    getCECMembers({
      page: newPagination.current || pagination.page,
      pageSize: newPagination.pageSize || pagination.pageSize,
    });
  };

  useEffect(() => {
    getCECMembers();
  }, [cremaId]);

  return (
    <Drawer
      placement="right"
      width={500}
      getContainer={false}
      open={open}
      zIndex={1005}
      maskClosable={false}
      title={t('CECMember')}
      styles={{ header: { textAlign: 'center', borderBottom: 'none' } }}
      onClose={close}
    >
      {canCreateCECMember && (
        <Form
          className="mb-base"
          form={form}
          layout="vertical"
          onFinish={addCECMember}
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="memberId"
                label={t('Member')}
                rules={[{ required: true }]}
              >
                <CECCandidatesSelect
                  cremaId={cremaId}
                  onSelect={(value, option) => {
                    const data = get(option, 'data', {});
                    form.setFieldsValue({
                      communityName: data.candidateCommunityName,
                      memberName: data.candidateName,
                    });
                  }}
                  CECMembers={CECMembers}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="memberRoleName"
                label={t('Role')}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  filterOption={filterSelect}
                  placeholder={t('SelectRole')}
                  options={CEC_ROLES}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item noStyle dependencies={['memberRoleName']}>
            {() =>
              form.getFieldValue('memberRoleName') === 'Other' ? (
                <Form.Item
                  name="roleName"
                  label={t('RoleName')}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('EnterRole')} />
                </Form.Item>
              ) : (
                <></>
              )
            }
          </Form.Item>
          {/* Hidden to save information */}
          <Form.Item name="communityName" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="memberName" hidden>
            <Input />
          </Form.Item>
          <Row gutter={8} justify="center">
            <Col span={12} className="text-center">
              <Button
                type="primary"
                htmlType="submit"
                className="w-120px"
                loading={loading}
              >
                {t('Save')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}

      <div>
        <span>
          {t('TotalRecords')}: {pagination.total}
        </span>
        <Table
          size="small"
          bordered
          rowKey="memberId"
          loading={loading}
          columns={columns}
          dataSource={CECMembers}
          pagination={{
            ...pagination,
            style: { gap: '10px' },
            showSizeChanger: true,
          }}
          onChange={handlePagination}
          className="mt-base"
        />
      </div>
    </Drawer>
  );
};

export default CECMember;
