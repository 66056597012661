import { LatLng, Point, icon } from 'leaflet';
import React, { Fragment, useEffect, useState } from 'react';
import { Marker, Polygon, Polyline, useMapEvents } from 'react-leaflet';
import circle from 'assets/images/circle.png';
import { IDrawPolygonRef, ILatLng } from 'interfaces';
import { generateDrawPoints, generateDrawPointsMultiLayer } from 'helpers/map';
import { DrawState } from 'constants/map';
import OverlapWarning from './OverlapWarning';
import { commonHooks } from 'hooks';
import { Spin } from 'antd';
import { IMapPolygon, MapPolygons } from '../../MapPolygons';

interface IDrawingPolygon {
  drawRef?: React.MutableRefObject<IDrawPolygonRef>;
  initPoints?: ILatLng[][][];
  drawState?: DrawState;
  setDrawState?: React.Dispatch<React.SetStateAction<DrawState>>;
  checkOverlap?: boolean;
  currentPolygonId?: number;
  onCheckOverlap?: () => void;
  loadingOverlap?: boolean;
  overlappedPolygons?: IMapPolygon[];
  limitRender?: boolean;
}

const { useDebounceEffect } = commonHooks;
const LIMIT_NODE = 500;

const DrawingPolygon: React.FC<IDrawingPolygon> = ({
  drawRef,
  initPoints,
  drawState,
  setDrawState,
  checkOverlap = false,
  onCheckOverlap,
  loadingOverlap,
  overlappedPolygons,
  limitRender,
}) => {
  const [polygonsPoints, setPolygonsPoints] = useState<ILatLng[][][]>(
    initPoints?.length ? generateDrawPointsMultiLayer(initPoints) : []
  );
  const [pointerPosition, setPointerPosition] = useState<LatLng | null>(null);
  const [showingPolygonsPoints, setShowingPolygonsPoints] = useState<
    ILatLng[][][]
  >([]);

  useEffect(() => {
    if (drawRef?.current) {
      drawRef.current = {
        ...drawRef.current,
        setInitialPolygon: (
          initPolygon?: LatLng[][][],
          drawState?: DrawState
        ) => {
          setPolygonsPoints?.(
            initPolygon?.length ? generateDrawPointsMultiLayer(initPolygon) : []
          );
          setDrawState?.(
            initPolygon?.length ? drawState || DrawState.EDIT : DrawState.DRAW
          );
        },
        stopDrawing: () => {
          setDrawState?.(DrawState.NONE);
        },
        clear: () => {
          setPolygonsPoints([]);
          setShowingPolygonsPoints([]);
          setDrawState?.(DrawState.NONE);
        },
        edit: () => {
          setDrawState?.(DrawState.EDIT);
        },
        setPolygonViewOnly: () => {
          setDrawState?.(DrawState.VIEW_ONLY);
        },
      };
    }
  }, []);

  useEffect(() => {
    if (drawRef?.current) {
      drawRef.current.getDrawPolygon = () => {
        return drawState === DrawState.EDIT
          ? polygonsPoints.map(subPolygonPoints =>
              subPolygonPoints.map(polygonPoints => {
                const points = polygonPoints.filter(p => !p.temporary);
                return [...points, points[0]];
              })
            )
          : [];
      };
    }
  }, [polygonsPoints]);

  useEffect(() => {
    if (drawState === DrawState.NONE) {
      setPolygonsPoints([]);
    }
  }, [drawState]);

  const map = useMapEvents({
    click: e => {
      if (drawState === DrawState.DRAW) {
        // Currently only supports drawing 1 polygon without hole => format: [[[number, number]...]]
        setPolygonsPoints?.(prev =>
          prev[0] ? [[[...prev[0][0], e.latlng]]] : [[[e.latlng]]]
        );
      }
    },
    mousemove: e => {
      if (drawState === DrawState.DRAW) {
        setPointerPosition(e.latlng);
      }
    },
    moveend: () => {
      // ignore optimize logic
      if (!limitRender) return;
      // keep all node when dragging
      if (drawState === DrawState.DRAW) return;
      // no render when zoom level too low, the map too large
      const zoomLevel = map.getZoom();
      if (zoomLevel < 10) {
        setShowingPolygonsPoints([]);
        return;
      }
      // filter all node outside map
      let totalInBoundsPoints = 0;
      const bounds = map.getBounds();
      const north = bounds.getNorth();
      const south = bounds.getSouth();
      const east = bounds.getEast();
      const west = bounds.getWest();
      const showPolygonsPoints = polygonsPoints.map(subPolygonPoints =>
        subPolygonPoints.map(polygonPoints => {
          const filtedPolygon = polygonPoints.filter((p, index) => {
            p.originalIndex = index;
            if (p.lat > north || p.lat < south || p.lng > east || p.lng < west)
              return false;
            return true;
          });
          totalInBoundsPoints += filtedPolygon.length;
          return filtedPolygon;
        })
      );
      // if too much node still inside map => do not render
      if (totalInBoundsPoints <= LIMIT_NODE)
        setShowingPolygonsPoints(showPolygonsPoints);
      else setShowingPolygonsPoints([]);
    },
  });

  useDebounceEffect(
    () => {
      if (drawState === DrawState.EDIT && checkOverlap) {
        onCheckOverlap?.();
      }
    },
    500,
    [polygonsPoints],
    true
  );

  return (
    <>
      <div ref={drawRef as any} />
      {loadingOverlap && (
        <Spin
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1999,
          }}
        />
      )}
      {(!limitRender || drawState === DrawState.DRAW
        ? polygonsPoints
        : showingPolygonsPoints
      ).map(
        (polygon, polygonIndex) =>
          drawState !== DrawState.VIEW_ONLY &&
          polygon.map((subPolygon, subPolygonIndex) =>
            subPolygon?.map((p, pointIndex) => (
              <Marker
                key={pointIndex}
                position={p}
                icon={icon({
                  iconUrl: circle,
                  iconSize: new Point(10, 10),
                  className: p.temporary ? 'opacity-3' : undefined,
                })}
                draggable
                eventHandlers={{
                  click: () => {
                    if (drawState === DrawState.EDIT || pointIndex !== 0)
                      return;
                    if (subPolygon.length > 2) {
                      setDrawState?.(DrawState.EDIT);
                      setPolygonsPoints(polygonsPoints => {
                        const newPolygonsPoints = [...polygonsPoints];
                        newPolygonsPoints[polygonIndex][
                          subPolygonIndex
                        ] = generateDrawPoints(
                          polygonsPoints[polygonIndex][subPolygonIndex]
                        );
                        return newPolygonsPoints;
                      });
                    }
                  },
                  dragend: e => {
                    setPolygonsPoints(polygonsPoints => {
                      const newPolygonsPoints = [...polygonsPoints];
                      // use optimize
                      if (Number.isInteger(p.originalIndex))
                        polygonsPoints[polygonIndex][subPolygonIndex][
                          p.originalIndex!
                        ] = new ILatLng(
                          e.target._latlng.lat,
                          e.target._latlng.lng
                        );
                      // otherwise
                      else
                        polygonsPoints[polygonIndex][subPolygonIndex][
                          pointIndex
                        ] = new ILatLng(
                          e.target._latlng.lat,
                          e.target._latlng.lng
                        );
                      newPolygonsPoints[polygonIndex][
                        subPolygonIndex
                      ] = generateDrawPoints(
                        polygonsPoints[polygonIndex][subPolygonIndex]
                      );
                      return newPolygonsPoints;
                    });
                  },
                }}
              />
            ))
          )
      )}
      {polygonsPoints.map((polygonPoints, polygonIndex) => {
        return (
          <>
            {polygonPoints.map((subPolygonPoints, subPolygonIndex) => (
              <Fragment key={`${polygonIndex}-${subPolygonIndex}`}>
                {subPolygonPoints?.length > 1 && (
                  <Polyline
                    positions={
                      drawState === DrawState.DRAW
                        ? subPolygonPoints
                        : [...subPolygonPoints, subPolygonPoints[0]]
                    }
                  />
                )}
                {pointerPosition &&
                  subPolygonPoints.length > 0 &&
                  drawState === DrawState.DRAW && (
                    <>
                      <Polyline
                        positions={[
                          subPolygonPoints[subPolygonPoints.length - 1],
                          pointerPosition,
                        ]}
                        dashArray="5, 5"
                      />
                    </>
                  )}
              </Fragment>
            ))}
            {[DrawState.EDIT, DrawState.VIEW_ONLY].includes(
              drawState as DrawState
            ) && <Polygon positions={[...polygonPoints]} />}
          </>
        );
      })}
      {drawState === DrawState.EDIT && checkOverlap && overlappedPolygons && (
        <MapPolygons polygons={overlappedPolygons} />
      )}
      <OverlapWarning
        showWarning={(overlappedPolygons ?? []).length > 0 && checkOverlap}
      />
    </>
  );
};

export default DrawingPolygon;
