import {
  Alert,
  Button,
  Card,
  Empty,
  Form,
  notification,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography,
} from 'antd';
import { ID_DEFAULT } from 'constants/common';
import { DEFAULT_PAGE } from 'constants/pagination';
import { useFlagsupContext, useProjectContext } from 'contexts';
import dayjs from 'dayjs';
import { fileHelpers, formHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { userHooks } from 'hooks';
import {
  FormSubmissionStatus,
  IFormAnswer,
  IFormSubmission,
  IFPicFormSubmissionsGetParams,
  IFPicFormSubmissionUpsertBody,
} from 'interfaces';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { STATUS_COLOR } from '../constants';
import { useFpicContext } from '../context';
import TemplateSelect from '../shared/TemplateSelect';
import './Detail.scss';
import {
  Camera,
  Checkbox,
  CustomizableDropdown,
  Datagrid,
  DatePicker,
  DocFile,
  Dropdown,
  ImageFile,
  InputText,
  Location,
  MediaFile,
  Numberic,
  Password,
  Signature,
} from './FormItem';
import { BaseItemProps, Mode } from './FormItem/BaseItem';
import { useFormSubmission } from './hooks';
import {
  DATE_REVERSE_DASH_FORMAT,
  DATE_REVERSE_TIME_WS_DASH_FORMAT,
} from 'constants/dateTime';

/** Interfaces, enum... * */
export interface DetailProps {
  formSubmissionId?: number;
  mode: Mode;
  currentFormSubmission?: IFormSubmission;
  className?: string;
  updateFilter?: (params: Partial<IFPicFormSubmissionsGetParams>) => void;
  referenceId?: number;
  callbackAfterSubmit?: () => void;
  noRedirectAfterCreate?: boolean;
  useTekbone?: boolean;
  title?: string;
}

/** Variables * */
const { useUserPermissions } = userHooks;
const { Title } = Typography;
const { applyGoogleImageSizing } = fileHelpers;

/** ------------------------- * */
const Detail: React.FC<DetailProps> = ({
  formSubmissionId,
  mode,
  currentFormSubmission,
  className,
  updateFilter,
  referenceId,
  callbackAfterSubmit,
  noRedirectAfterCreate,
  useTekbone,
  title,
}) => {
  /** States * */
  const [form] = Form.useForm();
  const [loadingButtonIndex, setLoadingButtonIndex] = useState<
    FormSubmissionStatus | undefined
  >();
  const analyzedDemandQuestionIds =
    window.config.analyzedDemandQuestionIds || {};

  /** Hooks * */
  const history = useHistory();
  const { search } = useLocation();
  const { projectId } = useProjectContext();
  const query = queryString.parse(search) as any;
  const {
    filling,
    loading,
    fetching,
    formSubmission = currentFormSubmission,
    upsertForm,
    autoFillForm,
  } = useFormSubmission(
    projectId,
    formSubmissionId,
    !currentFormSubmission,
    useTekbone
  );
  const { setTitle, templates, setTemplate, template } = useFpicContext();
  const {
    canUpdateMeetingRecords,
    canApproveMeetingRecords,
  } = useUserPermissions();

  /** Variables * */
  const isCreate = mode === 'CREATE';
  const color: any = formSubmission?.status
    ? STATUS_COLOR[formSubmission.status]
    : {};
  const allowFullActions = canApproveMeetingRecords;
  const allowCommonAction = canUpdateMeetingRecords || allowFullActions;
  const activeAutoFill =
    formSubmission?.autoFillStatus &&
    formSubmission.autoFillStatus === 'NOT_PROCESS_YET';
  const isShowButtonAutoFill =
    !!formSubmission?.autoFillStatus &&
    formSubmission.autoFillStatus !== 'NOT_ALLOW' &&
    formSubmission.autoFillStatus !== 'COMPLETED';
  const isAutoFillProcessing = formSubmission?.autoFillStatus === 'PROCESSING';
  const disabled =
    isAutoFillProcessing ||
    (!isCreate &&
      (!allowCommonAction || formSubmission?.status === 'APPROVED')) ||
    (!canUpdateMeetingRecords && !canApproveMeetingRecords);
  const dynamicFields =
    (isCreate ? template?.questions : formSubmission?.answers) || [];
  const buttonDisabled = loading || isAutoFillProcessing;
  const templateFormId =
    formSubmission?.templateFormId ?? template?.templateFormId ?? ID_DEFAULT;

  const { featureFlagsData } = useFlagsupContext();
  const isCarsa919Enable = featureFlagsData.CARSA_919?.enabled;

  /** Effects * */
  useEffect(() => {
    try {
      if (formSubmission) {
        if (query?.position === 'comment') {
          setTimeout(() => {
            scrollToBottom();
          }, 0);
        }

        setTitle?.(
          <Space size={'small'}>
            {!isCreate && (
              <Tag style={{ margin: 0 }}>
                ID: {formSubmission?.formSubmissionId}
              </Tag>
            )}
            {!!formSubmission?.meetingDate && (
              <Tag
                color="processing"
                className={'fpic-item-meeting-date'}
                style={{ margin: 0 }}
              >
                {dayjs
                  .unix(formSubmission.meetingDate)
                  .format('DD/MM/YYYY HH:mm:ss')}
              </Tag>
            )}
            {!!formSubmission?.status && (
              <Tag color={color?.tag} style={{ margin: 0 }}>
                {t(formSubmission.status)}
              </Tag>
            )}
          </Space>
        );

        if (templates?.length) {
          const temp = templates.find(
            item => item.templateFormId === formSubmission?.templateFormId
          );

          if (
            temp?.templateFormId &&
            (!query.template || Number(query.template) !== temp.templateFormId)
          ) {
            setTemplate?.(temp);
          }
        }
      }
    } catch (e) {}
  }, [formSubmission]);

  /** Functions, Events, Actions... * */
  const scrollToBottom = () => {
    if (isCreate) return;

    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onSubmit = async (status: FormSubmissionStatus) => {
    setLoadingButtonIndex(status);
    let analyzedDemand: string[] = [];
    try {
      const values = await form.validateFields();
      const answers = dynamicFields.reduce((body, answer) => {
        if (answer.fieldName) {
          const currentValue = values[answer.fieldName];

          let formatValue = currentValue;
          try {
            if (currentValue) {
              // analyzedDemandQuestionIds type = checkbox
              Object.entries(analyzedDemandQuestionIds).forEach(
                ([demand, ids]) => {
                  if (
                    ids?.includes(answer.questionId) &&
                    currentValue?.length
                  ) {
                    analyzedDemand.push(demand);
                  }
                }
              );
              switch (answer.fieldType) {
                case 'TimePicker':
                case 'TIME_PICKER':
                  formatValue = currentValue.isValid()
                    ? currentValue.unix().toString()
                    : '';
                  break;
                case 'DatePicker':
                case 'DATE_PICKER':
                  formatValue = currentValue.isValid()
                    ? dayjs(
                        `${currentValue.format(
                          DATE_REVERSE_DASH_FORMAT
                        )} 00:00`,
                        DATE_REVERSE_TIME_WS_DASH_FORMAT
                      )
                        .utc()
                        .unix()
                        .toString()
                    : '';
                  break;
                case 'Checkbox':
                case 'CHECKBOX':
                  formatValue = JSON.stringify(currentValue);
                  break;
                case 'CustomizableCheckbox':
                case 'CUSTOMIZABLE_CHECKBOX':
                  formatValue = JSON.stringify(
                    formHelpers.getCustomizableCheckboxValue(
                      values,
                      answer.fieldName
                    )
                  );
                  break;
                default:
                  break;
              }
            }
          } catch (e) {
          } finally {
            const specialValue =
              [
                'Datagrid',
                'DATAGRID',
                'MediaFile',
                'ImageFile',
                'IMAGE_FILE',
              ].includes(answer.fieldType) && !formatValue
                ? JSON.stringify([])
                : formatValue;

            body.push({
              questionId: answer.questionId,
              value: specialValue || '',
            });
          }
        }

        return body;
      }, [] as any);

      const body: IFPicFormSubmissionUpsertBody = {
        formSubmissionId: formSubmission?.formSubmissionId,
        templateFormId,
        status,
        answers,
        analyzedDemand,
        projectId,
        referenceId,
      };

      const result = await upsertForm(body);

      if (result) {
        notification.success({
          message:
            status === 'DRAFT'
              ? t('UpdateSuccessful')
              : t('StatusChangeSuccessful'),
        });

        if (isCreate) {
          if (!noRedirectAfterCreate) {
            const url = history.location.pathname.replace(
              /(\/.+)\/.+$/gi,
              '$1'
            );
            history.push(url);
          }
        }
        callbackAfterSubmit?.();
      }
      if (formSubmission?.status !== status) {
        updateFilter?.({ page: DEFAULT_PAGE });
      }
    } catch (e) {
      const errorFields = (e as any).errorFields || [];

      const description: {
        label: string;
        message: string;
      }[] = errorFields.reduce((error: any, field: any) => {
        if (field) {
          const answer = dynamicFields.find(
            (answer: IFormAnswer) => answer.fieldName === field.name[0]
          );
          if (answer?.fieldLabel) {
            error.push({
              label: answer.fieldLabel,
              message: field.errors[0],
            });
          }
        }

        return error;
      }, [] as { label: string; message: string }[]);

      notification.error({
        message: t('ThereAreIncorrectInformationFields'),
        description: description.map(error => (
          <p>
            <b>{error.label}</b> {error.message}
          </p>
        )),
      });
    }
  };

  const onAutoFillForm = async () => {
    if (!formSubmissionId) return;

    const state = await autoFillForm(formSubmissionId);

    if (state) {
      notification.success({
        message: t(
          'TheSystemHasReceivedTheRequestAndWillNotifyYouImmediatelyAfterProcessingIsCompleted'
        ),
      });
    }
  };

  const getFormItem = (answer: IFormAnswer) => {
    const { fieldType } = answer;

    const props: BaseItemProps = {
      item: answer,
      form,
      disabled,
      mode,
      formSubmission,
    };

    switch (fieldType) {
      case 'Dropdown':
      case 'DROPDOWN':
        return <Dropdown {...props} />;
      case 'Datagrid':
      case 'DATAGRID':
        return <Datagrid {...props} />;
      case 'DatePicker':
      case 'DATE_PICKER':
        return <DatePicker {...props} />;
      case 'TimePicker':
      case 'TIME_PICKER':
        return <DatePicker {...props} timePicker={true} />;
      case 'InputText':
      case 'INPUT_TEXT':
        return <InputText {...props} />;
      case 'Location':
      case 'LOCATION':
        return <Location {...props} />;
      case 'TextArea':
      case 'TEXT_AREA':
        return <InputText {...props} textArea={true} />;
      case 'ImageFile':
      case 'IMAGE_FILE':
        return (
          <ImageFile
            {...props}
            transformUrl={url =>
              isCarsa919Enable
                ? applyGoogleImageSizing(url, { width: 100 })
                : url
            }
            formSubmission={formSubmission}
          />
        );
      case 'MediaFile':
        return <MediaFile {...props} />;
      case 'Checkbox':
      case 'CHECKBOX':
        return <Checkbox {...props} />;
      case 'DocFile':
        return <DocFile {...props} />;
      case 'Password':
        return <Password {...props} />;
      case 'Numberic':
        return <Numberic {...props} min={1} />;
      case 'Currency':
        return <Numberic {...props} currency={true} />;
      case 'Camera':
      case 'CAMERA':
        return <Camera {...props} />;
      case 'Signature':
        return <Signature {...props} />;
      case 'CustomizableDropdown':
      case 'CUSTOMIZABLE_DROPDOWN':
        return <CustomizableDropdown {...props} />;
      case 'CustomizableCheckbox':
      case 'CUSTOMIZABLE_CHECKBOX':
        return <Checkbox {...props} customizable />;
      default:
        return <></>;
    }
  };

  const ButtonStatus = () => {
    const draftStatusButton = allowCommonAction ? (
      <Space>
        <Button
          type={'primary'}
          disabled={loading}
          loading={loading && loadingButtonIndex === 'IN_REVIEW'}
          onClick={() => onSubmit('IN_REVIEW')}
        >
          {t('Submit')}
        </Button>
        <Button
          type={'primary'}
          ghost={true}
          disabled={loading}
          loading={loading && loadingButtonIndex === 'DRAFT'}
          onClick={() => onSubmit('DRAFT')}
        >
          {t('SaveDraft')}
        </Button>
      </Space>
    ) : (
      <></>
    );

    if (isCreate) return draftStatusButton;

    switch (formSubmission?.status) {
      case 'IN_REVIEW':
        return (
          <Space>
            {allowCommonAction && (
              <>
                {!!formSubmissionId && isShowButtonAutoFill && (
                  <Button
                    type={'primary'}
                    danger={true}
                    disabled={!activeAutoFill || buttonDisabled || filling}
                    loading={filling}
                    onClick={() => onAutoFillForm()}
                  >
                    {t('AutoFill')}
                  </Button>
                )}
                <Button
                  type={'dashed'}
                  disabled={buttonDisabled}
                  loading={loading && loadingButtonIndex === 'IN_REVIEW'}
                  onClick={() => onSubmit('IN_REVIEW')}
                >
                  {t('Save')}
                </Button>
              </>
            )}
            {allowFullActions && (
              <>
                <Button
                  type={'primary'}
                  disabled={buttonDisabled}
                  loading={loading && loadingButtonIndex === 'APPROVED'}
                  onClick={() => onSubmit('APPROVED')}
                >
                  {t('Approve')}
                </Button>
                <Button
                  type={'primary'}
                  ghost={true}
                  disabled={buttonDisabled}
                  loading={loading && loadingButtonIndex === 'NEED_TO_UPDATE'}
                  onClick={() => onSubmit('NEED_TO_UPDATE')}
                >
                  {t('NEED_TO_UPDATE')}
                </Button>
                <Button
                  type={'primary'}
                  danger={true}
                  disabled={buttonDisabled}
                  loading={loading && loadingButtonIndex === 'REJECTED'}
                  onClick={() => onSubmit('REJECTED')}
                >
                  {t('Reject')}
                </Button>
              </>
            )}
          </Space>
        );
      case 'NEED_TO_UPDATE':
        return allowCommonAction ? (
          <Space>
            <Button
              type={'primary'}
              disabled={buttonDisabled}
              loading={loading && loadingButtonIndex === 'IN_REVIEW'}
              onClick={() => onSubmit('IN_REVIEW')}
            >
              {t('Submit')}
            </Button>
          </Space>
        ) : (
          <></>
        );
      case 'DRAFT':
        return draftStatusButton;
      default:
        return (
          <>
            <Typography.Text disabled>{t('ViewOnly')}</Typography.Text>
          </>
        );
    }
  };

  const formContent = (
    <Form form={form} layout={'vertical'}>
      {!isCreate && (
        <Card
          bordered={false}
          styles={{
            body: {
              padding: 12,
              marginBottom: 8,
            },
          }}
        >
          <TemplateSelect templateFormId={templateFormId} />
        </Card>
      )}
      {dynamicFields.map((answer, index) => (
        <Card
          key={index}
          bordered={false}
          styles={{
            body: {
              padding: 12,
              marginBottom: index === dynamicFields.length - 1 ? 0 : 8,
            },
          }}
        >
          {getFormItem(answer)}
        </Card>
      ))}
      {isCreate && (
        <Form.Item
          style={{
            marginBottom: 0,
            marginTop: 24,
          }}
        >
          <Row justify={'end'}>
            <ButtonStatus />
          </Row>
        </Form.Item>
      )}
    </Form>
  );

  if (isCreate) {
    return template ? (
      formContent
    ) : (
      <Row
        justify={'center'}
        style={{
          paddingTop: 100,
        }}
      >
        <Empty description={t('PleaseSelectATemplate')} />
      </Row>
    );
  }

  return fetching ? (
    <Skeleton active={true} />
  ) : isCreate || formSubmission ? (
    <>
      <div
        className={`${className} ${allowCommonAction ? 'allow-action' : ''}`}
      >
        <Card
          bordered={false}
          classNames={{ body: 'p-base' }}
          className="mb-half"
        >
          <Title level={5} className="text-center mb-0">
            {title || t('MeetingRecordDetails')}
          </Title>
        </Card>
        {formContent}
        {isAutoFillProcessing && (
          <Alert
            message={`${t('AutoFill')}: ${t(
              'TheSystemHasReceivedTheRequestAndWillNotifyYouImmediatelyAfterProcessingIsCompleted'
            )}`}
            type="warning"
            style={{ margin: '24px 0' }}
          />
        )}
      </div>
      {allowCommonAction && (
        <Card
          styles={{
            body: {
              padding: 0,
            },
          }}
          className="button-status p-half"
        >
          <ButtonStatus />
        </Card>
      )}
    </>
  ) : (
    <Empty description={<span>{t('ThereAreNoProjects')}</span>} />
  );
};

export default Detail;
