import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useProjectContext } from 'contexts';
import { formHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { cremaHooks } from 'hooks';
import {
  CremaMeetingRecord,
  CremaMeetingRecordStatus,
  MeetingNoteSubmissionApprovalPayload,
} from 'interfaces';
import { useEffect, useMemo, useState } from 'react';
import { cremaServices } from 'services';
import { v4 } from 'uuid';
import MeetingRecordComment from './MeetingRecordComment';
import ContentBlock from 'components/shared/ContentBlock';

const { getCremaMeetingRecordDetail } = cremaServices;
const {
  renderFormControlFromTemplate,
  getFormDataFromAnswers,
  getFormAnswersFromData,
} = formHelpers;

interface MeetingRecordDetailProps {
  currentMeetingId: number;
  refreshMeetingRecordList: () => void;
}

const MeetingRecordDetail: React.FC<MeetingRecordDetailProps> = ({
  currentMeetingId,
  refreshMeetingRecordList,
}) => {
  const { projectId } = useProjectContext();
  const [meetingRecord, setMeetingRecord] = useState<CremaMeetingRecord>();
  const [updating, setUpdating] = useState(false);
  const [form] = useForm();
  const {
    isAllowedToEdit,
    isAllowedToApproveReject,
  } = cremaHooks.useCremaMeetingRecordPermissions(
    meetingRecord?.status as CremaMeetingRecordStatus
  );

  useEffect(() => {
    if (currentMeetingId > 0) getMeetingRecordDetail();
  }, [currentMeetingId]);

  const onSubmit = async (formValues: any) => {
    if (!meetingRecord?.submissionId) return;
    // Transform form data to form answers
    const payload = {
      status: CremaMeetingRecordStatus.InReview,
      submissionId: formValues?.submissionId,
      answers: getFormAnswersFromData(meetingRecord.answers, formValues),
    };
    try {
      setUpdating(true);
      await cremaServices.upsertMeetingNoteSubmission(payload);
      notification.success({ message: t('UpdateMeetingRecordSuccessfully') });
      refreshData();
    } finally {
      setUpdating(false);
    }
  };

  const refreshData = () => {
    getMeetingRecordDetail();
    refreshMeetingRecordList();
  };

  const getMeetingRecordDetail = async () => {
    try {
      setUpdating(true);
      const data = await getCremaMeetingRecordDetail({
        submissionId: currentMeetingId,
        projectId,
      });
      // Transform answers to form data
      const formData = getFormDataFromAnswers(data.answers);
      form.setFieldsValue({ submissionId: data.submissionId });
      form.setFieldsValue(formData);
      setMeetingRecord(data);
    } finally {
      setUpdating(false);
    }
  };

  const confirmApproval = async (
    action: MeetingNoteSubmissionApprovalPayload['action']
  ) => {
    if (meetingRecord?.submissionId) {
      try {
        setUpdating(true);
        await cremaServices.meetingNoteSubmissionApproval({
          projectId,
          submissionId: meetingRecord?.submissionId,
          action,
        });
        refreshData();
      } finally {
        setUpdating(false);
      }
    }
  };

  const ans = useMemo(
    () =>
      meetingRecord?.answers.map(answer => (
        <Form.Item
          className="meeting-record-question"
          key={v4()}
          label={<Typography.Text strong>{answer.fieldLabel}</Typography.Text>}
          name={answer.fieldName}
          required={answer.isRequired}
          rules={
            answer.isRequired
              ? [{ required: true, message: t('RequiredMessage') }]
              : []
          }
          help={answer.hint || undefined}
        >
          {renderFormControlFromTemplate(answer, !isAllowedToEdit)}
        </Form.Item>
      )),
    [meetingRecord]
  );

  if (currentMeetingId === -1) return null;

  return (
    <Spin spinning={updating}>
      <Row gutter={8}>
        <Col span={16} className="crema-meeting-record-col scrollable">
          <ContentBlock
            title={
              <div className="text-center">{t('MeetingRecordDetails')}</div>
            }
          >
            <Form
              layout="vertical"
              disabled={!isAllowedToEdit}
              form={form}
              scrollToFirstError
              onFinish={onSubmit}
            >
              <Form.Item hidden name="submissionId">
                <Input />
              </Form.Item>
              <>{ans}</>
              {meetingRecord?.submissionId && (
                <div className="text-right p-half">
                  <Space>
                    {isAllowedToEdit && (
                      <Button type="dashed" htmlType="submit">
                        {t('Save')}
                      </Button>
                    )}
                    {isAllowedToApproveReject && (
                      <>
                        <Button
                          type="primary"
                          onClick={() => confirmApproval('APPROVE')}
                        >
                          {t('Approve')}
                        </Button>
                        <Button
                          type="default"
                          onClick={() => confirmApproval('REQUEST_TO_UPDATE')}
                        >
                          {t('NeedToUpdate')}
                        </Button>
                        <Button
                          type="primary"
                          danger
                          onClick={() => confirmApproval('REJECT')}
                        >
                          {t('Reject')}
                        </Button>
                      </>
                    )}
                  </Space>
                </div>
              )}
            </Form>
          </ContentBlock>
        </Col>
        <Col span={8} className="crema-meeting-record-col">
          <MeetingRecordComment submissionId={currentMeetingId} />
        </Col>
      </Row>
    </Spin>
  );
};

export default MeetingRecordDetail;
