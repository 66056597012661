import { Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NOT_AVAILABLE } from 'constants/common';
import { useFlagsupContext } from 'contexts';
import { convertNumberFormat } from 'helpers/common';
import { t } from 'helpers/i18n';
import {
  CarbonPotentialReportRowData,
  IARRCarbonPotentialReport,
  IARRCarbonPotentialReportRowData,
  ICarbonPotentialReport,
} from 'interfaces';
import { sumBy } from 'lodash';
import { metricConstants } from 'constants/index';

const { T_CO2, HA } = metricConstants;

interface CarbonPotentialReportTableProps extends TableProps {
  carbonPotentialReport?: IARRCarbonPotentialReport | ICarbonPotentialReport;
}

const CarbonPotentialReportTable: React.FC<CarbonPotentialReportTableProps> = ({
  carbonPotentialReport,
  ...tableProps
}) => {
  const {
    riskLevel,
    uncertainty,
    leakage,
    performanceBenchmark,
    nonPermanenceRisk,
  } = carbonPotentialReport?.reportAttributes?.riskLevel || {};
  const { featureFlagsData } = useFlagsupContext();

  const isCAR2619Enabled = featureFlagsData?.CAR_2619?.enabled;

  const columns: TableProps<
    IARRCarbonPotentialReportRowData | CarbonPotentialReportRowData
  >['columns'] = [
    {
      title: t('Year'),
      dataIndex: 'year',
    },
    {
      title: t('PlantedAreaHa'),
      dataIndex: 'plantedArea',
      render: value => convertNumberFormat(value),
    },
    {
      title: t('IncrementalPlantingAreaHa'),
      dataIndex: 'incrementalPlantingArea',
      render: value => convertNumberFormat(value),
    },
    {
      title: t('LinearModelEstimatedRemovalCO2eTonHa'),
      dataIndex: 'linearModelEstimatedRemoval',
      render: value => convertNumberFormat(value),
    },
    {
      title: t('EmissionCO2eTon'),
      dataIndex: 'emission',
      render: value => convertNumberFormat(value),
    },
    {
      title: (
        <>
          <p>
            {t('Risk')} - {t(riskLevel || NOT_AVAILABLE)}
          </p>
          <ul>
            <li>
              {t('Uncertainty')}: {uncertainty}%
            </li>
            <li>
              {t('Leakage')}: {leakage}%
            </li>
            <li>
              {t('PerformanceBenchmark')}: {performanceBenchmark}%
            </li>
            <li>
              {t('NonPermanenceRisk')}: {nonPermanenceRisk}%
            </li>
          </ul>
        </>
      ),
      dataIndex: 'risk',
      render: value => convertNumberFormat(value),
    },
  ];

  const multiProjectColumns: ColumnsType = [
    {
      title: t('Year'),
      dataIndex: 'year',
    },
    {
      title: `${t('ProjectArea')} (${HA})`,
      children: [
        {
          title: `${t('ARRArea')} (${HA})`,
          dataIndex: ['arr', 'plantedArea'],
          align: 'right',
          render: value => convertNumberFormat(value),
        },
        {
          title: `${t('WRCArea')} (${HA})`,
          dataIndex: ['wrc', 'plantedArea'],
          align: 'right',
          render: value => convertNumberFormat(value),
        },
      ],
    },
    {
      title: `${t('TotalCarbonRemoval')} (${T_CO2})`,
      children: [
        {
          title: `${t('ARRCredit')} (${T_CO2})`,
          dataIndex: ['arr', 'totalCarbonRemoval'],
          align: 'right',
          render: value => convertNumberFormat(value),
        },
        {
          title: `${t('WRCCredit')} (${T_CO2})`,
          dataIndex: ['wrc', 'totalCarbonRemoval'],
          align: 'right',
          render: value => convertNumberFormat(value),
        },
      ],
    },
    {
      title: `${t('Emission')} (${T_CO2})`,
      children: [
        {
          title: `${t('ARRCredit')} (${T_CO2})`,
          dataIndex: ['arr', 'emission'],
          align: 'right',
          render: value => convertNumberFormat(value),
        },
        {
          title: `${t('WRCCredit')} (${T_CO2})`,
          dataIndex: ['wrc', 'emission'],
          align: 'right',
          render: value => convertNumberFormat(value),
        },
      ],
    },
    {
      title: (
        <>
          <p>
            {t('Risk')} - {t(riskLevel || NOT_AVAILABLE)}
          </p>
          <ul>
            <li>
              {t('Uncertainty')}: {uncertainty}%
            </li>
            <li>
              {t('Leakage')}: {leakage}%
            </li>
            <li>
              {t('PerformanceBenchmark')}: {performanceBenchmark}%
            </li>
            <li>
              {t('NonPermanenceRisk')}: {nonPermanenceRisk}%
            </li>
          </ul>
        </>
      ),
      align: 'left',
      children: [
        {
          title: `${t('ARRCredit')} (${T_CO2})`,
          dataIndex: ['arr', 'risk'],
          align: 'right',
          render: value => convertNumberFormat(value),
        },
        {
          title: `${t('WRCCredit')} (${T_CO2})`,
          dataIndex: ['wrc', 'risk'],
          align: 'right',
          render: value => convertNumberFormat(value),
        },
      ],
    },
  ];

  const multiProjectSummaryRow = (
    dataSource: CarbonPotentialReportRowData[]
  ) => {
    const totalARRProjectArea = sumBy(dataSource, 'arr.plantedArea');
    const totalWRCProjectArea = sumBy(dataSource, 'wrc.plantedArea');
    const totalARRCarbonRemoval = sumBy(dataSource, 'arr.totalCarbonRemoval');
    const totalWRCCarbonRemoval = sumBy(dataSource, 'wrc.totalCarbonRemoval');
    const totalARREmission = sumBy(dataSource, 'arr.emission');
    const totalWRCEmission = sumBy(dataSource, 'wrc.emission');
    const totalARRRisk = sumBy(dataSource, 'arr.risk');
    const totalWRCRisk = sumBy(dataSource, 'wrc.risk');
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>{t('Total')}</Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            {convertNumberFormat(totalARRProjectArea || 0)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align="right">
            {convertNumberFormat(totalWRCProjectArea || 0)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align="right">
            {convertNumberFormat(totalARRCarbonRemoval || 0)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align="right">
            {convertNumberFormat(totalWRCCarbonRemoval || 0)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} align="right">
            {convertNumberFormat(totalARREmission || 0)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} align="right">
            {convertNumberFormat(totalWRCEmission || 0)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} align="right">
            {convertNumberFormat(totalARRRisk || 0)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} align="right">
            {convertNumberFormat(totalWRCRisk || 0)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const singleProjectSummaryRow = (
    dataSource: IARRCarbonPotentialReportRowData[]
  ) => {
    const totalLinearModelEstimatedRemoval = sumBy(
      dataSource,
      'linearModelEstimatedRemoval'
    );
    const totalEmission = sumBy(dataSource, 'emission');
    const totalRisk = sumBy(dataSource, 'risk');

    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>{t('Total')}</Table.Summary.Cell>
          <Table.Summary.Cell index={1} />
          <Table.Summary.Cell index={2} />
          <Table.Summary.Cell index={3}>
            {convertNumberFormat(totalLinearModelEstimatedRemoval)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            {convertNumberFormat(totalEmission)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            {convertNumberFormat(totalRisk)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const data = carbonPotentialReport?.reportData || [];

  return (
    <Table
      columns={isCAR2619Enabled ? multiProjectColumns : columns}
      dataSource={data}
      bordered
      pagination={false}
      rowKey="year"
      sticky={{ offsetHeader: 0 }}
      summary={() => {
        if (!data.length) return undefined;

        return isCAR2619Enabled
          ? multiProjectSummaryRow(data as CarbonPotentialReportRowData[])
          : singleProjectSummaryRow(data as IARRCarbonPotentialReportRowData[]);
      }}
      {...tableProps}
    />
  );
};

export default CarbonPotentialReportTable;
