import React, { useState } from 'react';
import {
  Drawer,
  Form,
  Button,
  notification,
  Typography,
  Spin,
  Row,
  Divider,
} from 'antd';
import { ID_DEFAULT } from 'constants/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentMemberId,
  setCurrentMemberId,
  selectFilter,
  setFilter,
} from 'redux/features/memberManagement';
import { memberManagementHooks, userHooks } from 'hooks';
import { AppDispatch } from 'redux/store';
import { memberManagementServices } from 'services';
import { useProjectContext } from 'contexts';
import MemberGroupRecomendationSelect from './MemberGroupRecomendationSelect';
import { resourceConstants } from 'constants/index';
import { t } from 'helpers/i18n';
import './MemberGroupApproval.scss';
import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
import { ApprovalAction, JoinMGRequestStatus } from 'interfaces';

const { APPS, RESOURCES, ACTIONS } = resourceConstants;
const { PROJECT_MANAGER_TEKBONE } = APPS;
const { MEMBER_MANAGEMENT } = RESOURCES;
const { APPROVE } = ACTIONS;
const { usePermission } = userHooks;

interface IMemberGroupApprovalForm {
  [key: string]: number;
}

const MemberGroupApproval: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [form] = Form.useForm<IMemberGroupApprovalForm>();
  const { projectId } = useProjectContext();
  const { hasPermission } = usePermission();
  const memberId = useSelector(selectCurrentMemberId);
  const filter = useSelector(selectFilter);
  const [loading, setLoading] = useState(false);

  const {
    joinMGRequests,
    loading: gettingJoinMGRequests,
    getJoinMGRequests,
  } = memberManagementHooks.useJoinMGRequests(memberId);

  const isAllowedToApprove = hasPermission(
    PROJECT_MANAGER_TEKBONE,
    MEMBER_MANAGEMENT,
    APPROVE
  );

  const onClose = () => {
    dispatch(setCurrentMemberId(ID_DEFAULT));
  };

  const onFinish = async (formValues: IMemberGroupApprovalForm) => {
    try {
      setLoading(true);
      const requests =
        pendingRequests?.map(request =>
          memberManagementServices.joinMGRequestApproval({
            projectId,
            requestId: request.requestId,
            action: ApprovalAction.APPROVE,
            memberGroupId: formValues[request.requestId],
          })
        ) || [];
      const results = await Promise.allSettled(requests);

      // Handle failed requests
      const failedRequests = results.filter(
        result => result.status === 'rejected'
      );

      if (failedRequests.length > 0) {
        notification.error({
          message: t('SomeRequestsFailedPleaseTryAgain'),
        });
        getJoinMGRequests();
      } else {
        notification.success({
          message: t('ApproveMemberSuccessfully'),
        });
        onClose();
        dispatch(setFilter({ ...filter, ...DEFAULT_PAGINATION_OFFSET }));
      }
    } finally {
      setLoading(false);
    }
  };

  const pendingRequests = joinMGRequests?.joinMemberGroupRequests.filter(
    request => request.status === JoinMGRequestStatus.WAITING_FOR_APPROVAL
  );

  return (
    <Drawer
      open={memberId !== ID_DEFAULT}
      closable={true}
      destroyOnClose={true}
      maskClosable={false}
      getContainer={false}
      placement="left"
      mask={false}
      onClose={onClose}
      title={t('MemberGroupApproval')}
      className="member-group-approval"
      classNames={{
        wrapper: 'w-100',
        header: 'pb-0',
      }}
    >
      {gettingJoinMGRequests ? (
        <Spin spinning={gettingJoinMGRequests} className="w-100" />
      ) : (
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Typography.Text>{`${t('MemberName')}: ${joinMGRequests?.farmerName ||
            t('NotAvailable')}`}</Typography.Text>
          <Divider orientation="left" orientationMargin="0">
            {t('MemberGroup')}
          </Divider>
          {pendingRequests?.map(request => (
            <Row gutter={16} key={request.requestId} className="px-half">
              <Form.Item
                name={`${request.requestId}`}
                label={request.landName || t('NotAvailable')}
                initialValue={request.memberGroupId}
                required
                className="w-100 mb-quarter"
              >
                <MemberGroupRecomendationSelect landId={request.landId} />
              </Form.Item>
            </Row>
          ))}
          {!!pendingRequests?.length && (
            <Form.Item className="text-center mt-base">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={!isAllowedToApprove || gettingJoinMGRequests}
              >
                {t('Approve')}
              </Button>
            </Form.Item>
          )}
        </Form>
      )}
    </Drawer>
  );
};

export default MemberGroupApproval;
