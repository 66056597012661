/** Third party libs **/
import { Card, Tooltip } from 'antd';
import React from 'react';
import classNames from 'classnames';

/** Local libs **/
import { IEligibleCriteria } from 'interfaces';
import { CRITERIA_FARM_INFO, ELIGIBLE_CRITERIA_NAME } from 'constants/farm';
import { t } from 'helpers/i18n';

/** Styles **/
import './EligibleCriteria.scss';

/** Interfaces, enum... **/
interface IEligibleCriteriaProps {
  eligibleCriterias?: IEligibleCriteria[];
}

/** Variables **/

/** ------------------------- **/
/** EligibleCriteria Component */
/** ------------------------- **/
const EligibleCriteria: React.FC<IEligibleCriteriaProps> = ({
  eligibleCriterias,
}) => {
  /** States **/

  /** Hooks **/

  /** Variables **/
  const criteriaFarmClassName: {
    [key in CRITERIA_FARM_INFO]: string;
  } = {
    [CRITERIA_FARM_INFO.ELIGIBLE]: 'eligible',
    [CRITERIA_FARM_INFO.NOT_ELIGIBLE]: 'not-eligible',
    [CRITERIA_FARM_INFO.PENDING_ELIGIBLE_CHECK]: 'pending-eligible-check',
    [CRITERIA_FARM_INFO.EXCLUDED_PRE_SCREEN_AGRO_INCLUDED]:
      'exclude-pre-screen-agro-included',
  };

  /** Functions, Events, Actions... **/
  const renderSquare = (status?: CRITERIA_FARM_INFO) => {
    return (
      <span
        className={classNames(
          'square',
          status ? criteriaFarmClassName[status] : ''
        )}
      />
    );
  };

  const renderCriteria = (
    criteriaName: string,
    status?: CRITERIA_FARM_INFO
  ) => {
    switch (criteriaName) {
      case ELIGIBLE_CRITERIA_NAME.LOCATED_WITHIN_PROJECT_AREA_BOUNDARIES:
        return (
          <div>
            <Tooltip
              key={criteriaName}
              title={
                <div className="d-block ">
                  <div>
                    <span className="square eligible" />
                    {t('FarmAreaIsWithinPrescreenMap')}
                  </div>
                  <div>
                    <span className="square exclude-pre-screen-agro-included" />
                    {t('FarmAreaIsNotWithinPrescreenMap')}
                  </div>
                  <div>
                    <span className="square pending-eligible-check" />
                    {t('PendingCalcFarmLocation')}
                  </div>
                  <div>
                    <span className="square not-eligible" />
                    {t('FarmAreaIsNotWithinAgroforestryMap')}
                  </div>
                </div>
              }
              placement="topLeft"
            >
              {renderSquare(status)}
            </Tooltip>
            <span>{criteriaName}</span>
          </div>
        );
      case ELIGIBLE_CRITERIA_NAME.CONTIGUOUS_AREA_GREATER_THAN_1_HA:
        return (
          <div>
            <Tooltip
              key={criteriaName}
              title={
                <div className="d-block ">
                  <div>
                    <span className="square eligible"></span>
                    {t('ContiguousAreaGreaterThan1Ha')}
                  </div>
                  <div>
                    <span className="square pending-eligible-check" />
                    {t('PendingCalcContiguousArea')}
                  </div>
                  <div>
                    <span className="square not-eligible"></span>
                    {t('ContiguousAreaLessThan1Ha')}
                  </div>
                </div>
              }
              placement="topLeft"
            >
              {renderSquare(status)}
            </Tooltip>
            <span>{criteriaName}</span>
          </div>
        );
      case ELIGIBLE_CRITERIA_NAME.NOT_OVERLAPPING_NEARBY_FARMS:
        return (
          <div>
            <Tooltip
              key={criteriaName}
              title={
                <div className="d-block ">
                  <div>
                    <span className="square eligible"></span>
                    {t('FarmAreaIsNotOverlappedWithOtherApprovedFarms')}
                  </div>
                  <div>
                    <span className="square pending-eligible-check" />
                    {t('PendingCalcOverlappingNearbyFarm')}
                  </div>
                  <div>
                    <span className="square not-eligible"></span>
                    {t('FarmAreaIsOverlappedWithOtherApprovedFarms')}
                  </div>
                </div>
              }
              placement="topLeft"
            >
              {renderSquare(status)}
            </Tooltip>
            <span>{criteriaName}</span>
          </div>
        );
      case ELIGIBLE_CRITERIA_NAME.NOT_WITHIN_FOREST_RESERVE:
        return (
          <div>
            <Tooltip
              key={criteriaName}
              title={
                <div className="d-block ">
                  <div>
                    <span className="square eligible" />
                    {t('FarmAreaIsNotWithinForestReserveArea')}
                  </div>
                  <div>
                    <span className="square pending-eligible-check" />
                    {t('PendingCalcFarmLocation')}
                  </div>
                  <div>
                    <span className="square not-eligible" />
                    {t('FarmAreaIsWithinForestReserveArea')}
                  </div>
                </div>
              }
              placement="topLeft"
            >
              {renderSquare(status)}
            </Tooltip>
            <span>{criteriaName}</span>
          </div>
        );
      case ELIGIBLE_CRITERIA_NAME.NOT_DEFORESTED_IN_THE_LAST_10_YEARS:
        return (
          <div>
            <Tooltip
              key={criteriaName}
              title={
                <div className="d-block ">
                  <div>
                    <span className="square eligible" />
                    {t('FarmAreaIsNotWithinDeforestationAreaForTheLast10Years')}
                  </div>
                  <div>
                    <span className="square pending-eligible-check" />
                    {t('PendingCalcTreeCoverLoss')}
                  </div>
                  <div>
                    <span className="square not-eligible" />
                    {t('FarmAreaIsWithinDeforestationAreaForTheLast10Years')}
                  </div>
                </div>
              }
              placement="topLeft"
            >
              {renderSquare(status)}
            </Tooltip>
            <span>{criteriaName}</span>
          </div>
        );
      default:
        return (
          <div>
            <Tooltip key={criteriaName}>
              <span className="square pending-eligible-check" />
            </Tooltip>
            <span>{criteriaName}</span>
          </div>
        );
    }
  };

  /** Effects **/

  /** Elements **/
  return (
    <Card title="Eligible Criteria">
      {eligibleCriterias?.map(criteria => (
        <React.Fragment key={criteria.criteriaName}>
          {renderCriteria(criteria.criteriaName, criteria.status)}
        </React.Fragment>
      ))}
    </Card>
  );
};

export { EligibleCriteria };
export default EligibleCriteria;
