import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Form,
  InputNumber,
  notification,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import { t } from 'helpers/i18n';
import { LatLng } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProject } from 'redux/features/project';
import { PiCube, PiRuler, PiTag } from 'react-icons/pi';
import { TbWorldLatitude, TbWorldLongitude } from 'react-icons/tb';
import { REGEX_PATTERN_NUMBER_FORMAT } from 'components/FPIC/constants';
import { useProjectContext } from 'contexts';
import { plotServices } from 'services';
import { Alert } from 'components/shared/Alert';
import { AppDispatch } from 'redux/store';
import {
  updateManualSamplingPlotLocation,
  updateModuleIdSamplingPlot,
  updateRadiusSamplingPlot,
  updateSubmittedSamplingPlot,
} from 'redux/features/samplingPlot';
import { useWatch } from 'antd/es/form/Form';
import { PLOT_OUTSIDE_PROJECT_BOUNDARY_CODE } from 'constants/plot';

interface IManualAddSamplingPlotFormProps {
  location: LatLng;
  index: number;
  plotRadius: number;
  submitted?: boolean;
  markerRef: React.MutableRefObject<any>;
}

interface IManualAddSamplingPlotForm {
  latitude: number;
  longitude: number;
  plotShape: string;
  plotRadius: number;
  samplingModuleId: number;
}

const ManualAddSamplingPlotForm: React.FC<IManualAddSamplingPlotFormProps> = ({
  location,
  index,
  plotRadius,
  submitted,
  markerRef,
}) => {
  const [form] = Form.useForm<IManualAddSamplingPlotForm>();
  const dispatch: AppDispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { projectId } = useProjectContext();
  const longitude = useWatch('longitude', form);
  const latitude = useWatch('latitude', form);
  const project = useSelector(selectProject);

  const initialValues = {
    plotShape: 'CIRCLE',
  };

  const inputNumberFormatter = (value: number | string | undefined) => {
    if (value) {
      return `${value}`.replace(REGEX_PATTERN_NUMBER_FORMAT, ',');
    }
    return '';
  };

  const onSubmit = async (values: IManualAddSamplingPlotForm) => {
    try {
      setLoading(true);
      const { data, code } = await plotServices.createPlotManually({
        ...values,
        projectId,
      });
      if (data.plotId) {
        notification.success({
          message: t('GeneratePlotSuccessfully'),
        });
        dispatch(updateSubmittedSamplingPlot({ index, submitted: true }));
      } else if (code === PLOT_OUTSIDE_PROJECT_BOUNDARY_CODE) {
        setDisabled(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location) {
      form.setFieldsValue({
        latitude: location.lat,
        longitude: location.lng,
        plotRadius,
      });
      setDisabled(false);
    }
  }, [location]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      colon={false}
      className="p-quarter sampling-plot-form"
      labelCol={{ span: 8 }}
      labelAlign="left"
      onFinish={onSubmit}
      requiredMark={false}
    >
      <Form.Item
        name="samplingModuleId"
        label={
          <>
            <PiTag className="mr-half" color="#27734D" /> {t('Module')}
          </>
        }
        className="mb-half"
        rules={[{ required: true, message: t('PleaseSelectModule') }]}
      >
        <Select
          options={
            project?.samplingModules?.map(item => ({
              label: item.typeName,
              value: item.id,
            })) || []
          }
          disabled={submitted}
          className="w-100"
          onDropdownVisibleChange={() => {
            markerRef.current?.openPopup();
          }}
          onChange={value => {
            dispatch(updateModuleIdSamplingPlot({ index, moduleId: value }));
          }}
        />
      </Form.Item>

      <Form.Item
        name="latitude"
        className="mb-half"
        label={
          <>
            <TbWorldLatitude className="mr-half" color="#27734D" />
            {t('Latitude')}
          </>
        }
      >
        <InputNumber
          className="w-100"
          formatter={inputNumberFormatter}
          type="number"
          readOnly={submitted}
          onBlur={e => {
            dispatch(
              updateManualSamplingPlotLocation({
                lat: Number(e.target.value),
                lng: longitude,
                index,
              })
            );
          }}
        />
      </Form.Item>
      <Form.Item
        name="longitude"
        className="mb-half"
        label={
          <>
            <TbWorldLongitude className="mr-half" color="#27734D" />
            {t('Longitude')}
          </>
        }
      >
        <InputNumber
          className="w-100"
          formatter={inputNumberFormatter}
          type="number"
          readOnly={submitted}
          onBlur={e => {
            dispatch(
              updateManualSamplingPlotLocation({
                lat: latitude,
                lng: Number(e.target.value),
                index,
              })
            );
          }}
        />
      </Form.Item>

      <Form.Item
        name="plotShape"
        label={
          <>
            <PiCube className="mr-half" color="#27734D" />
            {t('PlotShape')}
          </>
        }
        className="mb-half"
      >
        <Select
          className="w-100"
          disabled
          options={[{ value: 'CIRCLE', label: t('Circle') }]}
        />
      </Form.Item>
      <Form.Item
        name="plotRadius"
        label={
          <Flex justify="center">
            <div className="d-block mr-half">
              <PiRuler color="#27734D" />
            </div>
            <Typography.Text>{t('PlotRadius')} </Typography.Text>
            <Tooltip title={t('PlotRadiusTooltip')} className="ml-quarter">
              <InfoCircleOutlined />
            </Tooltip>
          </Flex>
        }
        className="mb-half"
      >
        <InputNumber
          suffix="m"
          className="w-100"
          type="number"
          min={0}
          readOnly={submitted}
          onChange={value => {
            if (!disabled) {
              dispatch(
                updateRadiusSamplingPlot({
                  radius: value ?? 0,
                  index,
                })
              );
              form.setFieldValue('plotRadius', value);
            }
          }}
        />
      </Form.Item>
      {disabled && (
        <Alert
          type="error"
          contents={[
            `${t('PlotsCannotOverlapEachOther')}`,
            `${t('PleaseCheckAgain')}`,
          ]}
          showDot={false}
        />
      )}
      {!submitted && (
        <Form.Item className="w-100 d-flex justify-content-center align-items-center mt-half mb-half">
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled}
            loading={loading}
          >
            {t('SavePlot')}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export { ManualAddSamplingPlotForm };
export default ManualAddSamplingPlotForm;
