import { getData } from 'helpers/request';
import {
  IGetListJoinMGRequestParams,
  IGetListJoinMGRequestResponse,
  IGetListMGRecommendationParams,
  IGetListMGRecommendationResponse,
  IGetListMemberParams,
  IGetListMemberResponse,
  IJoinMGRequestApprovalPayload,
} from 'interfaces/memberManagement';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;
const prefixUrl = '/tekbone/api/v1';

const getListMember = (
  params: IGetListMemberParams
): Promise<IGetListMemberResponse> =>
  projectManagerClient
    .get(`${prefixUrl}/member-groups/farmers`, { params })
    .then(getData);

const getListJoinMGRequest = (
  params: IGetListJoinMGRequestParams
): Promise<IGetListJoinMGRequestResponse> =>
  projectManagerClient
    .get(`${prefixUrl}/member-groups/join-requests`, {
      params,
    })
    .then(getData);

const getListMGRecommendation = (
  params: IGetListMGRecommendationParams
): Promise<IGetListMGRecommendationResponse> =>
  projectManagerClient
    .get(`${prefixUrl}/member-groups/recommendations`, { params })
    .then(getData);

const joinMGRequestApproval = (payload: IJoinMGRequestApprovalPayload) =>
  projectManagerClient.post(
    `${prefixUrl}/member-groups/staffs/farmers/join-requests/${payload.requestId}/approval`,
    payload
  );

export default {
  getListMember,
  joinMGRequestApproval,
  getListJoinMGRequest,
  getListMGRecommendation,
};
