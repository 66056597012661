import { GOOGLE_DRIVE_ONLINE_VIEWER } from 'constants/file';
import IFrameContainer from 'containers/shared/IFrameContainer';
import InProgressPage from 'containers/shared/InProgressPage';
import { useProjectContext } from 'contexts';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export interface IFrameContainerProps extends RouteComponentProps {
  iFrameSrc: string;
}

export const PROJECTS = window.appConfig?.projects || {};

const IFRAME_URL = {
  [PROJECTS.Kwahu]:
    'https://files.tekoapis.com/files/512cbf74-fe6b-4eef-b36e-90d68b12bb34',
};

const ConsultantReport: React.FC<IFrameContainerProps> = props => {
  const { projectId } = useProjectContext();
  return IFRAME_URL[projectId] ? (
    <IFrameContainer
      {...props}
      iFrameSrc={GOOGLE_DRIVE_ONLINE_VIEWER(IFRAME_URL[projectId])}
    />
  ) : (
    <InProgressPage />
  );
};

export default ConsultantReport;
