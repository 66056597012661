import { farmHooks } from 'hooks';
import {
  IFarmLand,
  ISurveyForm,
  IUpsertFarmMappingFormParams,
} from 'interfaces';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface FarmDetailContextProps {
  farmDetails?: IFarmLand;
  loading: boolean;
  updatingPolygon: boolean;
  getFarmDetails: (farmId: number) => Promise<void>;
  setFarmDetails: Dispatch<SetStateAction<IFarmLand | undefined>>;
  removeSurvey: (surveyId: number) => Promise<void>;
  updateSurvey: (
    surveyId: number,
    answers: ISurveyForm['answers']
  ) => Promise<any> | any;
  updateFarmMappingForm: (
    payload: IUpsertFarmMappingFormParams
  ) => Promise<any>;
  refresh: () => Promise<void>;
}

const FarmDetailContext = createContext<FarmDetailContextProps>({
  farmDetails: {} as IFarmLand,
  loading: false,
  updatingPolygon: false,
  getFarmDetails: async () => {},
  setFarmDetails: () => {},
  updateSurvey: async () => {},
  removeSurvey: async () => {},
  updateFarmMappingForm: async () => {},
  refresh: async () => {},
});

export const FarmDetailContextProvider: React.FC = ({ children }) => {
  const {
    farmDetails,
    getFarmDetails,
    setFarmDetails,
    removeSurvey,
    updateSurvey,
    updateFarmMappingForm,
    updatingPolygon,
    refresh,
    loading,
  } = farmHooks.useFarmDetail();

  return (
    <FarmDetailContext.Provider
      value={{
        loading,
        updatingPolygon,
        farmDetails,
        getFarmDetails,
        setFarmDetails,
        removeSurvey,
        updateSurvey,
        updateFarmMappingForm,
        refresh,
      }}
    >
      {children}
    </FarmDetailContext.Provider>
  );
};

export const useFarmDetailContext = () => useContext(FarmDetailContext);
